import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ValidationError extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  };

  render() {
    return <div className="validation-error">{this.props.children}</div>;
  }
}
