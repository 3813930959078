import React, { Component } from 'react';
import axios from 'utils/axios';
import { Flex, Box } from 'grid-styled';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, Loader, Input, Label, Form, TextArea, Radio, Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { haveOrderInProgress, getFailedOrPendingOrders, getModalTextAndType } from 'utils/photo-orders';

import './order-photos-modal.scss';
export default class OrderPhotosModal extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    propertyId: PropTypes.string.isRequired,
    hasProPhotos: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    poc: PropTypes.array.isRequired,
    specialInstructions: PropTypes.string,
  };

  constructor() {
    super();
    this.state = {
      text: '',
      type: '',
      access_type: null,
      access_code: null,
      special_instruction: null,
      service: 'Pre Market',
      vendor: 'InsideMaps',
      photo_orders: null,
      poc: {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
      },
    };
  }

  componentDidUpdate({ propertyId }) {
    if (propertyId !== this.props.propertyId) {
      this.getOrderPhotosStatus(this.props.propertyId);
      const defaultContact = (this.props.poc && this.props.poc.find((e) => e.is_default)) || this.props.poc[0];
      this.setState({
        poc: { ...defaultContact },
        special_instruction: this.props.specialInstructions,
      });
    }
  }

  onChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  getOrderPhotosStatus = (propertyId) => {
    const { hasProPhotos } = this.props;

    this.setState({
      isLoading: true,
    });

    axios.get(`/api/admin/photo-orders/${propertyId}`).then((res) => {
      const inProgress = haveOrderInProgress(res.data.results);
      const failedOrders = getFailedOrPendingOrders(res.data.results);
      const { modalText, type } = getModalTextAndType(inProgress, failedOrders, hasProPhotos);

      this.setState({
        text: modalText,
        type: type,
        isLoading: false,
        photo_orders: res.data.results,
        canOrderProPhotos: !inProgress,
      });
    });
  };

  handlePackageChange = (e, { value }) => this.setState({ service: value });

  handleContactChange = (e, { name, value }) => {
    if (name === 'poc') {
      const contact = this.props.poc && this.props.poc.find((e) => e.id === value);
      this.setState({
        poc: { ...contact },
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        poc: { ...prevState.poc, [name]: value },
      }));
    }
  };

  handleReOrder = () => {
    this.setState({
      canOrderProPhotos: true,
      type: 'success',
      isLoading: false,
    });
    this.state.photo_orders
      .filter((el) => el.order_status === 'failed' || el.order_status === 'pending')
      .forEach((order) => axios.delete(`/api/admin/photo-orders/${order.property_id}`));
  };
  renderModal = () => {
    const { text, type, isLoading, canOrderProPhotos, service, vendor, poc } = this.state;
    // TODO: Replace this with getOrderTypes from insidemaps-client
    const orderTypes = ['Pre Market', 'Market Ready', 'Pro Package', 'Curb Shot Only'].map((type) => {
      return (
        <Radio
          key={type}
          name="service"
          value={type}
          label={type}
          checked={service === type}
          onChange={this.handlePackageChange}
        />
      );
    });

    const mappedContactOptions =
      this.props.poc &&
      this.props.poc.map((e) => {
        return {
          key: e.id,
          value: e.id,
          text: `${e.first_name} ${e.last_name}`,
        };
      });

    return (
      <Modal isOpen={this.props.active} className="order-photos-modal">
        <ModalHeader>Order professional photos</ModalHeader>
        <Form>
          <ModalBody>
            <Flex>{isLoading ? <Loader active={true} inline="centered" /> : <Box width={1}>{text}</Box>}</Flex>
          </ModalBody>
          {!isLoading && canOrderProPhotos && (
            <div>
              <Form.Field className="order-photos-modal__form-field">
                <Flex flexDirection="column">
                  <div className={'order-photos-modal__order-type'}>
                    <Label>Vendor</Label>
                    <Radio
                      name="vendor"
                      value="InsideMaps"
                      label="Inside Maps"
                      checked={vendor === 'InsideMaps'}
                      onChange={this.onChange}
                    />
                    <Label>Order Type</Label>
                    <React.Fragment>{orderTypes}</React.Fragment>
                  </div>

                  <Label>
                    <span>Access Type</span>
                    <span> (examples include keypad, lockbox, gate)</span>
                  </Label>
                  <Input focus tabIndex={1} onChange={this.onChange} name="access_type" />
                  <Label>Access Code</Label>
                  <Input tabIndex={2} onChange={this.onChange} name="access_code" />
                  <Label>Special Instructions</Label>
                  <TextArea
                    tabIndex={3}
                    onChange={this.onChange}
                    name="special_instruction"
                    value={this.state.special_instruction}
                  />
                  <Label style={{ marginBottom: '20px' }}>
                    <span>Point Of Contact</span>
                  </Label>

                  {poc.id && (
                    <Select
                      style={{ marginBottom: '20px' }}
                      name="poc"
                      value={poc.id}
                      options={mappedContactOptions}
                      onChange={this.handleContactChange}
                    />
                  )}
                  <Input
                    tabIndex={4}
                    onChange={this.handleContactChange}
                    placeholder="First Name"
                    name="first_name"
                    value={poc.first_name}
                  />
                  <Input
                    tabIndex={5}
                    onChange={this.handleContactChange}
                    placeholder="Last Name"
                    name="last_name"
                    value={poc.last_name}
                  />
                  <Input
                    tabIndex={6}
                    onChange={this.handleContactChange}
                    placeholder="Email"
                    name="email"
                    value={poc.email}
                  />
                  <Input
                    tabIndex={7}
                    onChange={this.handleContactChange}
                    placeholder="Phone Number"
                    name="phone"
                    value={poc.phone}
                  />
                </Flex>
              </Form.Field>
            </div>
          )}
          {type === 'success' && !isLoading ? (
            <ModalFooter>
              <Button onClick={this.props.toggleModal} tabIndex={canOrderProPhotos ? 9 : 1}>
                No
              </Button>
              <Button
                id="submit-order"
                onClick={this.orderPhotos}
                tabIndex={canOrderProPhotos ? 8 : 2}
                className="ui primary button"
              >
                Order photos
              </Button>
            </ModalFooter>
          ) : type === 'reorder' && !isLoading ? (
            <ModalFooter>
              <Button onClick={this.props.toggleModal} tabIndex={canOrderProPhotos ? 9 : 1}>
                No
              </Button>
              <Button
                id="submit-order"
                onClick={this.handleReOrder}
                tabIndex={canOrderProPhotos ? 8 : 2}
                className="ui primary button"
              >
                Yes
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <Button disabled={isLoading} onClick={this.props.toggleModal} tabIndex={canOrderProPhotos ? 4 : 1}>
                {type === 'submit' ? 'Ok' : 'Cancel'}
              </Button>
            </ModalFooter>
          )}
        </Form>
      </Modal>
    );
  };

  orderPhotos = () => {
    const { propertyId } = this.props;
    const { access_code, access_type, special_instruction, service, vendor, poc } = this.state;

    const payload = {
      property_id: propertyId,
      access_code,
      access_type,
      special_instruction,
      service,
      vendor,
      first_name: poc.first_name,
      last_name: poc.last_name,
      email: poc.email,
      phone: poc.phone,
    };

    this.setState(
      {
        text: 'Your order for professional photos has been placed!',
        type: 'submit',
        isLoading: true,
      },
      () => {
        axios
          .post('/api/admin/photo-orders', payload)
          .then(() => {
            this.setState({
              isLoading: false,
              canOrderProPhotos: false,
            });
          })
          .catch(() => {
            this.setState({
              text: 'There was an error placing the order at this time. Please contact IT department.',
              isLoading: false,
            });
          });
      },
    );
  };

  render() {
    return this.renderModal();
  }
}
