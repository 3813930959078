import { get } from 'lodash';

export const transformPhotoItems = (items, { hasTour, hasFloorplan }) => {
  return items.map((item, i) => {
    let hidden = item.hidden;

    if (['tour-embedded', 'tour-direct', 'tourStock'].includes(item.type)) {
      hidden = hasTour === 'no';
    } else if (item.type === 'floorplan') {
      hidden = hasFloorplan === 'no';
    }

    return {
      picture_id: item.id,
      property_id: item.property_id,
      order: i,
      pro: item.pro === true,
      hidden: hidden,
    };
  });
};

const checkHasTour = (type, has_tour) => type === 'tourStock' && has_tour === 'yes';
const checkHasFloorPlan = (type, has_floorplan) => type === 'floorplan' && has_floorplan === 'yes';

export const resortItems = (items, has_tour, has_floorplan) => {
  const primary = [];
  const tour = [];
  const floorplans = [];
  const rest = [];

  for (const item of items) {
    if (item.primary) {
      primary.push(item);
      continue;
    }
    if (checkHasTour(item.type, has_tour)) {
      tour.push(item);
      continue;
    }
    if (checkHasFloorPlan(item.type, has_floorplan)) {
      floorplans.push(item);
      continue;
    }
    rest.push(item);
  }

  return [...primary, ...tour, ...floorplans, ...rest];
};

export const getTagByName = (name, tags) => {
  const selectedTag = tags.filter((e) => e.name === name);
  return selectedTag.length ? selectedTag[0] : false;
};

export const moveHiddenPhotoToEnd = (options) => {
  const photoList = get(options, 'photoList', []);
  const hiddenPhotoIndex = get(options, 'hiddenPhotoIndex', 0);

  if (hiddenPhotoIndex < 0 || hiddenPhotoIndex >= photoList.length) {
    return photoList;
  }

  return photoList.filter((el, i) => i !== hiddenPhotoIndex).concat(photoList[hiddenPhotoIndex]);
};

export const moveUnHiddenPhotoToEndOfNonHiddenList = (options = {}) => {
  const { photoList = [], nonHiddenPhotoIndex = 0 } = options;

  const elementToUnHide = photoList[nonHiddenPhotoIndex];

  const nonHiddenPhotos = photoList.filter((el) => !el.hidden);
  const hiddenPhotosExceptTarget = photoList.filter((el) => el.hidden && el.id !== elementToUnHide?.id);

  return [...nonHiddenPhotos, ...hiddenPhotosExceptTarget];
};

export const toggleClassName = (image, isSelected) => {
  if (!image) return '';

  const classNames = [];

  if (image.hidden) classNames.push('image-hidden');
  if (isSelected) classNames.push('image-selected');

  return classNames.join(' ');
};
