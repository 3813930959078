import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { getUserPermissions } from '../../utils';
import axios from 'utils/axios';

import PermissionsProvider from './PermisionsProvider';

class OperationsStateManager extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    history: PropTypes.object.isRequired,
  };

  state = {
    userPermissions: null,
  };

  componentDidMount() {
    this.mounted = true;
    const { history } = this.props;
    this.routeChangeCallback();
    this.unlisten = history.listen(() => {
      this.routeChangeCallback();
    });
  }

  componentWillUnmount() {
    this.unlisten();
    this.mounted = false;
  }

  getUserPermissions = () => {
    if (!axios.getUserId()) return Promise.reject();
    return getUserPermissions(axios.getUserId());
  };

  setPermissions(userPermissions) {
    // avoid setState after component has unmounted
    if (this.mounted) {
      this.setState({ userPermissions });
    }
  }

  routeChangeCallback() {
    this.getUserPermissions()
      .then((userPermissions) => {
        this.setPermissions(userPermissions);
      })
      .catch(() => {
        this.setPermissions([]);
      });
  }

  unlisten = null;
  mounted = false;

  render() {
    const { userPermissions } = this.state;
    const { children } = this.props;
    return userPermissions && <PermissionsProvider userPermissions={userPermissions}>{children}</PermissionsProvider>;
  }
}

export default withRouter(OperationsStateManager);
