import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Flex, Box } from 'grid-styled';
import moment from 'moment';
import PropTypes from 'prop-types';
import calenderIcon from '../icons/calendar.svg';
import _ from 'lodash';

class DatePickers extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    onErrorHide: PropTypes.func,
    filterLabel: PropTypes.string.isRequired,
    keyName: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    showApplyButton: PropTypes.bool,
  };

  state = {
    dates: {
      start: '',
      end: '',
    },
  };

  onSubmit = () => {
    if (!this.hasValidDates()) {
      this.props.onError && this.props.onError('Enter valid date');
    } else if (this.required.length === 2 && !this.isEndDateGreaterThanStartDate()) {
      this.props.onError && this.props.onError('End date should be greater');
    } else {
      this.props.onErrorHide && this.props.onErrorHide();
      this.props.onSubmit && this.props.onSubmit(this.transformPayload(this.state.dates));
    }
  };

  onChange = (e) => {
    const fieldName = e.target.name;

    this.setState(
      {
        dates: {
          ...this.state.dates,
          [fieldName]: e.target.value,
        },
      },
      () => {
        if (!this.required.includes(fieldName)) {
          this.required.push(fieldName);
        } else if (!this.state.dates[fieldName]) {
          this.required.splice(this.required.indexOf(fieldName), 1);
        }

        if (this.props.onChange) {
          this.props.onChange({
            startDate: this.state.dates.start,
            endDate: this.state.dates.end,
          });
        }
      },
    );
  };

  onClear = () => {
    this.required = [];
    this.setState({
      datesError: false,
      dates: { start: '', end: '' },
      startDatePicker: '',
      endDatePicker: '',
    });

    this.props.onErrorHide && this.props.onErrorHide();
    this.props.onClear && this.props.onClear();
  };

  transformPayload(data) {
    const transformed = Object.keys(data)
      .filter((k) => !!data[k])
      .reduce((obj, key) => {
        return {
          ...obj,
          [`${this.props.keyName}${_.capitalize(key)}Date`]: moment(data[key]).format('MM-DD-YYYY'),
        };
      }, {});
    return transformed;
  }

  isEndDateGreaterThanStartDate() {
    return moment(this.state.dates.end).isAfter(moment(this.state.dates.start));
  }

  hasValidDates() {
    return (
      !!this.required.length &&
      this.required.every((field) => {
        return (
          moment(this.state.dates[field], 'L', true).isValid() || moment(this.state.dates[field], 'l', true).isValid()
        );
      })
    );
  }

  handleChangeStart = (startDatePicker) => {
    !this.required.includes('start') && this.required.push('start');
    this.handleChange({ startDatePicker });
  };

  handleChangeEnd = (endDatePicker) => {
    !this.required.includes('end') && this.required.push('end');
    this.handleChange({ endDatePicker });
  };

  handleChange = ({ startDatePicker, endDatePicker }) => {
    startDatePicker = startDatePicker || this.state.startDatePicker;
    endDatePicker = endDatePicker || this.state.endDatePicker;

    this.setState(
      {
        startDatePicker,
        endDatePicker,
        dates: {
          start: startDatePicker && moment(startDatePicker).format('L'),
          end: endDatePicker && moment(endDatePicker).format('L'),
        },
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange({
            startDate: this.state.dates.start,
            endDate: this.state.dates.end,
          });
        }
      },
    );
  };

  required = [];

  render() {
    const { showApplyButton = true } = this.props;

    return (
      <Flex css="margin-bottom: 20px;" className="date-picker">
        <Box mr={'1%'}>Filter by {this.props.filterLabel} between</Box>

        <Flex css="margin-right: 1%;" className="date-picker__item">
          <Box>
            <input value={this.state.dates.start} name="start" onChange={this.onChange} />
          </Box>
          <Box>
            <DatePicker
              customInput={<img alt="" src={calenderIcon} />}
              selected={this.state.startDatePicker}
              popperPlacement="top-end"
              onChange={this.handleChangeStart}
            />
          </Box>
        </Flex>

        <Box mr={'1%'}>and</Box>

        <Flex css="margin-right: 1%;" className="date-picker__item">
          <Box>
            <input value={this.state.dates.end} name="end" onChange={this.onChange} />
          </Box>
          <Box>
            <DatePicker
              customInput={<img alt="" src={calenderIcon} />}
              selected={this.state.endDatePicker}
              popperPlacement="top-end"
              onChange={this.handleChangeEnd}
            />
          </Box>
        </Flex>

        {showApplyButton && (
          <Box ml={'4%'}>
            <button type="button" className="ui primary button" onClick={this.onSubmit}>
              Apply Filter
            </button>
          </Box>
        )}
        <Box ml={'2%'}>
          <button type="button" className="ui primary button" onClick={this.onClear}>
            Remove Filter
          </button>
        </Box>
      </Flex>
    );
  }
}

export default DatePickers;
