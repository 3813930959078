import React from 'react';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import BulkBoostListing from 'components/BulkBoostListing';

const BulkBoost = () => {
  return (
    <View>
      <ViewHeader
        title="Bulk Boost Listing"
        subtitle="Upload a csv file to bulk upload properties to boost"
      ></ViewHeader>

      <ViewContent>
        <BulkBoostListing />
      </ViewContent>
    </View>
  );
};

export default BulkBoost;
