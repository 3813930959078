import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const SubmittedOrdersTable = ({ submitted, RenderTable, total, failed }) => {
  return (
    <Fragment>
      {submitted && submitted.columns && (
        <RenderTable
          columns={submitted.columns}
          title={`Submission Results: ${total.submitted}/${total.total}`}
          data={submitted.data}
        />
      )}
      <br />
      {failed && failed.columns && (
        <RenderTable
          columns={failed.columns}
          title={`Submissions Failed: ${total.total - total.submitted}/${total.total}`}
          data={failed.data}
        />
      )}
    </Fragment>
  );
};

export default SubmittedOrdersTable;

SubmittedOrdersTable.propTypes = {
  submitted: PropTypes.object,
  RenderTable: PropTypes.func,
  total: PropTypes.object,
  failed: PropTypes.object,
};
