import React, { Component } from 'react';
import { Flex, Box } from 'grid-styled';
import withTableResources from '../../containers/TableStateContainer';
import Table from '../../components/Table';
import PropTypes from 'prop-types';
import ExportToCVSButton from '../../containers/ExportToCSVButton';
import { MAX_REPORT_SIZE_LIMIT } from '../../utils/constants';
import moment from 'moment';
import DateRangeSelector from '../../components/DateRangeSelector';
import { Message } from 'semantic-ui-react';
import { get } from 'lodash';

class PropertyUpdatesReport extends Component {
  static propTypes = {
    // inherited from withTableResources
    onBeforeRequestDataSetup: PropTypes.func.isRequired,
    tableState: PropTypes.object,
    onFetchData: PropTypes.func.isRequired,
    onFilteredChange: PropTypes.func,
    onSortedChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
  };

  state = {
    error: false,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  onError = (errorMsg) => {
    this.setState({
      error: true,
      errorMsg: errorMsg,
    });
  };

  onSubmit = (date) => {
    const { onBeforeRequestDataSetup, onFetchData } = this.props;
    onBeforeRequestDataSetup({ ...date });
    onFetchData();
  };

  onClear = () => {
    const { onBeforeRequestDataSetup, onFetchData } = this.props;
    onBeforeRequestDataSetup({});
    onFetchData({ page: 0 });
  };

  hideError = () => {
    this.setState({
      error: false,
    });
  };

  transformValues = (key, value, data) => {
    if (key === 'created_at') {
      return value && moment(value).format('l h:mm A');
    } else if (key === 'property') {
      return value.formattedAddress;
    } else if (key === 'user') {
      return value.fullName;
    } else if (key === 'market_name') {
      return get(data, 'property.market.name');
    }

    return value;
  };

  renderError() {
    if (this.state.error) {
      return (
        <Message negative onDismiss={this.hideError} ref={(errorMessage) => (this.errorMessage = errorMessage)}>
          <p>{this.state.errorMsg}</p>
        </Message>
      );
    }

    if (this.errorMessage && this.errorMessage.focus) {
      this.errorMessage.focus();
    }

    return null;
  }

  render() {
    const { tableState, onFilteredChange, onSortedChange, onPageChange, onPageSizeChange } = this.props;

    return (
      <div className="property-report">
        {this.renderError()}
        <Flex>
          <Box pb={60} pt={20}>
            <span>View changes that have been made to properties in the marketing admin console.</span>
          </Box>
        </Flex>
        <DateRangeSelector
          onError={this.onError}
          onSubmit={this.onSubmit}
          onClear={this.onClear}
          onErrorHide={this.hideError}
          filterLabel="Modified At"
          keyName="createdAt"
        />
        <Table
          data={tableState.data}
          columns={tableState.columns}
          page={tableState.page}
          pages={tableState.pages}
          filtered={tableState.filtered}
          sorted={tableState.sorted}
          loading={tableState.loading}
          onFilteredChange={onFilteredChange}
          onSortedChange={onSortedChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          getTdProps={(state, rowInfo, column) => {
            if (column.id === 'market_name') {
              return (column.Cell = () => <div>{rowInfo.original.property.market.name}</div>);
            }

            return { style: { cursor: 'pointer' } };
          }}
        />
        {ExportToCVSButton({
          state: tableState,
          maxReportSizeLimit: MAX_REPORT_SIZE_LIMIT,
          reportName: 'property-updates-report',
          endpoint: '/api/admin/activity/properties',
          transformValues: this.transformValues,
        })}
      </div>
    );
  }
}

export default withTableResources(PropertyUpdatesReport, {
  endpoint: '/api/admin/activity/properties',
  isReport: true,
});
