import React, { Component } from 'react';
import { Form, TextArea, Button, Message, Radio } from 'semantic-ui-react';
import { Flex, Box } from 'grid-styled';
import axios from 'utils/axios';
import { scrollToTop, trimValue } from 'utils';
import PropTypes from 'prop-types';
import ValidationRequired from '../../components/validation/ValidationRequired';
import CustomInput from '../../components/CustomInput';
import { withPermissions } from '../../components/HoC';

class GeneralInformation extends Component {
  static propTypes = {
    marketData: PropTypes.object,
    onUpdateMarketData: PropTypes.func,
    permissions: PropTypes.object,
  };

  state = {
    marketData: {},
  };

  componentDidMount() {
    this.setState({
      marketData: this.props.marketData,
    });
  }

  onCatchError(err) {
    if (err && err.response && err.response.status === 401) {
      axios.clearLogin();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  onSave = () => {
    if (!this.isAllFieldsValid) return;

    this.setState({
      loading: true,
      saved: false,
      error: false,
    });

    axios
      .put(`/api/admin/markets/${this.state.marketData.id}`, this.getPutData())
      .then(() => {
        scrollToTop();
        this.setState({
          loading: false,
          saved: true,
        });
        this.props.onUpdateMarketData();
      })
      .catch((err) => this.onCatchError(err));
  };

  onChange = (e, props) => {
    const target = e.target;

    this.setState(
      {
        marketData: {
          ...this.state.marketData,
          [props.name]: props.type === 'radio' ? props.value : e.target.value,
        },
      },
      () => {
        this.validateFields(target);
      },
    );
  };

  getPutData() {
    return {
      name: trimValue(this.state.marketData.name),
      h2_heading: trimValue(this.state.marketData.h2_heading),
      longdesc: trimValue(this.state.marketData.longdesc),
      offer_title: trimValue(this.state.marketData.offer_title),
      offer_details: trimValue(this.state.marketData.offer_details),
      shortdesc: trimValue(this.state.marketData.shortdesc),
      disclaimer: trimValue(this.state.marketData.disclaimer),
      banner_url: trimValue(this.state.marketData.banner_url),
      thumbnail_url: trimValue(this.state.marketData.thumbnail_url),
      lat: trimValue(this.state.marketData.lat),
      lng: trimValue(this.state.marketData.lng),
      phone_general: trimValue(this.state.marketData.phone_general),
      phone_leasing: trimValue(this.state.marketData.phone_leasing),
      meta_title: trimValue(this.state.marketData.meta_title),
      meta_description: trimValue(this.state.marketData.meta_description),
      headline: trimValue(this.state.marketData.headline),
      enable_notice: this.state.marketData.enable_notice === 'Yes',
      notice_show_icon: this.state.marketData.notice_show_icon === 'Yes',
      notice_content: trimValue(this.state.marketData.notice_content),
      pls_market_content: trimValue(this.state.marketData.pls_market_content),
      ils_market_content: trimValue(this.state.marketData.ils_market_content),
      ils_disclaimer: trimValue(this.state.marketData.ils_disclaimer),
    };
  }

  get isAllFieldsValid() {
    return !Object.keys(this.state).some((curVal) => {
      return curVal.includes('_error') && this.state[curVal];
    });
  }

  validateFields = (target) => {
    const requiredFields = ['name', 'lat', 'lng'],
      fieldName = target.name;

    if (requiredFields.includes(fieldName)) {
      if (!this.state.marketData[fieldName]) {
        this.setState({
          [`${fieldName}_error`]: true,
        });
      } else {
        this.setState({
          [`${fieldName}_error`]: false,
        });
      }
    }
  };

  renderMessage() {
    if (this.state.error) {
      return (
        <Message
          negative
          onDismiss={() => {
            this.setState({ error: false });
          }}
        >
          <Message.Header>Error</Message.Header>
          <p>An error has occurred while saving the property.</p>
        </Message>
      );
    } else if (this.state.saved) {
      return (
        <Message
          positive
          onDismiss={() => {
            this.setState({ saved: false });
          }}
        >
          <Message.Header>Success</Message.Header>
          <p>Your changes have been saved.</p>
        </Message>
      );
    }

    return null;
  }

  render() {
    const {
      name,
      longdesc,
      h2_heading,
      offer_title,
      offer_details,
      shortdesc,
      disclaimer,
      banner_url,
      thumbnail_url,
      lat,
      lng,
      phone_general,
      phone_leasing,
      meta_title,
      meta_description,
      headline,
      enable_notice,
      notice_content,
      notice_show_icon,
      pls_market_content,
      ils_market_content,
      ils_disclaimer,
    } = this.state.marketData;

    const { name_error, lat_error, lng_error } = this.state;

    const {
      permissions: { canEditMarketDetails },
    } = this.props;

    return (
      <Form onSubmit={this.onSave}>
        {this.renderMessage()}
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>General Information</h2>
          </Box>
        </Flex>
        <Flex>
          <div className="market-edit">
            <Box width={[1, 1, 1 / 4]}>
              <Box p={10}>
                <CustomInput
                  withValidation
                  validationError={name_error}
                  label="Name"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="H2 Market Heading"
                  name="h2_heading"
                  value={h2_heading}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
                <div>Max length: 255 characters</div>
              </Box>
              <Box p={10}>
                <label>
                  <strong>Long description</strong>
                </label>
                <TextArea name="longdesc" disabled={!canEditMarketDetails} value={longdesc} onChange={this.onChange} />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="Offer title"
                  name="offer_title"
                  value={offer_title}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <label>
                  <strong>Offer details</strong>
                </label>
                <TextArea
                  name="offer_details"
                  value={offer_details}
                  disabled={!canEditMarketDetails}
                  onChange={this.onChange}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="Short description"
                  name="shortdesc"
                  value={shortdesc}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="Banner image url"
                  name="banner_url"
                  value={banner_url}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="Thumbnail image url"
                  name="thumbnail_url"
                  value={thumbnail_url}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>

              <Box mt={20} ml={10}>
                <ValidationRequired /> - Indicates required field
              </Box>
            </Box>
            <Box width={[1, 1, 1 / 4]}>
              <Box p={10}>
                <CustomInput
                  withValidation
                  validationError={lat_error}
                  label="Latitude"
                  name="lat"
                  value={lat}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  withValidation
                  validationError={lng_error}
                  label="Longitude"
                  name="lng"
                  value={lng}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="General phone number"
                  name="phone_general"
                  value={phone_general}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="Leasing phone number"
                  name="phone_leasing"
                  value={phone_leasing}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>

              <Box p={10}>
                <CustomInput
                  label="Meta title"
                  name="meta_title"
                  value={meta_title}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="Meta description"
                  name="meta_description"
                  value={meta_description}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <CustomInput
                  label="Headline"
                  name="headline"
                  value={headline}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <label>
                  <strong>PLS Market Level content</strong>
                </label>
                <TextArea
                  name="pls_market_content"
                  value={pls_market_content}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
              <Box p={10}>
                <label>
                  <strong>ILS Market Level content</strong>
                </label>
                <TextArea
                  name="ils_market_content"
                  value={ils_market_content}
                  onChange={this.onChange}
                  disabled={!canEditMarketDetails}
                />
              </Box>
            </Box>
          </div>
        </Flex>
        <Flex>
          <Box pl="2%" pt={20} pb={20}>
            <h2>Banner Notice</h2>
          </Box>
        </Flex>
        <Flex className="market-edit-one-column" flexDirection="column">
          <Box p={10}>
            <label>
              <strong>Show banner</strong>
            </label>
            <Flex>
              <Box>
                <Radio
                  label="Yes"
                  disabled={!canEditMarketDetails}
                  name="enable_notice"
                  value="Yes"
                  checked={enable_notice === 'Yes'}
                  onChange={this.onChange}
                />
              </Box>
              <Box ml={10}>
                <Radio
                  label="No"
                  disabled={!canEditMarketDetails}
                  name="enable_notice"
                  value="No"
                  checked={enable_notice === 'No'}
                  onChange={this.onChange}
                />
              </Box>
            </Flex>
          </Box>
          {enable_notice === 'Yes' && (
            <Box p={10}>
              <label>
                <strong>Banner content</strong>
              </label>
              <TextArea
                name="notice_content"
                disabled={!canEditMarketDetails}
                maxLength="500"
                value={notice_content}
                onChange={this.onChange}
              />
              <Box mt={10}>Max length: 500 characters</Box>
              <Box pt={10} pb={10}>
                <label>
                  <strong>Show icon in banner</strong>
                </label>
                <Flex>
                  <Box>
                    <Radio
                      label="Yes"
                      disabled={!canEditMarketDetails}
                      name="notice_show_icon"
                      value="Yes"
                      checked={notice_show_icon === 'Yes'}
                      onChange={this.onChange}
                    />
                  </Box>
                  <Box ml={10}>
                    <Radio
                      label="No"
                      disabled={!canEditMarketDetails}
                      name="notice_show_icon"
                      value="No"
                      checked={notice_show_icon === 'No'}
                      onChange={this.onChange}
                    />
                  </Box>
                </Flex>
              </Box>
            </Box>
          )}
        </Flex>
        <Flex>
          <Box pl="2%" pt={20} pb={20}>
            <h2>Legal Disclaimer</h2>
          </Box>
        </Flex>
        <Flex>
          <Flex className="market-edit-one-column" flexDirection="column">
            <Box mb={20} mt={40}>
              <label>
                <strong>PLS Disclaimer content</strong>
              </label>
              <TextArea
                name="disclaimer"
                disabled={!canEditMarketDetails}
                rows="20"
                value={disclaimer}
                onChange={this.onChange}
              />
            </Box>
            <Box mt={20} ml={10}>
              <ValidationRequired /> - Indicates required field
            </Box>
          </Flex>
          <Flex className="market-edit-one-column" flexDirection="column">
            <Box mb={20} mt={40}>
              <label>
                <strong>ILS Disclaimer content</strong>
              </label>
              <TextArea
                name="ils_disclaimer"
                disabled={!canEditMarketDetails}
                rows="20"
                value={ils_disclaimer}
                onChange={this.onChange}
              />
            </Box>
            <Box mt={20} ml={10}>
              <ValidationRequired /> - Indicates required field
            </Box>
          </Flex>
        </Flex>

        {canEditMarketDetails && (
          <Flex justifyContent="flex-end" css="margin-bottom: 40px; margin-top: 20px; margin-right: 10%;">
            <Box>
              <Button type="submit" primary>
                Save
              </Button>
            </Box>
          </Flex>
        )}
      </Form>
    );
  }
}

export default withPermissions(GeneralInformation);
