import React, { useState, useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Box, FormControl, Radio, FormControlLabel, RadioGroup, Grid, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'utils/axios';

import FeedsQuery from './Query';
import { withPermissions } from '../../components/HoC';
import InlineMessage from '../../components/InlineMessage';
import { checkQueryConditions } from 'utils/index';
import DragDrop from 'components/DragDrop';

const defaultQuery = {
  criteria: {
    operator: 'AND',
    children: [],
  },
  ordering: [],
  manually_added_properties: [],
  excluded_properties: [],
};

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    margin: theme.spacing(1),
    backgroundColor: '#db2828e0',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ea7474B0',
    },
  },
  watermarkContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  watermarkOptionsContainer: {
    padding: '0 16px',
    alignItems: 'center',
    bottom: 0,
    right: 0,
    backgroundColor: '#f6f6f6',
  },
  checkbox: {
    padding: 0,
    marginRight: '8px',
  },
  chessBoard: {
    marginTop: 8,
    width: 'fit-content',
    backgroundImage:
      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEX////09PQtDxrOAAAAE0lEQVQI12P4f4CBKMxg/4EYDAAFkR1NiYvv7QAAAABJRU5ErkJggg==')",
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
    '& > img': {
      objectFit: 'contain',
      maxHeight: '200px',
    },
  },
}));

function FeedsForm({
  id = '',
  initialValues = {
    title: '',
    description: '',
    feed_format_id: 1,
  },
  onSubmit = null,
  setSubTitle = null,
  permissions: { canCreateFeeds, canManageFeeds },
}) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const newFeed = urlParams.new !== undefined;

  if (newFeed) {
    delete urlParams.new;
    history.push({
      pathname: location.pathname,
      search: qs.stringify(urlParams),
    });
  }

  const [feedFormats, setFeedFormats] = useState([]);
  const [values, setValues] = useState({
    id: id || '',
    ...initialValues,
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(newFeed);
  const [query, setQuery] = useState({
    query: { ...defaultQuery },
    queryConfig: {},
  });
  const [redirect, setRedirect] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [selectedImages, setSelectedImages] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    // Fetch feed formats
    await axios.get('/api/admin/feed-formats').then((response) => {
      if (response.status === 200 && response.data && response.data.results) {
        setFeedFormats(response.data.results);
        setValues({
          ...values,
          feed_format_id: response.data.results.length ? response.data.results[0].id : 1,
        });
      }
    });

    if (id) {
      // Fetch this feed
      await axios.get(`/api/admin/feeds/${id}`).then((response) => {
        if (response && response.status === 200) {
          setSubTitle(response.data.slug);
          setValues(omit(response.data, ['created_at', 'updated_at']));
          setImagesUrl(response.data.main_watermark_url, response.data.secondary_watermark_url);
          setQuery(response.data.conditions);
        }
      });
    }
  }

  function onChange(field) {
    return (e) => {
      setValues({ ...values, [field]: e.target.value });
    };
  }

  function checkQuery(query) {
    const queryChecker = checkQueryConditions(query.query.criteria, []);

    if (queryChecker > 0) {
      setError(
        'At least 2 query components are required to use "OR" as a condition. Please add another group or condition or use "AND" instead of "OR".',
      );
      throw Error();
    }
  }

  function onFormSubmit() {
    setError(false);
    setSuccess(false);

    const user_id = axios.getUserId();

    if (!canManageFeeds && !canCreateFeeds) {
      return setError({
        type: 'danger',
        message: 'You do not have permission to edit any feeds.',
      });
    }
    // Permission to manage own, not all, but didn't create this row:
    if (values.user_id && !canManageFeeds && canCreateFeeds && values.user_id !== user_id) {
      return setError({
        type: 'danger',
        message: 'You do not have permission to edit this feed.',
      });
    }

    const data = { ...values, user_id: values.user_id || user_id };

    // Remove id from payload if creating feed
    if (!id) {
      delete data.id;
    }
    // Remove frontend-only query options
    data.conditions = JSON.parse(
      JSON.stringify(query, (key, value) => (!['id', 'isRoot', 'parent'].includes(key) ? value : undefined)),
    );

    data.main_watermark_url = selectedFiles.main_watermark_url;
    data.secondary_watermark_url = selectedFiles.secondary_watermark_url;
    checkQuery(query);

    const formData = getFormData(data);

    onSubmit(formData)
      .then((res) => {
        if (res) {
          setSuccess(true);
          if (res.redirect) {
            setRedirect(res.redirect);
          }
          setImagesUrl(res.main_watermark_url, res.secondary_watermark_url);

          return true;
        }
        setError(
          'An error was encountered when saving this feed. Perhaps the title or slug is already in use. Double check your input and try again.',
        );
      })
      .catch(setError);
  }

  const setImagesUrl = (main_watermark_url, secondary_watermark_url) => {
    setSelectedImages({
      ...(main_watermark_url ? { main_watermark_url } : {}),
      ...(secondary_watermark_url ? { secondary_watermark_url } : {}),
    });
    setSelectedFiles({
      ...(main_watermark_url ? { main_watermark_url } : {}),
      ...(secondary_watermark_url ? { secondary_watermark_url } : {}),
    });
  };

  const getFormData = (data) => {
    const formData = new FormData();
    if (data.id) {
      formData.append('id', data.id);
    }
    formData.append('user_id', data.user_id);
    formData.append('slug', data.slug);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('conditions', JSON.stringify(data.conditions));
    formData.append('feed_format_id', data.feed_format_id);
    formData.append('main_watermark', data.main_watermark_url);
    formData.append('secondary_watermark', data.secondary_watermark_url);
    return formData;
  };

  const onMainImage = (acceptedFiles) => {
    const images = acceptedFiles.map((file) => URL.createObjectURL(file));
    if (images.length === 2) {
      setSelectedImages({
        main_watermark_url: images[0],
        secondary_watermark_url: images[1],
      });
      setSelectedFiles({
        main_watermark_url: acceptedFiles[0],
        secondary_watermark_url: acceptedFiles[1],
      });
    } else if (Object.entries(selectedImages).length === 0) {
      setSelectedImages({
        main_watermark_url: images[0],
      });
      setSelectedFiles({
        main_watermark_url: acceptedFiles[0],
      });
    } else {
      setSelectedImages({
        main_watermark_url: selectedImages.main_watermark_url ?? images[0],
        secondary_watermark_url: selectedImages.secondary_watermark_url ?? images[0],
      });
      setSelectedFiles({
        main_watermark_url: selectedFiles.main_watermark_url ?? acceptedFiles[0],
        secondary_watermark_url: selectedFiles.secondary_watermark_url ?? acceptedFiles[0],
      });
    }
  };

  const deleteImage = (imageKey) => {
    const newSelectedImages = {};
    const newSelectedFiles = {};
    Object.entries(selectedImages).forEach(([key, value]) => {
      if (key !== imageKey) {
        newSelectedImages[key] = value;
      }
    });
    Object.entries(selectedFiles).forEach(([key, value]) => {
      if (key !== imageKey) {
        newSelectedFiles[key] = value;
      }
    });
    setSelectedImages(newSelectedImages);
    setSelectedFiles(newSelectedFiles);
  };

  const onWatermarkChange = (e) => {
    const image = Object.entries(selectedImages).find(([key, value]) => !!value);
    const file = Object.entries(selectedFiles).find(([key, value]) => !!value);

    setSelectedImages({
      [e.target.value]: image[1],
    });
    setSelectedFiles({
      [e.target.value]: file[1],
    });
  };

  const imagesToDisplay = Object.entries(selectedImages).filter(([key, value]) => !!value);
  return (
    <Form onSubmit={onFormSubmit}>
      {error && (
        <Message negative>
          <Message.Header>Failed to create feed</Message.Header>
          <p>{error}</p>
          {error.type && <InlineMessage {...error} />}
        </Message>
      )}
      {success &&
        (redirect ? (
          <Redirect to={redirect} />
        ) : (
          <Message positive>
            <Message.Header>Feed Saved</Message.Header>
            <p>Your changes have been saved successfully.</p>
          </Message>
        ))}
      <Form.Field>
        <label>Title</label>
        <input
          type="text"
          name="title"
          placeholder="My Custom Feed"
          value={values.title}
          onChange={onChange('title')}
        />
      </Form.Field>
      <Form.Field>
        <label>Slug</label>
        <div className="ui labeled input">
          <div className="ui label">{process.env.REACT_APP_PLS_URL}/api/feeds/</div>
          <input type="text" name="slug" placeholder="my-feed-slug" value={values.slug} onChange={onChange('slug')} />
        </div>
        <a href={`${process.env.REACT_APP_PLS_URL}/api/feeds/${values.slug}`} target="_blank" rel="noreferrer">
          view current feed
        </a>
      </Form.Field>
      <Form.Field>
        <label>Description</label>
        <textarea
          name="description"
          placeholder="Short description of this feed"
          value={values.description}
          onChange={onChange('description')}
        />
      </Form.Field>
      <Form.Field>
        <label>Feed Format</label>
        <select
          className="ui selection dropdown"
          name="feed_format_id"
          value={values.feed_format_id}
          onChange={onChange('feed_format_id')}
        >
          {feedFormats.map((format) => (
            <option key={format.id} value={format.id}>
              {format.title}
            </option>
          ))}
        </select>
      </Form.Field>
      <Form.Field>
        <label>Watermark</label>
        <DragDrop title="Drag and drop your image file here" accept="image/png" onDrop={onMainImage} maxFiles={2} />
        <Grid container spacing={2} justifyContent="center">
          {imagesToDisplay.map(([key, value], index) => (
            <Grid item xs={6} key={index} className={classes.watermarkContainer}>
              <Grid container className={classes.watermarkOptionsContainer}>
                <Grid item xs={10}>
                  <FormControl component="fieldset">
                    {index === 0 ? (
                      <RadioGroup name="watermark" value={key} onChange={onWatermarkChange}>
                        <FormControlLabel
                          control={<Radio color="primary" className={classes.checkbox} />}
                          value="main_watermark_url"
                          disabled={imagesToDisplay.length === 2}
                          label="Show on the first image"
                        />
                        {imagesToDisplay.length === 1 ? (
                          <FormControlLabel
                            control={<Radio color="primary" className={classes.checkbox} />}
                            value="secondary_watermark_url"
                            disabled={imagesToDisplay.length >= 2}
                            label="Show from the second image onwards"
                          />
                        ) : null}
                      </RadioGroup>
                    ) : null}
                    {index === 1 ? (
                      <RadioGroup name="watermark" value={key} onChange={onWatermarkChange}>
                        <FormControlLabel
                          control={<Radio color="primary" className={classes.checkbox} />}
                          value="secondary_watermark_url"
                          disabled={imagesToDisplay.length >= 2}
                          label="Show from the second image onwards"
                        />
                      </RadioGroup>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color="danger"
                    aria-label="delete"
                    className={classes.deleteButton}
                    onClick={() => deleteImage(key)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box height={200}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                  className={classes.chessBoard}
                >
                  <img src={value} maxHeight="100%" />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Query</label>
        <FeedsQuery query={query} />
      </Form.Field>

      <button type="submit" className="ui button primary right floated">
        <i className="icon left save" />
        Save Feed
      </button>
    </Form>
  );
}

FeedsForm.propTypes = {
  id: PropTypes.string,
  slug: PropTypes.string,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  setSubTitle: PropTypes.func,
  permissions: PropTypes.shape({
    canCreateFeeds: PropTypes.string,
    canManageFeeds: PropTypes.string,
  }),
};

export default withPermissions(FeedsForm);
