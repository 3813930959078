import React, { useState, useEffect } from 'react';
import axios from 'utils/axios';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Message } from 'semantic-ui-react';

const useStyles = makeStyles(() => ({
  button: {
    background: '#009412',
    '&:hover': {
      backgroundColor: '#009412',
    },
    color: '#fff',
  },
}));

// Toggle which amenities we can filter by in PLS
const Amenities = () => {
  const classes = useStyles();
  const [amenities, setAmenities] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    axios.get('/api/admin/amenities').then(({ data }) => {
      setAmenities(data);
    });
  }, []);

  const toggleFilter = (amenity) => {
    const copy = [...amenities];
    const index = copy.findIndex((e) => e.name === amenity.name);
    copy[index].enabled_filter = !copy[index].enabled_filter;
    setAmenities(copy);
  };

  const onSubmit = async () => {
    setLoading(true);

    await axios
      .put(`/api/admin/amenities`, {
        amenities,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => setError(true));

    setLoading(false);
  };

  const closeModal = () => {
    setIsActive(false);
  };

  const confirmToggle = () => {
    onSubmit();
    closeModal();
  };

  const openSaveModal = () => {
    setSuccess(false);
    setIsActive(true);
  };

  const mappedAmenities = amenities.map((amenity) => {
    return (
      <FormControlLabel
        key={amenity.id}
        control={<Checkbox color="primary" checked={amenity.enabled_filter} onChange={() => toggleFilter(amenity)} />}
        label={amenity.name}
      />
    );
  });

  return (
    <Box p={{ xs: 2, sm: 3, md: 4 }}>
      <h1>Toggle Amenity Filters in PLS</h1>
      {error && <p>An error has occurred while saving.</p>}
      {success && (
        <Message positive>
          <Message.Header>Amenities Saved</Message.Header>
          <p>Your changes have been saved successfully.</p>
        </Message>
      )}
      <Grid container>
        <Grid style={{ margin: '5px 0' }} item md={12}>
          {mappedAmenities}
        </Grid>
        <Modal isOpen={isActive}>
          <ModalHeader>Toggle Amenity Filters in Property Listing Website</ModalHeader>
          <ModalBody>
            <p>
              Note that the Home features filter on the Search page will be enabled according to the selection below.
              Please be aware the changes in here will be affecting the Property Listing Website.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeModal}>Cancel</Button>
            <Button color="primary" onClick={confirmToggle}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
        {loading ? (
          'Loading...'
        ) : (
          <Button onClick={openSaveModal} className={classes.button} startIcon={<SaveIcon />}>
            Save
          </Button>
        )}
      </Grid>
    </Box>
  );
};

export default Amenities;
