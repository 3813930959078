import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import axios from 'utils/axios';
import { Flex, Box } from 'grid-styled';
import { Redirect, withRouter, Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { scrollToTop } from '../../utils';
import CustomInput from '../../components/CustomInput';
import { isEmailValid } from 'utils/administration';

class AddEditUser extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    params: PropTypes.object,
    editPage: PropTypes.bool,
  };

  state = {
    formData: {},
    roles: [],
    showReset: !this.props.editPage,
  };

  componentDidMount() {
    if (this.props.editPage) {
      const { userId } = this.props.match.params;

      axios.get(`/api/admin/users/${userId}`).then((res) => {
        const { data } = res;
        this.setState({
          name: `${data.first_name} ${data.last_name}`,
          roles: data.roles,
          formData: {
            email: data.email,
            last_name: data.last_name,
            first_name: data.first_name,
          },
        });
      });
    }
  }

  onSubmit = () => {
    const { showReset } = this.state;

    if (!this.formIsValid() || !this.isEmailCorrect() || (showReset && !this.isPasswordsMatch())) {
      scrollToTop();
      return;
    }

    const payload = { ...this.state.formData };
    if (!showReset) delete payload.password;
    delete payload.c_password;

    if (this.props.editPage) {
      const { userId } = this.props.match.params;

      axios
        .put(`/api/admin/users/${userId}`, payload)
        .then(() => {
          this.setState({
            successRedirect: true,
          });
        })
        .catch((e) => {
          scrollToTop();
          this.setState({
            duplicateMailError: true,
            duplicateMailMsg: e.response.data.message,
          });
        });
    } else {
      axios
        .post(`/api/admin/users`, payload)
        .then(() => {
          this.setState({
            successRedirect: true,
          });
        })
        .catch((e) => {
          scrollToTop();
          this.setState({
            duplicateMailError: true,
            duplicateMailMsg: e.response.data.message,
          });
        });
    }
  };

  onChange = (e, props) => {
    const target = e.target;
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [props.name]: target.value,
        },
      },
      () => {
        this.validateFields(target.name);
      },
    );
  };

  requiredFields = this.props.editPage
    ? ['email', 'first_name', 'last_name']
    : ['email', 'first_name', 'last_name', 'password', 'c_password'];

  formIsValid() {
    const { state } = this;

    this.requiredFields.forEach((field) => {
      this.validateFields(field);
    });

    return this.requiredFields.every((curVal) => {
      return state.formData[curVal];
    });
  }

  validateFields = (fieldName) => {
    if (this.requiredFields.includes(fieldName)) {
      if (!this.state.formData[fieldName]) {
        this.setState({
          [`${fieldName}_error`]: true,
        });
      } else {
        this.setState({
          [`${fieldName}_error`]: false,
        });
      }
    }
  };

  isEmailCorrect() {
    const { email } = this.state.formData;
    const validMail = isEmailValid(email);

    this.setState({
      mailError: !validMail,
      duplicateMailError: false,
    });
    return validMail;
  }

  isPasswordsMatch() {
    const { password, c_password } = this.state.formData,
      passwordsMath = password === c_password;

    this.setState({
      pswdError: !passwordsMath,
    });

    return passwordsMath;
  }

  toggleResetPassword = (e) => {
    e.preventDefault();
    const { showReset } = this.state;

    this.setState(
      {
        showReset: !showReset,
      },
      () => {
        if (showReset) {
          this.requiredFields = this.requiredFields.slice(0, -2);
        } else {
          this.requiredFields.push('password', 'c_password');
        }
      },
    );
  };

  renderRoles = () => {
    const { roles } = this.state;

    return roles.map((role) => (
      <Box key={role.id}>
        <Link to={`/administration/roles/${role.id}`}>{role.name}</Link>
      </Box>
    ));
  };

  render() {
    const { email, first_name, last_name, password, c_password } = this.state.formData;
    const {
      mailError,
      pswdError,
      successRedirect,
      email_error,
      first_name_error,
      last_name_error,
      password_error,
      c_password_error,
      duplicateMailError,
      duplicateMailMsg,
      showReset,
      name,
      roles,
    } = this.state;

    const { editPage } = this.props;
    if (successRedirect) {
      return <Redirect to="/administration/users" />;
    }

    return (
      <Form onSubmit={this.onSubmit}>
        {pswdError && <Alert color="danger">Passwords do not match</Alert>}
        {mailError && <Alert color="danger">Enter valid email</Alert>}
        {duplicateMailError && <Alert color="danger">{duplicateMailMsg}</Alert>}

        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>{editPage ? `Edit User: ${name}` : 'Add User'} </h2>
          </Box>
        </Flex>
        <Flex>
          <Box width={[1, 1, 1 / 2]}>
            <Flex>
              <Box width={[1, 1, 1, 1 / 2]} pl="2%">
                <Box p={10}>
                  <CustomInput
                    withValidation
                    validationError={email_error}
                    label="Email address"
                    name="email"
                    value={email}
                    input={{ 'data-lpignore': true }}
                    onChange={this.onChange}
                  />
                </Box>

                <Box p={10}>
                  <CustomInput
                    withValidation
                    validationError={first_name_error}
                    label="First name"
                    name="first_name"
                    value={first_name}
                    input={{ 'data-lpignore': true }}
                    onChange={this.onChange}
                  />
                </Box>

                <Box p={10}>
                  <CustomInput
                    withValidation
                    validationError={last_name_error}
                    label="Last name"
                    name="last_name"
                    value={last_name}
                    input={{ 'data-lpignore': true }}
                    onChange={this.onChange}
                  />
                </Box>

                {editPage && (
                  <Box p={10}>
                    <Button className="reset-password" onClick={this.toggleResetPassword}>
                      Reset Password
                    </Button>
                  </Box>
                )}
                {showReset && (
                  <Box>
                    <Box p={10}>
                      <CustomInput
                        withValidation
                        validationError={password_error}
                        label="Password"
                        name="password"
                        type="password"
                        value={password}
                        input={{ 'data-lpignore': true }}
                        onChange={this.onChange}
                      />
                    </Box>

                    <Box p={10}>
                      <CustomInput
                        withValidation
                        validationError={c_password_error}
                        label="Confirm password"
                        name="c_password"
                        type="password"
                        value={c_password}
                        input={{ 'data-lpignore': true }}
                        onChange={this.onChange}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Flex>
          </Box>

          {editPage && !!roles.length && (
            <Box pl="3%" width={[1, 1, 1 / 2]}>
              <Box pb={10}>
                <h4>Member Of :</h4>
              </Box>
              {this.renderRoles()}
            </Box>
          )}
        </Flex>
        <Flex>
          <Box ml={'auto'}>
            <Button type="submit" primary>
              Save
            </Button>
          </Box>
        </Flex>
      </Form>
    );
  }
}

export default withRouter(AddEditUser);
