import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { emitter } from '../../services';

class PubSubContainer extends Component {
  static propTypes = {
    children: PropTypes.object,
  };

  static childContextTypes = {
    emitter: PropTypes.object,
  };

  getChildContext() {
    return { emitter };
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default PubSubContainer;
