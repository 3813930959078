export const confirmAndFilterValues = (values, id, confirmMessage) => {
  const isConfirmed = window.confirm(confirmMessage);
  if (!isConfirmed) return null;

  const filteredValues = values.filter((values) => values.id !== id);
  return filteredValues;
};

export const isEmailValid = (email) => {
  const emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};
