import React, { Component } from 'react';
import { Flex } from 'grid-styled';
import { withRouter, Route, Switch } from 'react-router-dom';
import AddFeaturedBlocks from '../../routes/FeaturedBlocks/AddFeaturedBlock';
import EditFeaturedBlocks from '../../routes/FeaturedBlocks/EditFeaturedBlock';
import PropTypes from 'prop-types';

import FeaturedBlocks from '../../routes/FeaturedBlocks/FeaturedBlocks';

class FeaturedHomes extends Component {
  static propTypes = {
    marketData: PropTypes.object,
    match: PropTypes.object,
    params: PropTypes.object,
  };

  render() {
    const { slug, tabSlug } = this.props.match.params;
    const { id } = this.props.marketData;

    return (
      <Flex flexDirection="column">
        <Switch>
          <Route
            exact
            path={`/market/${slug}/featured-homes`}
            render={() => <FeaturedBlocks slug={slug} marketId={id} />}
          />
          <Route
            exact
            path={`/market/${slug}/featured-homes/add`}
            render={() => <AddFeaturedBlocks backLink={`${slug}/${tabSlug}`} marketId={id} />}
          />
          <Route
            exact
            path={`/market/${slug}/featured-homes/:featuredId`}
            render={() => <EditFeaturedBlocks backLink={`${slug}/${tabSlug}`} marketId={id} />}
          />
        </Switch>
      </Flex>
    );
  }
}

export default withRouter(FeaturedHomes);
