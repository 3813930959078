import React, { Component } from 'react';
import { Grid, Image, Button, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Flex, Box } from 'grid-styled';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';

import './PreviewQueryModal.scss';
class PreviewQueryModal extends Component {
  static propTypes = {
    properties: PropTypes.array,
    trigger: PropTypes.node,
    closeModal: PropTypes.func,
    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    properties: [],
    isOpen: true,
  };

  getPropertyAvailabilityDate = (property) => {
    const availableDate = moment(property.available_at);
    return `${availableDate.month() + 1}/${availableDate.date()}/${availableDate.year()}`;
  };

  render() {
    const { properties, isOpen, closeModal } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        size={'lg'}
        toggle={closeModal}
        autoFocus={true}
        keyboard={true}
        className={'preview-modal'}
      >
        <ModalHeader>Property Previews</ModalHeader>
        <ModalBody
          style={{
            height: `${isEmpty(properties) ? '100px' : '65vh'}`,
            overflowY: 'auto',
          }}
        >
          {isEmpty(properties) ? (
            <div>There are no properties to preview.</div>
          ) : (
            properties.map((property) => (
              <Grid key={property.id} columns={2} centered padded>
                <Grid.Row data-testid="property-row">
                  <Grid.Column>
                    <Image
                      src={get(
                        property,
                        'photos[0].image_url',
                        'https://images.invitationhomes.com/invh-web/image/upload/ils-marketing-assets/house-card-default-thumbnail.jpg',
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Grid columns={1} verticalAlign={'middle'} padded>
                      <Grid.Row className={'preview-modal__property-description'}>
                        <Grid.Column>
                          <Header>${property.market_rent}</Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Flex className="preview-modal__flex">
                            <Box>
                              <b>{property.beds}</b> br
                            </Box>
                            <Box>
                              <b>{property.baths}</b> ba
                            </Box>
                            <Box>
                              <b>{property.sqft}</b> sqft
                            </Box>
                          </Flex>
                        </Grid.Column>
                        <Grid.Column>{property.address_1}</Grid.Column>
                        <Grid.Column>
                          {property.city}, {property.state}
                        </Grid.Column>
                        {property.available_at && (
                          <Grid.Column
                            style={{
                              marginTop: '20px',
                              color: 'gray',
                            }}
                          >
                            Available At: {this.getPropertyAvailabilityDate(property.available_at)}
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ))
          )}
        </ModalBody>
        <ModalFooter>
          <Button primary onClick={closeModal} data-testid="close-button">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PreviewQueryModal;
