import React from 'react';
import axios from 'utils/axios';

import Card from 'components/Card';
import View from 'components/View';
import ViewHeader from 'components/ViewHeader';
import ViewContent from 'components/ViewContent';
import FeedsForm from 'containers/Feeds/Form';

export default function FeedCreate() {
  function onSubmit(values) {
    return axios
      .post(`/api/admin/feeds`, values, { headers: { 'Content-type': 'multipart/form-data' } })
      .then((response) => {
        if (response.status === 200) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return { redirect: `/feed/${response.data.id}?new` };
        } else {
          throw new Error('An unexpected error was encountered while attempting to create this feed');
        }
      });
  }

  return (
    <View>
      <ViewHeader title="Create Feed" subtitle="Create a new feed:" />
      <ViewContent>
        <Card>
          <FeedsForm onSubmit={onSubmit} />
        </Card>
      </ViewContent>
    </View>
  );
}
