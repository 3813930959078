import axios from './axios';

export async function fetchMarkets() {
  try {
    const res = await axios.get('/api/markets');
    return res.status === 200 ? res.data.results : [];
  } catch (error) {
    return [];
  }
}

export async function fetchAmenities() {
  try {
    const res = await axios.get('/api/admin/amenities');

    return res.status === 200 ? res.data : [];
  } catch (error) {
    return [];
  }
}

export async function buildQueryConfig() {
  const markets = await fetchMarkets();
  const amenities = await fetchAmenities();

  return {
    criteria: {
      operator_defs: {
        math_equal: { operator: '=', label: '=' },
        math_greater: { operator: '>', label: '>' },
        math_less: { operator: '<', label: '<' },
        math_greater_equal: { operator: '>=', label: '>=' },
        math_less_equal: { operator: '<=', label: '<=' },
        math_not_equal: { operator: '!=', label: '!=' },
        days_before: { operator: '<=', label: 'Days Before' },
        days_after: { operator: '>=', label: 'Days After' },
      },
      operator_group_defs: {
        math_equal_not_equal: ['math_equal', 'math_not_equal'],
        math_full: [
          'math_greater_equal',
          'math_greater',
          'math_less',
          'math_less_equal',
          'math_equal',
          'math_not_equal',
        ],
      },
      validation: {
        date: {
          regex: `^\\d{2}\\/\\d{2}\\/\\d{4}$`,
          description: 'date in the format of MM/DD/YYYY',
        },
        onlyAlpha: {
          regex: `^[A-Z ]+$`,
          opts: 'i',
          description: 'only alphabetical characters',
        },
        onlyNumeric: {
          regex: `^\\d+(\\.?\\d)*$`,
          description: 'only numeric characters',
        },
        zipCode: {
          regex: `^\\d{5}$`,
          description: 'only five numbers',
        },
        boolean: {
          regex: `^(true|false)$`,
          description: 'either true or false',
        },
      },
      criteria_defs: [
        {
          name: 'amenities',
          label: 'Amenities',
          type: 'list',
          data_type: 'uuid',
          operators: ['group:math_equal_not_equal'],
          values: amenities.map((amenity) => ({
            [amenity.id]: amenity.name,
          })),
        },
        {
          name: 'city',
          label: 'City',
          type: 'text',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
          validation: ['onlyAlpha'],
        },
        {
          name: 'hasSubdivision',
          label: 'Has Subdivision',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'syndication_days',
          label: 'Syndication Days',
          type: 'text',
          data_type: 'integer',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'market_id',
          label: 'Market',
          type: 'list',
          operators: ['group:math_equal_not_equal'],
          data_type: 'uuid',
          values: markets.map((market) => ({
            [market.id]: market.name,
          })),
        },
        {
          name: 'beds',
          label: 'Bedrooms',
          type: 'text',
          data_type: 'integer',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'baths',
          label: 'Bathrooms',
          type: 'text',
          data_type: 'integer',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'market_rent',
          label: 'Rent Price',
          type: 'text',
          data_type: 'integer',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'sqft',
          label: 'Square Footage',
          type: 'text',
          data_type: 'integer',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'available',
          label: 'Available Date',
          type: 'text',
          data_type: 'integer',
          operators: ['days_before', 'days_after'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'in_active_leasing_special',
          label: 'In Active Leasing Special',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'has_photos',
          label: 'Has Photos',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'has_tour',
          label: 'Has Tour',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'pre_market',
          label: 'Pre Market',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'otm',
          label: 'On The Market',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'exclusive',
          label: 'Is Exclusive',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'is_leasing_special',
          label: 'Is Leasing Special',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'has_floorplan',
          label: 'Has Floor Plan',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'has_all_pro_photos',
          label: 'Has All Professional Photos',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'has_pro_photos',
          label: 'Has Professional Photos',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'unit_status',
          label: 'Unit Status',
          type: 'list',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
          validation: ['onlyAlpha'],
          values: [
            {
              'Notice Unrented': 'Notice Unrented',
            },
            {
              'Vacant Unrented Not Ready': 'Vacant Unrented Not Ready',
            },
            {
              'Vacant Unrented Ready': 'Vacant Unrented Ready',
            },
          ],
        },
        {
          name: 'allow_selfshow',
          label: 'Allows Self-Show',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'allow_syndication_zillow',
          label: 'Allows Syndication to Zillow',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'model_home',
          label: 'Model Home',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'syndication_days_zillow',
          label: 'Syndication Days (Zillow)',
          type: 'number',
          data_type: 'number',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'allow_syndication_realtor',
          label: 'Allows Syndication to Realtor.com',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'syndication_days_realtor',
          label: 'Syndication Days (Realtor)',
          type: 'number',
          data_type: 'number',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'allow_syndication_facebook',
          label: 'Allows Syndication to Facebook',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'syndication_days_facebook',
          label: 'Syndication Days (Facebook)',
          type: 'number',
          data_type: 'number',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'allow_syndication_zumper',
          label: 'Allows Syndication to Zumper',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'syndication_days_zumper',
          label: 'Syndication Days (Zumper)',
          type: 'number',
          data_type: 'number',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'allow_syndication_apartmentlist',
          label: 'Allows Syndication to Apartmentlist',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'syndication_days_apartmentlist',
          label: 'Syndication Days (Apartmentlist)',
          type: 'number',
          data_type: 'number',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'allow_syndication_apartments',
          label: 'Allows Syndication to Apartments.com',
          type: 'list',
          data_type: 'boolean',
          operators: ['group:math_equal_not_equal'],
          validation: ['boolean'],
          values: [
            {
              true: 'True',
            },
            {
              false: 'False',
            },
          ],
        },
        {
          name: 'syndication_days_apartments',
          label: 'Syndication Days (Apartments.com)',
          type: 'number',
          data_type: 'number',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'property_code',
          label: 'Property Code',
          type: 'text',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
        },
        {
          name: 'rehab_type',
          label: 'Rehab Type',
          type: 'text',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
        },
        {
          name: 'bid_type',
          label: 'Bid Type',
          type: 'text',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
        },
        {
          name: 'subdivision',
          label: 'Subdivision',
          type: 'text',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
        },
        {
          name: 'days_on_market',
          label: 'Days On Market',
          data_type: 'number',
          operators: ['group:math_full'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'est_rehab_complete_date',
          label: 'Est. Rehab Complete Date',
          type: 'text',
          data_type: 'integer',
          operators: ['days_before', 'days_after'],
          validation: ['onlyNumeric'],
        },
        {
          name: 'submarket_names',
          label: 'Submarket Names',
          type: 'text',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
        },
        {
          name: 'asset_review_type',
          label: 'Asset Review Type',
          type: 'text',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
        },
        {
          name: 'property_type',
          label: 'Property Type',
          type: 'list',
          data_type: 'string',
          operators: ['group:math_equal_not_equal'],
          validation: ['onlyAlpha'],
          values: [
            {
              Condo: 'Condo',
            },
            {
              Corporate: 'Corporate',
            },
            {
              Duplex: 'Duplex',
            },
            {
              Entity: 'Entity',
            },
            {
              Fourplex: 'Fourplex',
            },
            {
              Land: 'Land',
            },
            {
              'Manufactured Housing': 'Manufactured Housing',
            },
            {
              'Multi Family': 'Multi Family',
            },
            {
              Other: 'Other',
            },
            {
              'Single Family Home': 'Single Family Home',
            },
            {
              Townhome: 'Townhome',
            },
            {
              Triplex: 'Triplex',
            },
          ],
        },
      ],
    },
    ordering: {
      operators_defs: {
        'a-z': { direction: 'ASC', label: 'A-Z' },
        'z-a': { direction: 'DESC', label: 'Z-A' },
        'low-high': { direction: 'ASC', label: 'Low to High' },
        'high-low': { direction: 'DESC', label: 'High to Low' },
        'false-true': { direction: 'ASC', label: 'False-True' },
        'true-false': { direction: 'DESC', label: 'True-False' },
      },
      criteria: [
        {
          name: 'syndication_days',
          label: 'Syndication Days',
          operators: ['low-high', 'high-low'],
        },
        {
          name: 'city',
          label: 'City',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'zipcode',
          label: 'Zip code',
          operators: ['low-high', 'high-low'],
        },
        {
          name: 'bedrooms',
          label: 'Bedrooms',
          operators: ['low-high', 'high-low'],
        },
        {
          name: 'bathrooms',
          label: 'Bathrooms',
          operators: ['low-high', 'high-low'],
        },
        {
          name: 'rent',
          label: 'Rent Price',
          operators: ['low-high', 'high-low'],
        },
        {
          name: 'sqft',
          label: 'Square Footage',
          operators: ['low-high', 'high-low'],
        },
        {
          name: 'available',
          label: 'Available Date',
          operators: ['low-high', 'high-low'],
        },
        {
          name: 'est_rehab_complete_date',
          label: 'Est. Rehab Complete Date',
          operators: ['low-high', 'high-low'],
        },
        {
          name: 'has_photos',
          label: 'Has Photos',
          operators: ['false-true', 'true-false'],
        },
        {
          name: 'has_tour',
          label: 'Has Tour',
          operators: ['false-true', 'true-false'],
        },
        {
          name: 'pre_market',
          label: 'Pre Market',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'otm',
          label: 'On The Market',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'exclusive',
          label: 'IH Exclusive',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'is_leasing_special',
          label: 'Is Leasing Special',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'has_floorplan',
          label: 'Has Floor Plan',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'has_all_pro_photos',
          label: 'Has All Professional Photos',
          operators: ['false-true', 'true-false'],
        },
        {
          name: 'has_pro_photos',
          label: 'Has Professional Photos',
          operators: ['false-true', 'true-false'],
        },
        {
          name: 'unit_status',
          label: 'Unit Status',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'self_show',
          label: 'Allows Self-Show',
          operators: ['false-true', 'true-false'],
        },
        {
          name: 'property_code',
          label: 'Property Code',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'property_type',
          label: 'Property Type',
          operators: ['a-z', 'z-a'],
        },
        {
          name: 'subdivision',
          label: 'Subdivision',
          operators: ['a-z', 'z-a'],
        },
      ],
    },
  };
}
