import React, { useState, useEffect } from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import FeedFormatsForm from 'containers/FeedFormats/Form';

import { useParams } from 'react-router-dom';
import axios from 'utils/axios';

import View from 'components/View';
import ViewHeader from 'components/ViewHeader';
import ViewContent from 'components/ViewContent';

export default function FeedFormatsEdit(props) {
  const { id } = useParams();

  const [values, setValues] = useState(false);

  useEffect(() => {
    // Fetch the feed format
    axios.get(`/api/admin/feed-format/${id}`).then((response) => {
      if (response && response.status === 200) {
        setValues(response.data);
      }
    });
  }, []);

  function onSubmit(values) {
    return axios
      .put(`/api/admin/feed-format/${id}`, omit(values, ['id', 'created_at', 'updated_at']))
      .then((response) => response && response.status === 200);
  }

  return (
    <View>
      <ViewHeader title={`Editing Feed Format`} subtitle={values && values.title} />
      <ViewContent>
        {values && <FeedFormatsForm initialValues={values} onSubmit={onSubmit} location={props.location} />}
      </ViewContent>
    </View>
  );
}

FeedFormatsEdit.propTypes = {
  location: PropTypes.object,
};
