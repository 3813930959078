import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

import descriptionStatus from '../../lang/property/description';
import ValidationError from '../../components/validation/ValidationError';
import { withPermissions } from '../../components/HoC';

import AssetReviewTypes from './overview/AssetReviewTypes';
import { useFormContext } from 'react-hook-form';
import { CircularProgress, Card, Box, TextField, InputLabel, FormLabel } from '@material-ui/core';
import { isFieldRequired, isCurrentStatus, translateBooleanToYesNo } from 'utils/syndication';
const Overview = ({ transformNullValues, classes, permissions: { canEditPropertyDetails = false } }) => {
  const methods = useFormContext({
    mode: 'onBlur',
    revalidateMode: 'onChange',
  });
  const {
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const values = watch();

  const statuses = ['Notice Unrented', 'Vacant Unrented Not Ready'];

  return values.loading ? (
    <CircularProgress />
  ) : (
    <Box>
      <FormLabel className={classes.pageTitle}>Overview</FormLabel>
      <Box p={1}>
        <strong>Company Code:</strong> {values.company?.code}
      </Box>
      <Box p={1}>
        <strong>Market:</strong> {values.market.name}
      </Box>
      <Box p={1}>
        <strong>Office:</strong> {values.office.name}
      </Box>
      <Box p={1}>
        <strong>Property Details URL: </strong>
        <a href={values.property_url} target="_blank" rel="noreferrer" className="hyperlink">
          {values.property_url}
        </a>
      </Box>
      <Box p={1}>
        <strong>Application URL: </strong>
        <a href={values.application_url} target="_blank" rel="noreferrer" className="hyperlink">
          {values.application_url}
        </a>
      </Box>
      <Box p={1}>
        <strong>Yardi Property Code: </strong>
        {values.property_code}
      </Box>
      <Box p={1}>
        <strong>Property Status: </strong>
        {values.property_status}
      </Box>
      <Box p={1}>
        <strong>Unit Status: </strong>
        {values.unit_status}
      </Box>
      <Box p={1}>
        <strong>Availability Status: </strong>
        {moment(values.available_at).utc().format('l')}
      </Box>
      <Box p={1}>
        <strong>Has Selfshow: </strong>
        {translateBooleanToYesNo(values.allow_selfshow)}
      </Box>
      <Box p={1}>
        <strong>Selfshow Enabled: </strong>
        {translateBooleanToYesNo(values.selfshow_enabled)}
      </Box>
      <Box p={1}>
        <strong>Has Trouble Condition: </strong>
        {translateBooleanToYesNo(values.has_trouble_condition)}
      </Box>
      <Box p={1}>
        <strong>Rehab Type: </strong>
        {values.rehab_type}
      </Box>
      <Box p={1}>
        <strong>Subdivision: </strong>
        {values.subdivision}
      </Box>
      <Box p={1}>
        <strong>Days on Market: </strong>
        {values.days_on_market}
      </Box>
      <Box p={1}>
        <strong>Est. Rehab Completion Date: </strong>
        {moment(values.est_rehab_complete_date).format('l')}
      </Box>
      <Box p={1}>
        <strong>Bid Type: </strong>
        {values.bid_type}
      </Box>
      <Box p={1}>
        <strong>Submarket Name: </strong>
        {values.submarket_names}
      </Box>
      <Box p={1}>
        <strong>Latitude: </strong>
        {values.lat}
      </Box>
      <Box p={1}>
        <strong>Longitude: </strong>
        {values.lng}
      </Box>
      <Box p={1}>
        <strong>Asset Review Type: </strong>
        {values.asset_review_type}
      </Box>
      <Box p={1}>
        <strong>Property Type: </strong>
        {values.property_type}
      </Box>
      <AssetReviewTypes canEditPropertyDetails={canEditPropertyDetails} />
      <Box w={1} p={1} className={classes.column}>
        <ValidationError>{errors?.description?.message}</ValidationError>
        <InputLabel required className={classes.label}>
          Description
        </InputLabel>

        <Box>
          <TextField
            multiline
            fullWidth
            minRows={20}
            variant="outlined"
            {...methods.register('longdesc', { required: 'Required Field' })}
            disabled={!canEditPropertyDetails}
            placeholder="Enter property description."
            value={transformNullValues(values.longdesc)}
            data-testid="property_description"
          />
        </Box>
        <Box width={1}>
          <FormLabel className={classes.title}>Model Home Info</FormLabel>

          <Box w={1} className={classes.column}>
            <ValidationError>{errors?.model_home_modal_info?.message}</ValidationError>
            <InputLabel required={values.model_home} className={classes.label}>
              Community Description
            </InputLabel>

            <Box>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                minRows="20"
                name="model_home_modal_info"
                {...methods.register('model_home_modal_info', {
                  required: isFieldRequired(values.model_home),
                })}
                disabled={!values.model_home}
                placeholder={'Enter Model Home Community description.'}
                value={transformNullValues(values.model_home_modal_info)}
                data-testid="community_info"
              />
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box width="180px" marginRight={'10px'} className={classes.column}>
              <ValidationError>{errors?.model_home_details?.community_name?.message}</ValidationError>
              <InputLabel required={values.model_home} className={classes.label}>
                Community Name{' '}
              </InputLabel>

              <Box>
                <TextField
                  size="small"
                  variant="outlined"
                  {...methods.register('model_home_details.community_name', {
                    required: isFieldRequired(values.model_home),
                  })}
                  disabled={!values.model_home}
                  placeholder="Community Name."
                  value={transformNullValues(values.model_home_details.community_name)}
                  data-testid="community_name"
                />
              </Box>
            </Box>
            <Box width="500px" marginRight={'10px'} className={classes.column}>
              <ValidationError>{errors?.model_home_details?.address?.message}</ValidationError>
              <InputLabel required={values.model_home} className={classes.label}>
                Model Home Address
              </InputLabel>

              <Box>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  {...methods.register('model_home_details.address', {
                    required: isFieldRequired(values.model_home),
                  })}
                  disabled={!values.model_home}
                  placeholder="Model Home Address include city and state"
                  value={transformNullValues(values.model_home_details.address)}
                  data-testid="community_address"
                />
              </Box>
            </Box>
            <Box width="290px" marginRight={'10px'} className={classes.column}>
              <ValidationError>{errors?.model_home_details?.operating_hours?.message}</ValidationError>
              <InputLabel required={values.model_home} className={classes.label}>
                Operating Hours{' '}
              </InputLabel>

              <Box>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  {...methods.register('model_home_details.operating_hours', {
                    required: isFieldRequired(values.model_home),
                  })}
                  disabled={!values.model_home}
                  placeholder="Operating Hours"
                  value={transformNullValues(values.model_home_details.operating_hours)}
                  data-testid="operating_hours"
                />
              </Box>
            </Box>
            <Box className={classes.column}>
              <InputLabel className={classes.label}>Interest Form URL</InputLabel>
              <Box>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  {...methods.register('model_home_details.interest_form_url')}
                  disabled={!values.model_home}
                  placeholder="Interest Form URL"
                  value={transformNullValues(values.model_home_details.interest_form_url)}
                  data-testid="interest_form"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width={1} p={1}>
        <Box mb={1}>
          <Card className={classes.card} elevation={0}>
            <strong>Note:</strong>
            <p>
              The below preview shows what the above description will look like combined with boilerplate text based on
              the current unit status. The above text will be inserted between the status related text and the legal
              text.
            </p>
          </Card>
        </Box>
        <label style={{ marginBottom: '10px' }}>
          <strong style={{ display: 'inline-block' }}>Live Preview</strong>
          <select
            style={{
              display: 'inline-block',
              width: 'auto',
              marginLeft: 10,
            }}
            disabled={!canEditPropertyDetails}
            value={values.preview_status}
            onChange={(e) => setValue('preview_status', e.target.value)}
          >
            {statuses.map((status, index) => {
              return (
                <option value={status} key={index}>
                  {status} {isCurrentStatus(status, values)}
                </option>
              );
            })}
          </select>
        </label>
        <p>{values.description}</p>
        <p>{descriptionStatus.status(values.preview_status)}</p>
        <p>
          CONSENT TO TEXT MESSAGING: By entering your mobile phone number, you expressly consent to receive text
          messages from Invitation Homes.Msg & Data rates may apply.
        </p>
      </Box>
    </Box>
  );
};
export default withPermissions(Overview);

Overview.propTypes = {
  permissions: PropTypes.object,
  classes: PropTypes.object,
  transformNullValues: PropTypes.func,
};
