import React from 'react';
import View from '../components/View';
import ViewHeader from '../components/ViewHeader';
import ViewContent from '../components/ViewContent';
import { withRouter } from 'react-router-dom';
import './bulkOrder.scss';
import BulkPhotoOrderContent from '../components/BulkPhotoOrder';

const BulkPhotoOrder = () => {
  return (
    <View>
      <ViewHeader
        title="Bulk Upload for Photo Orders"
        subtitle="Upload a csv file to bulk upload photo orders"
      ></ViewHeader>

      <ViewContent>
        <BulkPhotoOrderContent />
      </ViewContent>
    </View>
  );
};

export default withRouter(BulkPhotoOrder);
