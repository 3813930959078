import React, { Component } from 'react';
import { Flex, Box } from 'grid-styled';
import { Radio, TextArea, Form, Button, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import ValidationRequired from '../../components/validation/ValidationRequired';
import ValidationError from '../../components/validation/ValidationError';

import { QueryBuilderContext } from '../../components/QueryBuilder';
import PreviewQueryModal from '../../components/QueryBuilder/PreviewQueryModal';
import { previewFeatureBlock } from '../../api';
import { removeIdsFromQueryState } from '../../utils/queryBuilder';
import QueryBuilderHelper from 'utils/queryBuilderHelper';
import { filterAndConcatProperties } from 'utils/featuredBlock';

import { get, findIndex } from 'lodash';

class AddEditFeatureBlockForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    backLink: PropTypes.string.isRequired,
    notificationMsg: PropTypes.string,
    notificationType: PropTypes.string,
    backIcon: PropTypes.string,
    headline: PropTypes.string,
    maxNumPropertiesAllowed: PropTypes.number,
    title_error: PropTypes.bool,
    title: PropTypes.string,
    view_type: PropTypes.string,
    description_error: PropTypes.bool,
    description: PropTypes.string,
    long_description_error: PropTypes.bool,
    long_description: PropTypes.string,
    previewImg: PropTypes.string,
    is_visible: PropTypes.string,
    questionIcon: PropTypes.string,
    onImageUpload: PropTypes.func,
    children: PropTypes.object,
    marketId: PropTypes.string,
    show_promo_button: PropTypes.bool,
    no_lifestyle_photo: PropTypes.bool,
    removeImageFromState: PropTypes.func,
    isSaving: PropTypes.bool,
    queryConfig: PropTypes.object,
    query: PropTypes.object,
    displayQueryBuilder: PropTypes.bool,
  };

  static defaultProps = {
    long_description: '',
    description: '',
  };

  state = {
    previewProperties: [],
    isPreviewModalOpen: false,
  };

  onRemoveImage = () => {
    const c = window.confirm('Are you sure you want to remove the image?');
    if (c) {
      // unset the file field's value so that onChange will fire if same file is re-selected by the user the next time
      // the file upload form is used.
      this.fileUploadRef && (this.fileUploadRef.value = null);
      this.props.removeImageFromState();
    }
  };

  displayPreviewPropertiesModal = async (e, query, updateFeatureBlockState) => {
    e.preventDefault();

    const { marketId, queryConfig, displayQueryBuilder, displayManualPropertyAdditions } = this.props;

    const helperInstance = new QueryBuilderHelper(queryConfig);
    const isQueryValid = helperInstance.hasValidQuery(query.criteria);

    if (isQueryValid && (displayQueryBuilder || displayManualPropertyAdditions)) {
      const sanitizedQuery = {
        ...query,
        criteria: removeIdsFromQueryState(query.criteria && [query.criteria])[0],
        manually_added_properties: this.props.query.manually_added_properties,
        ordering: removeIdsFromQueryState(query.ordering),
      };

      const result = await previewFeatureBlock(marketId, sanitizedQuery);

      const manuallyAddedPropertyIds = get(query, 'manually_added_properties', []);

      const rowData = get(result, 'data.rows', []);

      const manuallyAddedProperties = manuallyAddedPropertyIds.reduce((mapArray, mapId) => {
        const foundIndex = findIndex(rowData, ['id', mapId]);
        if (foundIndex >= 0) {
          mapArray.push(rowData[foundIndex]);
        }

        return mapArray;
      }, []);

      const previewProperties = filterAndConcatProperties(rowData, manuallyAddedPropertyIds, manuallyAddedProperties);

      this.setState({
        previewProperties,
        isPreviewModalOpen: true,
      });
    } else if (!isQueryValid && isQueryValid !== undefined) {
      updateFeatureBlockState({ isQueryValid });
    } else {
      this.setState({
        isPreviewModalOpen: true,
        previewProperties: [],
      });
    }
  };

  closeModal = () => {
    this.setState({
      isPreviewModalOpen: false,
    });
  };

  render() {
    const {
      onSubmit,
      notificationMsg,
      notificationType,
      backLink,
      backIcon,
      headline,
      maxNumPropertiesAllowed,
      onChange,
      title_error,
      title,
      view_type,
      description_error,
      description = '',
      long_description = '',
      previewImg,
      is_visible,
      questionIcon,
      onImageUpload,
      children,
      show_promo_button,
      no_lifestyle_photo,
      isSaving,
    } = this.props;
    return (
      <QueryBuilderContext.Consumer>
        {({ query, updateFeatureBlockState }) => (
          <Box pb={100}>
            <Form onSubmit={() => onSubmit(query)}>
              {notificationMsg && <Alert color={notificationType}>{notificationMsg}</Alert>}
              <Flex>
                <Box pl="2%" pb={20} pt={20}>
                  <Link to={`/market/${backLink}`} style={{ color: 'var(--green)' }}>
                    <img src={backIcon} alt="" /> Back to All Featured Homes Blocks
                  </Link>
                </Box>
              </Flex>
              <Flex>
                <Box pl="2%" pb={20} pt={20}>
                  <h2>{headline}</h2>
                </Box>
              </Flex>
              <Flex>
                <Box pl="2%" pb={20} pt={20} width={[1, 1, 1 / 4]}>
                  <Box>
                    <CustomInput
                      withValidation
                      validationError={title_error}
                      label="Name"
                      name="title"
                      value={title}
                      onChange={onChange}
                    />
                  </Box>

                  <Box mt={30}>
                    <label>
                      <strong>View type</strong>
                    </label>
                    <Flex>
                      <Box>
                        <Radio
                          id="view_type_grid"
                          label="Grid"
                          name="view_type"
                          checked={view_type === 'Grid'}
                          onChange={onChange}
                        />
                      </Box>
                      <Box ml={10}>
                        <Radio
                          id="view_type_promo"
                          label="Promo"
                          name="view_type"
                          checked={view_type === 'Promo'}
                          onChange={onChange}
                        />
                      </Box>
                    </Flex>
                  </Box>
                  {view_type === 'Promo' && (
                    <Box>
                      <Box mt={30}>
                        {description_error && <ValidationError>Required field</ValidationError>}
                        <label>
                          <strong>
                            Description
                            <ValidationRequired />
                          </strong>
                        </label>
                        <TextArea name="description" maxLength="2000" value={description} onChange={onChange} />
                        <Box mt={10}>Max length: 2000 characters</Box>
                      </Box>
                      <Box mt={30}>
                        <label>
                          <strong>Long Description</strong>
                        </label>
                        <TextArea name="long_description" value={long_description} onChange={onChange} />
                      </Box>
                      <Box mt={30}>
                        <Checkbox
                          label='Show the "View Homes" button'
                          name="show_promo_button"
                          checked={show_promo_button}
                          onChange={onChange}
                        />
                      </Box>
                    </Box>
                  )}
                  {view_type === 'Promo' && (
                    <Box mt={30}>
                      <label>
                        <strong>
                          Photo{' '}
                          <span className="featured-tooltip">
                            <img alt="questions" className="cursor-pointer" src={questionIcon} />
                            <div>
                              A photo will display on the promo for desktop users. If you do not select a photo, a
                              lifestyle image will be chosen at random, unless you check the &quot;No lifestyle
                              photo&quot; box.
                            </div>
                          </span>
                        </strong>
                      </label>

                      <Box width={1}>
                        {previewImg ? (
                          <Box mb={10}>
                            <img id="promo_preview_image" alt="Preview" src={previewImg} />
                          </Box>
                        ) : null}
                        <Flex css="margin-top: 10px;">
                          <Button type="button" className="file-btn">
                            <input
                              name="upload_promo_photo"
                              type="file"
                              onChange={onImageUpload}
                              ref={(el) => (this.fileUploadRef = el)}
                            />
                            {previewImg ? 'Change' : 'Add a'} photo
                          </Button>
                          {previewImg ? (
                            <Button
                              type="button"
                              onClick={this.onRemoveImage}
                              className="ui red button"
                              style={{ marginLeft: '10px' }}
                            >
                              Remove Photo
                            </Button>
                          ) : null}
                        </Flex>
                        {!previewImg && (
                          <Box mt={30}>
                            <Checkbox
                              label="No lifestyle photo "
                              name="no_lifestyle_photo"
                              checked={no_lifestyle_photo}
                              onChange={onChange}
                            />
                            <span className="featured-tooltip" style={{ margin: '0 5px' }}>
                              <img alt="questions" className="cursor-pointer" src={questionIcon} />
                              <div>If you select this option, no lifestyle image will be added to the promo block.</div>
                            </span>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                  <Box mt={30}>
                    <label>
                      <strong>Visible on Market Landing Page </strong>
                    </label>
                    <Flex>
                      <Box>
                        <Radio
                          id="is_visible_yes"
                          label="Yes"
                          name="is_visible"
                          checked={is_visible === 'Yes'}
                          onChange={onChange}
                        />
                      </Box>
                      <Box ml={10}>
                        <Radio
                          id="is_visible_no"
                          label="No"
                          name="is_visible"
                          checked={is_visible === 'No'}
                          onChange={onChange}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
              {/* Adds any additional form children needed here */}
              {children}
              <Flex>
                <Box ml="2%">
                  Click and drag rows to reorder. Maximum of {maxNumPropertiesAllowed} properties allowed.
                </Box>
                <Box ml="auto">
                  <Button
                    style={{ background: 'none', color: '#2185d0' }}
                    onClick={(e) => this.displayPreviewPropertiesModal(e, query, updateFeatureBlockState)}
                  >
                    Preview Properties
                  </Button>
                  <Button type="submit" primary disabled={isSaving}>
                    Save Changes
                  </Button>
                  <PreviewQueryModal
                    properties={this.state.previewProperties}
                    isOpen={this.state.isPreviewModalOpen}
                    closeModal={this.closeModal}
                  />
                </Box>
              </Flex>
            </Form>
          </Box>
        )}
      </QueryBuilderContext.Consumer>
    );
  }
}

export default AddEditFeatureBlockForm;
