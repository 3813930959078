import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Card from 'components/Card';
import Table from 'components/Table';
import View from 'components/View';
import ViewHeader from 'components/ViewHeader';
import ViewContent from 'components/ViewContent';
import withTableResources from 'containers/TableStateContainer';
import { withPermissions } from 'components/HoC';

function FeedFormatsList(props) {
  const { tableState, onFetchData } = props;
  useEffect(() => {
    onFetchData();
  }, []);

  return (
    <View>
      <ViewHeader title="Manage Feed Formats" subtitle="List view of all feed formats:" />
      <ViewContent>
        <Card>
          {' '}
          <Table
            data={tableState.data}
            columns={tableState.columns}
            page={tableState.page}
            pages={tableState.pages}
            filtered={tableState.filtered}
            sorted={tableState.sorted}
            loading={tableState.loading}
            {...props}
          />
          <Link to="/feed-formats/create" className="ui button primary right floated segment">
            <i className="left plus icon" /> Create Feed Format
          </Link>
          <Link to="/feeds/create" className="ui button secondary right floated segment">
            <i className="left plus icon" /> Create Feed
          </Link>
          <Link to="/feeds" className="ui button secondary right floated segment">
            <i className="left list icon" /> Feeds
          </Link>
        </Card>
      </ViewContent>
    </View>
  );
}

FeedFormatsList.propTypes = {
  tableState: PropTypes.object,
  permissions: PropTypes.object,
  onFetchData: PropTypes.func,
};

export default withPermissions(
  withTableResources(FeedFormatsList, {
    endpoint: '/api/admin/feed-formats',
    columnFilter: (col) => col.key !== 'template',
    canDelete: true,
    route: '/feed-format',
    name: 'delete_feed_format',
    headline: 'Delete Feed Format',
    description:
      'Are you sure you want to delete this format?  This will permanantly delete any feeds associated with it.',
  }),
);
