import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'grid-styled';
import deleteIcon from '../../../icons/delete.svg';
import { API } from '../utils';
import withEmitter from '../../../components/HoC/withEmitter';

class Properties extends Component {
  static propTypes = {
    special: PropTypes.object.isRequired,
    emitter: PropTypes.object.isRequired,
  };

  state = {
    // we may update the list of properties (delete a property)
    // so we keep a mutatedSpecial around to avoid needing to remount the component.
    mutatedSpecial: null,
  };

  get special() {
    return this.state.mutatedSpecial || this.props.special;
  }

  renderNoSpecials = () => {
    return (
      <div className="rt-tr-group">
        <Box pt="2%" pb="2%" style={{ textAlign: 'center' }}>
          <h5>No results found</h5>
          <p>There are no items in this list.</p>
        </Box>
      </div>
    );
  };

  deletePropertyFromSpecial = (address1, id) => {
    const c = window.confirm(`Are you sure you want to delete "${address1}" from this special?`);

    if (c) {
      const { emitter } = this.props;
      API.deleteSpecialProperty(id).then(() => {
        const special = { ...this.special };
        this.setState({
          mutatedSpecial: {
            ...special,
            properties: special.properties.filter((property) => property.id !== id),
          },
        });
        emitter.emit('alert-queue:add', {
          type: 'success',
          message: 'Successfully deleted property from special.',
        });
      });
    }
  };

  renderList = () => {
    const { properties } = this.special;
    return (
      (properties &&
        properties.length &&
        properties.map((propertySpecial, i) => {
          const { property } = propertySpecial;
          return (
            <div className="rt-tr-group" key={propertySpecial.id}>
              <div className={`rt-tr ${i % 2 ? '-even' : '-odd'}`}>
                <div className="rt-td" style={largeColumn}>
                  {property.address_1}
                </div>
                <div className="rt-td" style={rowStyle}>
                  {property.city}
                </div>
                <div className="rt-td" style={rowStyle}>
                  {property.state}
                </div>
                <div className="rt-td" style={rowStyle}>
                  {property.zipcode}
                </div>
                <div className="rt-td" style={rowStyle}>
                  {property.property_code}
                </div>
                <div className="rt-td" style={rowStyle}>
                  {propertySpecial.concession_amount}
                </div>
                <div className="rt-td" style={rowStyle}>
                  {propertySpecial.lease_terms || '-'}
                </div>
                <div className="rt-td" style={{ textAlign: 'center' }}>
                  <img
                    alt="delete"
                    className="cursor-pointer"
                    src={deleteIcon}
                    onClick={() => {
                      this.deletePropertyFromSpecial(property.address_1, propertySpecial.id);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })) ||
      this.renderNoSpecials()
    );
  };

  render() {
    return (
      <div>
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h3>Properties in Leasing Special</h3>
          </Box>
        </Flex>
        <Flex>
          <Box pl="2%" pb={20} width={1}>
            <div className="ReactTable -striped -highlight" style={containerStyle}>
              <div className="rt-table">
                <div className="rt-thead -header" style={containerStyle}>
                  <div className="rt-tr">
                    <div className="rt-th" style={largeColumn}>
                      <div>Street Address</div>
                    </div>
                    <div className="rt-th">
                      <div>City</div>
                    </div>
                    <div className="rt-th">
                      <div>State</div>
                    </div>
                    <div className="rt-th">
                      <div>Zip Code</div>
                    </div>
                    <div className="rt-th">
                      <div>Property Code</div>
                    </div>
                    <div className="rt-th">
                      <div>Concession Amount</div>
                    </div>
                    <div className="rt-th">
                      <div>Lease Terms</div>
                    </div>
                    <div className="rt-th">
                      <div>Remove</div>
                    </div>
                  </div>
                </div>
                <div className="rt-tbody" style={containerStyle}>
                  {this.renderList()}
                </div>
              </div>
            </div>
          </Box>
        </Flex>
      </div>
    );
  }
}

const containerStyle = { minWidth: '900px', overflowX: 'scroll' };
const rowStyle = { textAlign: 'center' };
const largeColumn = {
  minWidth: '300px',
  maxWidth: '300px',
  textAlign: 'center',
};

export default withEmitter(Properties);
