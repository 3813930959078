export const isFieldRequired = (value) => {
  return value ? 'Required Field' : false;
};

export const isCurrentStatus = (status, values) => {
  return values?.unit_status === status && '(current status)';
};

export const translateBooleanToYesNo = (boolean) => (boolean ? 'Yes' : 'No');

export const isChecked = (value, values) => {
  if (Array.isArray(values?.amenities)) {
    if (values.amenities.filter((amenity) => amenity.name === value.name).length !== 0) return true;
  }
};

export const prepareUpdatePayload = (values) => {
  return {
    title: values.title !== null ? values.title : '',
    shortdesc: values.shortdesc !== null ? values.shortdesc : '',
    longdesc: values.longdesc,
    tout: values.tout !== null ? values.tout : '',
    disclaimer: values.disclaimer,
    active: !!values.active,
    amenities: values.amenities,
    allow_featured: values.allow_featured,
    allow_greatschools: values.allow_greatschools,
    is_pet_friendly: values.is_pet_friendly,
    new_construction: values.new_construction,
    btr_community: values.btr_community,
    est_rehab_complete_date: values.est_rehab_complete_date,
    rehab_type: values.rehab_type,
    submarket_names: values.submarket_names,
    subdivision: values.subdivision,
    pro_photos: values.pro_photos,
    syndication_status: values.syndication_status,
    allow_syndication_zillow: !!values.allow_syndication_zillow,
    allow_syndication_realtor: !!values.allow_syndication_realtor,
    allow_syndication_facebook: !!values.allow_syndication_facebook,
    allow_syndication_zumper: !!values.allow_syndication_zumper,
    allow_syndication_apartmentlist: !!values.allow_syndication_apartmentlist,
    allow_syndication_apartments: !!values.allow_syndication_apartments,
    allow_syndication_rentals: !!values.allow_syndication_rentals,
    syndication_days: values.syndication_days,
    syndication_days_zillow: values.syndication_days_zillow,
    syndication_days_realtor: values.syndication_days_realtor,
    syndication_days_facebook: values.syndication_days_facebook,
    syndication_days_zumper: values.syndication_days_zumper,
    syndication_days_apartmentlist: values.syndication_days_apartmentlist,
    syndication_days_apartments: values.syndication_days_apartments,
    syndication_days_rentals: values.syndication_days_rentals,
    boost_apartments: values.boost_apartments,
    model_home: values.model_home,
    model_home_modal_info: values.model_home_modal_info,
    model_home_community_name: values.model_home_details.community_name,
    model_home_operating_hours: values.model_home_details.operating_hours,
    model_home_address: values.model_home_details.address,
    model_home_interest_form_url: values.model_home_details.interest_form_url,
    bid_type: values.bid_type,
    asset_review_type: values.asset_review_type,
    days_on_market: values.days_on_market,
    syndication_status_note: values.syndication_status_note,
    clearAmenities: true,
  };
};
