import React, { useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { Message } from 'semantic-ui-react';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle, dropFinishedStyle } from './md-style';
import './style.scss';

const DragDropFile = (props) => {
  const { title, disabled, message, hasError, onDrop, onDismissMessage } = props;

  const [dropFinished, setDropFinished] = useState(false);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    disabled,
    onDrop,
    onDropAccepted: () => {
      setDropFinished(true);
    },
    maxFiles: 1,
    multiple: false,
    accept: { 'text/csv': [] },
  });

  const style = useMemo(
    // eslint-disable-next-line complexity
    () => ({
      ...baseStyle,
      ...(dropFinished ? dropFinishedStyle : {}),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(hasError ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, hasError, dropFinished],
  );

  const messageTitle = message?.messageType === 'positive' ? 'Success' : 'Fail';

  return (
    <>
      {message ? (
        <Message
          positive={message.messageType === 'positive'}
          negative={message.messageType === 'negative'}
          onDismiss={onDismissMessage}
        >
          <Message.Header>{messageTitle}</Message.Header>
          <p>{message.description}</p>
        </Message>
      ) : null}

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <CloudUpload color="primary" fontSize="large" />
        <span className="drag-drop__text">{title}</span>
        <span className="drag-drop__text">or</span>
        <Button
          variant="contained"
          color="primary"
          classes={{ containedPrimary: 'drag-drop__button' }}
          disabled={disabled}
        >
          Browse a file
        </Button>
      </div>
    </>
  );
};

export default DragDropFile;
