import React, { Component } from 'react';
import { Table, Popup, Button, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { QueryBuilderContext } from '../../components/QueryBuilder';

import './QueryResults.scss';
class QueryResults extends Component {
  static propTypes = {
    results: PropTypes.object,
    hasStaleResults: PropTypes.bool,
    addPropertyToManualPropertyList: PropTypes.func,
    setManuallyAddedProperties: PropTypes.func,
    getManuallyAddedProperties: PropTypes.func,
    setExcludedProperties: PropTypes.func,
    getExcludedProperties: PropTypes.func,
    removeExcludedPropertyFromList: PropTypes.func,
    runQueryBuilder: PropTypes.func,
  };

  static contextTypes = {
    emitter: PropTypes.object,
  };

  static defaultProps = {
    hasStaleResults: false,
    getExcludedProperties: () => [],
    getManuallyAddedProperties: () => [],
  };

  state = {
    hasOptionsDisplayed: [],
  };

  componentDidMount() {
    const { results } = this.props;

    const hasOptionsDisplayed = results.rows.map((result) => {
      return {
        [result]: false,
      };
    });

    this.setState({ hasOptionsDisplayed });
  }

  setTableReference = (node) => {
    this.setState({ node });
  };

  /***
   * excludeProperty
   *
   * Places a property at the end of the list of property results
   * based on it's propertyCode.
   *
   * @param {propertyCode}
   */

  // TODO make a call to rerun the query
  excludeProperty = (propertyId) => {
    const { setExcludedProperties } = this.props;
    if (propertyId) {
      const results = this.state.results || this.props.results;
      const propertyToExclude = results.rows.filter((r) => r.id === propertyId);

      if (propertyToExclude.length) {
        const updatedRows = results.rows.filter((r) => r.id !== propertyId).concat(propertyToExclude);

        results.rows = updatedRows;

        this.setState({ results });
      }

      setExcludedProperties(propertyId);
      this.toggleOptionsPopup(propertyId);
    }
  };

  includeProperty = async (propertyId, query) => {
    const c = window.confirm('Are you sure you want to remove from the excluded list?');
    if (c) {
      const { removeExcludedPropertyFromList, runQueryBuilder } = this.props;
      await removeExcludedPropertyFromList(propertyId);
      this.toggleOptionsPopup(propertyId);
      runQueryBuilder(query);
    } else {
      this.toggleOptionsPopup(propertyId);
    }
  };

  /***
   * moveToManualPropertiesList
   *
   * Removes a property from the list of property results
   * to be added to placed in the list of manually added
   * properties.
   *
   * Makes a call to addPropertyToManualPropertyList in order
   * to place the removed property to the manually added
   * properties table.
   *
   * @param {propertyCode}
   */
  // TODO make a call to rerun the query
  moveToManualPropertiesList = async (propertyId) => {
    const { addPropertyToManualPropertyList, setManuallyAddedProperties } = this.props;
    if (propertyId) {
      addPropertyToManualPropertyList && (await addPropertyToManualPropertyList(propertyId));

      setManuallyAddedProperties(propertyId);
      this.toggleOptionsPopup(propertyId);
    }
  };

  /***
   * toggleOptionsPopup
   *
   * Toggles the state of the hasOptionsDisplayed flag to true
   * in order to display the property options popup.
   */
  toggleOptionsPopup = (propertyId) => {
    const { hasOptionsDisplayed } = this.state;
    hasOptionsDisplayed[propertyId] = !hasOptionsDisplayed[propertyId];
    this.setState({ hasOptionsDisplayed });
  };

  isExcluded = (propertyId) => {
    const { getExcludedProperties } = this.props;
    return getExcludedProperties().indexOf(propertyId) >= 0;
  };

  isManuallyAdded = (propertyId) => {
    const { getManuallyAddedProperties } = this.props;
    return getManuallyAddedProperties().indexOf(propertyId) >= 0;
  };

  queryResultsWithManuallyAddedFiltered = () => {
    const { results } = this.props;
    return results.rows.filter((r) => !this.isManuallyAdded(r.id));
  };

  render() {
    const { hasOptionsDisplayed } = this.state;
    const { hasStaleResults, getExcludedProperties, results, runQueryBuilder } = this.props;

    return (
      <QueryBuilderContext.Consumer>
        {({ query }) => (
          <Table celled padded striped className={`query-results ${hasStaleResults ? 'query-results--modified' : ''}`}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Street Address</Table.HeaderCell>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.HeaderCell>Zip Code</Table.HeaderCell>
                <Table.HeaderCell>Property Code</Table.HeaderCell>
                <Table.HeaderCell>Edit</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body ref={this.setTableReference}>
              {this.queryResultsWithManuallyAddedFiltered().map((result) => {
                return (
                  <Table.Row key={result.id} data-testid="property-row">
                    <Table.Cell singleLine disabled={this.isExcluded(result.id)}>
                      {result.address_1}
                    </Table.Cell>
                    <Table.Cell singleLine disabled={this.isExcluded(result.id)}>
                      {result.city}
                    </Table.Cell>
                    <Table.Cell singleLine disabled={this.isExcluded(result.id)}>
                      {result.state}
                    </Table.Cell>
                    <Table.Cell singleLine disabled={this.isExcluded(result.id)}>
                      {result.zipcode}
                    </Table.Cell>
                    <Table.Cell singleLine disabled={this.isExcluded(result.id)}>
                      {result.property_code}
                    </Table.Cell>
                    <Table.Cell singleLine textAlign={'center'} disabled={false}>
                      <Popup
                        open={hasOptionsDisplayed[result.id]}
                        on={'click'}
                        position={'left center'}
                        basic={true}
                        wide={true}
                        onClose={(e) => {
                          e.preventDefault();
                          this.toggleOptionsPopup(result.id);
                        }}
                        trigger={
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.toggleOptionsPopup(result.id);
                            }}
                          >
                            ...
                          </span>
                        }
                        className={'query-results__popup'}
                      >
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            this.isExcluded(result.id)
                              ? this.includeProperty(result.id, query)
                              : this.excludeProperty(result.id);
                          }}
                        >
                          {this.isExcluded(result.id) ? 'Remove from excluded list' : 'Exclude from query'}
                        </div>
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            this.moveToManualPropertiesList(result.id);
                          }}
                        >
                          Move to manually added properties
                        </div>
                      </Popup>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              {hasStaleResults && (
                <div className={'query-results__stale-message-container'}>
                  <Grid textAlign={'center'}>
                    <Grid.Row>
                      <Grid.Column>
                        <p>Changes have been made to the query.</p>
                        <p>To see results, re-run the query.</p>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            runQueryBuilder && runQueryBuilder(query);
                          }}
                          color={'blue'}
                        >
                          Re-Run Query
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>{this.queryResultsWithManuallyAddedFiltered().length} Total Results</Table.HeaderCell>
                <Table.HeaderCell>{getExcludedProperties().length} Excluded Results</Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell>{results.timeToExecute} ms</Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </QueryBuilderContext.Consumer>
    );
  }
}

export default QueryResults;
