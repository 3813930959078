import React from 'react';
import Card from '../../components/Card';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import { findIndex } from 'lodash';
import { Route } from 'react-router-dom';
import { Loader, Tab } from 'semantic-ui-react';
import Users from '../../containers/administration/Users';
import Roles from '../../containers/administration/Roles';

class Administration extends React.Component {
  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;

    return !loading ? (
      <View>
        <ViewHeader title={'Administration'} />
        <ViewContent>
          <Card>
            <Route
              path="/administration/:tabSlug?"
              render={(route) => {
                const panes = [
                  {
                    menuItem: 'Roles',
                    tabSlug: 'roles',
                    render: () => (
                      <Tab.Pane>
                        <Roles />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Users',
                    tabSlug: 'users',
                    render: () => (
                      <Tab.Pane>
                        <Users />
                      </Tab.Pane>
                    ),
                  },
                ];
                const params = route.match.params;
                const activeIndex = params.tabSlug ? findIndex(panes, { tabSlug: params.tabSlug }) : 0;
                return (
                  <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, data) => {
                      route.history.push(`/administration/${data.panes[data.activeIndex].tabSlug}`);
                    }}
                    panes={panes}
                  />
                );
              }}
            />
          </Card>
        </ViewContent>
      </View>
    ) : (
      <Loader active={true} />
    );
  }
}

export default Administration;
