import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'utils/axios';

const Logout = () => {
  axios.clearLogin();
  return (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
};

export default Logout;
