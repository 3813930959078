import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Flex, Box } from 'grid-styled';
import { Loader } from 'semantic-ui-react';
import editIcon from '../../../icons/edit.svg';
import deleteIcon from '../../../icons/delete.svg';
import { displayMonthFromConcessionMonth, formatDate, API } from '../utils';
import { toSnakeCase } from 'utils/index';
import withEmitter from '../../../components/HoC/withEmitter';

class SpecialsList extends React.Component {
  static propTypes = {
    emitter: PropTypes.object.isRequired,
  };

  state = {
    specials: [],
    isLoading: true,
    noResults: false,
  };

  componentDidMount() {
    API.list().then((r) => {
      this.setState({
        isLoading: false,
        specials: r.data.results,
        noResults: r.data.results.length === 0,
      });
    });
  }

  deleteSpecial = (id, name) => {
    const { emitter } = this.props;
    const c = window.confirm(`Are you sure you want to delete "${name}?"`);
    if (c) {
      API.delete(id)
        .then(() => {
          emitter.emit('alert-queue:add', {
            type: 'success',
            message: 'Successfully deleted special',
          });
          const newSpecials = this.state.specials.filter((special) => special.id !== id);
          this.setState({
            specials: newSpecials,
            noResults: newSpecials.length === 0,
          });
        })
        .catch(() => {
          emitter.emit('alert-queue:add', {
            type: 'error',
            message: 'Something went wrong while deleting. Please try again.',
          });
        });
    }
  };

  renderNoSpecials = () => {
    return (
      <div className="rt-tr-group">
        <Box pt="2%" pb="2%" style={{ textAlign: 'center' }}>
          <h5>No results found</h5>
          <p>There are no items in this list.</p>
        </Box>
      </div>
    );
  };

  renderLoading = () => {
    return (
      <div className="rt-tr-group">
        <Box pt="2%" pb="2%" style={{ textAlign: 'center' }}>
          <Box p="2%">
            <Loader active={true} inline="centered" />
          </Box>
          <p>Please wait while we load the list.</p>
        </Box>
      </div>
    );
  };

  renderList = () => {
    const { specials } = this.state;
    return (
      (specials &&
        specials.length &&
        specials.map((special, i) => (
          <div className="rt-tr-group" key={special.id}>
            <div className={`rt-tr ${i % 2 ? '-even' : '-odd'}`}>
              <div className="rt-td" style={largeColumn}>
                {special.name}
              </div>
              <div className="rt-td" style={rowStyle}>
                {displayMonthFromConcessionMonth(special.concession_month)}
              </div>
              <div className="rt-td" style={rowStyle}>
                {formatDate(special.start_at)}
              </div>
              <div className="rt-td" style={rowStyle}>
                {formatDate(special.end_at)}
              </div>
              <div id={`edit_${toSnakeCase(special.name)}`} className="rt-td" style={rowStyle}>
                <Link to={`/leasing-specials/view/${special.id}`}>
                  <img alt="edit" className="cursor-pointer" src={editIcon} />
                </Link>
              </div>
              <div id={`delete_${toSnakeCase(special.name)}`} className="rt-td" style={rowStyle}>
                <img
                  alt="delete"
                  className="cursor-pointer"
                  src={deleteIcon}
                  onClick={() => {
                    this.deleteSpecial(special.id, special.name);
                  }}
                />
              </div>
            </div>
          </div>
        ))) ||
      null
    );
  };

  render() {
    const { isLoading, noResults } = this.state;

    return (
      <div>
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>All Active Leasing Specials</h2>
          </Box>
        </Flex>
        <Flex>
          <Box pl="2%" pb={20} width={1}>
            <div className="ReactTable -striped -highlight" style={{ maxWidth: '1200px' }}>
              <div className="rt-table">
                <div className="rt-thead -header" style={{ minWidth: '900px' }}>
                  <div className="rt-tr">
                    <div className="rt-th" style={largeColumn}>
                      <div>Name</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Concession Month</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Start Date</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>End Date</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Edit</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Remove</div>
                    </div>
                  </div>
                </div>
                <div className="rt-tbody" style={{ minWidth: '900px', overflow: 'visible' }}>
                  {isLoading ? this.renderLoading() : noResults ? this.renderNoSpecials() : this.renderList()}
                </div>
              </div>
            </div>
          </Box>
        </Flex>
        <Flex>
          <Box ml={'2%'} width={1}>
            <Flex>
              <Box ml={'auto'}>
                <Link to={`/leasing-specials/add`} className={`ui button primary`}>
                  Add New Special
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </div>
    );
  }
}

const rowStyle = { textAlign: 'center' };
const largeColumn = {
  minWidth: '300px',
  maxWidth: '300px',
  textAlign: 'center',
};

export default withEmitter(SpecialsList);
