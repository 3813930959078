import axios from 'utils/axios';

export default async function previewData() {
  const properties = await axios.get('/api/admin/properties?withPhotos=true').then((res) => res.data.results);
  const markets = await axios.get('/api/admin/markets').then((res) => res.data.results);
  return {
    base_url: '/',
    feed_url: '/example-feed-url',
    coming_soon_thumbnail: '/coming-soon.png',
    properties,
    markets,
  };
}
