import React from 'react';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import axios from 'utils/axios';
import { Flex, Box } from 'grid-styled';
import { Redirect, withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { scrollToTop, sortUsers } from '../../utils';
import _ from 'lodash';
import CustomInput from '../../components/CustomInput';

class AddEditRole extends React.Component {
  static propTypes = {
    editPage: PropTypes.bool,
    match: PropTypes.object,
    params: PropTypes.object,
  };

  state = {
    available: [],
    selected: [],
    formData: {},
  };

  componentDidMount() {
    const { editPage } = this.props;

    axios.get('/api/admin/roles/operations').then((r) => {
      this.setState({
        allOperations: r.data,
      });
    });

    axios.get('/api/admin/users').then((r) => {
      this.setState(
        {
          available: r.data,
        },
        () => {
          if (editPage) {
            const { roleId } = this.props.match.params;
            axios.get(`/api/admin/roles/${roleId}`).then((r) => {
              const data = r.data,
                permissions = this.transformPermissions(data.operations),
                available = _.differenceWith(this.state.available, data.users, _.isEqual);

              this.setState({
                isAdministrator: data.name === 'Administrators',
                roleName: data.name,
                selected: data.users,
                available: available,
                formData: {
                  name: data.name,
                  ...permissions,
                },
              });
            });
          }
        },
      );
    });
  }

  onSubmit = () => {
    if (!this.formIsValid() || !this.isUserSelected() || !this.isPermissionSelected()) {
      scrollToTop();
      return;
    }

    const { selected, formData, allOperations } = this.state,
      { name } = formData,
      selectedUsersIds = selected.map((i) => i.id),
      operationsFiltered = allOperations.filter(
        (operation) => Object.keys(formData).includes(operation.operation_code) && formData[operation.operation_code],
      );

    const payload = { name: name, users: selectedUsersIds };

    payload.operations = operationsFiltered.map((i) => i.id);

    if (this.props.editPage) {
      const { roleId } = this.props.match.params;
      axios.put(`/api/admin/roles/${roleId}`, payload).then(() => {
        this.setState({ successRedirect: true });
      });
    } else {
      axios
        .post('/api/admin/roles', payload)
        .then(() => {
          this.setState({ successRedirect: true });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            scrollToTop();
            this.setState({
              duplicateError: true,
            });
          }
        });
    }
  };

  onChange = (e, props) => {
    const target = e.target;
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [props.name]: props.type === 'checkbox' ? props.checked : target.value,
        },
      },
      () => {
        this.validateFields(target.name);
      },
    );
  };

  onSelectChange = (e) => {
    const target = e.target,
      options = target.options;

    const selected = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selected.push(+options[i].value);
      }
    }
    this.setState({
      [`${target.name}_active`]: selected,
    });
  };

  transformPermissions(permissions) {
    return permissions.reduce((accum, item) => {
      return {
        ...accum,
        [item.operation_code]: true,
      };
    }, {});
  }

  requiredFields = ['name'];

  validateFields = (fieldName) => {
    if (this.requiredFields.includes(fieldName)) {
      if (!this.state.formData[fieldName]) {
        this.setState({
          [`${fieldName}_error`]: true,
        });
      } else {
        this.setState({
          [`${fieldName}_error`]: false,
        });
      }
    }
  };

  addUsers = () => {
    const { available_active } = this.state;
    if (!available_active || !available_active.length) return;
    const available = [...this.state.available],
      selected = available.filter((i) => available_active.includes(i.id)),
      difference = _.difference(available, selected);

    this.setState({
      selected: [...this.state.selected, ...selected],
      available: difference,
    });
  };

  removeUsers = () => {
    const { selected_active } = this.state;
    if (!selected_active || !selected_active.length) return;
    const copySelected = [...this.state.selected],
      selected = copySelected.filter((i) => selected_active.includes(i.id)),
      difference = _.difference(copySelected, selected);

    this.setState({
      available: [...this.state.available, ...selected],
      selected: difference,
    });
  };

  renderOptions = (options) => {
    const sortedOptions = sortUsers(options);
    return Object.keys(sortedOptions).map((key) => {
      const option = sortedOptions[key];
      return (
        <option key={option.id} value={option.id}>
          {`${option.last_name}, ${option.first_name}`}
        </option>
      );
    });
  };

  formIsValid() {
    this.validateFields('name');
    return !!this.state.formData.name;
  }

  isUserSelected() {
    const userSelected = !!this.state.selected.length;
    this.setState({
      userError: !userSelected,
    });
    return userSelected;
  }

  isPermissionSelected() {
    const { formData } = this.state,
      permissionSelected = Object.keys(formData).some((key) => key.includes('CAN_') && formData[key]);

    this.setState({
      permissionError: !permissionSelected,
    });

    return permissionSelected;
  }

  renderOperationsCheckboxes() {
    const { allOperations } = this.state;
    if (allOperations && allOperations.length > 0) {
      // create 2-column layout of available operations by splitting operations into two arrays.
      const half = Math.ceil(allOperations.length / 2);
      const left = allOperations.slice(0, half);
      const right = allOperations.slice(half, allOperations.length);
      return (
        <Flex flexDirection="row" css="margin-top: 20px;">
          <Box width={[1, 1, 1 / 1.2]}>
            <Flex flexDirection="row">
              {[left, right].map((operations, index) => {
                return (
                  <Box width={[1, 1, 1 / 2]} key={index}>
                    {operations.map((operation) => {
                      return (
                        <Box mt={20} key={operation.id}>
                          <Checkbox
                            label={operation.operation_label}
                            name={operation.operation_code}
                            disabled={this.state.isAdministrator}
                            checked={this.state.formData[operation.operation_code]}
                            onChange={this.onChange}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Flex>
          </Box>
        </Flex>
      );
    }
  }

  render() {
    const { name_error, successRedirect, available, selected, userError, permissionError, duplicateError, roleName } =
      this.state;
    const { editPage } = this.props;
    const { name } = this.state.formData;

    if (successRedirect) {
      return <Redirect to="/administration/roles" />;
    }

    return (
      <Form onSubmit={this.onSubmit}>
        {userError && <Alert color="danger">Please select at least one user</Alert>}
        {permissionError && <Alert color="danger">Please select at least one permission</Alert>}
        {duplicateError && (
          <Alert color="danger">A Role with the same name already exists. Please provide a unique name.</Alert>
        )}
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>{editPage ? `Edit ${roleName}` : 'Add'} Role</h2>
          </Box>
        </Flex>
        <Flex flexDirection="column">
          <Box width={[1, 1, 1 / 4]} mb={20} pl="3%">
            <Box>
              <CustomInput
                withValidation
                validationError={name_error}
                label="Name"
                name="name"
                value={name}
                onChange={this.onChange}
              />
            </Box>
          </Box>

          <Box p={10} width={[1, 1, 1 / 1.5]} pl="3%">
            <h4>Role Members</h4>
            <span>
              Add users to this role by selecting them in Available Users list and clicking the Add Users button. You
              can also remove users from this role by selecting them in Selected Users list and clicking the Remove
              Users button.
            </span>
          </Box>

          <Flex>
            <Box pl="3%" mt={20} width={[1, 1, 1 / 2]}>
              <Flex flexDirection="row" justifyContent="space-between">
                <Box width={1 / 3}>
                  <label>
                    <strong>Available Users</strong>
                  </label>
                  <select multiple={true} name="available" size="10" onChange={this.onSelectChange}>
                    {this.renderOptions(available)}
                  </select>

                  <Box mt={10}>
                    <Button fluid type="button" className="ui primary button" onClick={this.addUsers}>
                      Add users
                    </Button>
                  </Box>
                </Box>

                <Box width={1 / 3}>
                  <label>
                    <strong>Selected Users</strong>
                  </label>
                  <select multiple={true} name="selected" size="10" onChange={this.onSelectChange}>
                    {this.renderOptions(selected)}
                  </select>

                  <Box mt={10}>
                    <Button type="button" negative fluid onClick={this.removeUsers}>
                      Remove users
                    </Button>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Flex>

          <Flex>
            <Box mt={40} width={[1, 1, 1 / 1.5]} pl="3%">
              <h4>Permissions</h4>
              {this.renderOperationsCheckboxes()}
            </Box>
          </Flex>

          <Box ml={'auto'}>
            <Button type="submit" primary>
              Save
            </Button>
          </Box>
        </Flex>
      </Form>
    );
  }
}

export default withRouter(AddEditRole);
