import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Card from '../../components/Card';
import Table from '../../components/Table';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import withTableResources from '../../containers/TableStateContainer';
import { withPermissions } from '../../components/HoC';

function FeedsList(props) {
  const { tableState, onFetchData } = props;

  useEffect(() => {
    onFetchData();
  }, []);

  return (
    <View>
      <ViewHeader title="Manage Feeds" subtitle="List view of all feeds:" />
      <ViewContent>
        <Card>
          {' '}
          <Table
            data={tableState.data}
            columns={tableState.columns}
            page={tableState.page}
            pages={tableState.pages}
            filtered={tableState.filtered}
            sorted={tableState.sorted}
            loading={tableState.loading}
            {...props}
          />
          <Link to="/feeds/create" className="ui button primary right floated segment">
            <i className="left plus icon" /> Create Feed
          </Link>
          <Link to="/feed-formats/create" className="ui button secondary right floated segment">
            <i className="left plus icon" /> Create Feed Format
          </Link>
          <Link to="/feed-formats" className="ui button secondary right floated segment">
            <i className="left list icon" /> Feed Formats
          </Link>
        </Card>
      </ViewContent>
    </View>
  );
}

FeedsList.propTypes = {
  tableState: PropTypes.object,
  permissions: PropTypes.object,
  onFetchData: PropTypes.func,
};

export default withPermissions(
  withTableResources(FeedsList, {
    endpoint: '/api/admin/feeds',
    canDelete: true,
    route: '/feed',
    name: 'delete_feed',
    headline: 'Delete Feed',
    description: 'Are you sure you want to delete this feed?  This action cannot be undone.',
  }),
);
