import axios from 'utils/axios';

export const getFeatureBlock = (featureId) => {
  return axios.get(`/api/admin/featured/${featureId}`);
};

/**
 * Gets a list of properties details by an array property ids.
 * @param {Array} propertyIds defaults to an empty array.
 * @returns {Promise}
 */
export const getPropertiesByIds = (propertyIds = []) => {
  return axios.get('/api/admin/properties/byIds', {
    params: {
      ids: propertyIds,
    },
  });
};

/***
 * previewFeatureBlock
 *
 * Get a list of properties to preview for a feature block
 *
 * @param {String} marketId
 * @returns {Promise}
 */
export const previewFeatureBlock = (marketId, payload) => {
  return axios.post(`/api/admin/featured/preview${marketId ? `?market_id=${marketId}` : ''}`, payload);
};
