import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

export default class Table extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    onFilteredChange: PropTypes.func.isRequired,
    onSortedChange: PropTypes.func.isRequired,
  };

  render() {
    const { data, columns, loading, onFilteredChange, onSortedChange, page, ...next } = this.props;

    const style = { height: 700, textAlign: 'center' };
    if (next.height) {
      style.height = next.height;
      delete next.height;
    }
    return (
      <ReactTable
        data={data}
        textAlign="center"
        columns={columns}
        className="-striped -highlight"
        filterable
        manual
        page={page}
        style={style}
        loading={loading}
        onFilteredChange={onFilteredChange}
        onSortedChange={onSortedChange}
        {...next}
      />
    );
  }
}
