import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children }) => (
  <div className="view-header">
    <header className="title text-white">{children}</header>
  </div>
);

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default function ViewHeader({ title, subtitle, children }) {
  if (children) {
    return <Header>{children}</Header>;
  }

  return (
    <Header>
      <h1 className="h4 text-uppercase">{title}</h1>
      <p className="mb-0">{subtitle}</p>
    </Header>
  );
}

ViewHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
