import React from 'react';
import TableHeader from './tableHeader';
import TableBody from './tableBody';

function Table(props) {
  const {
    columnData,
    order,
    orderBy,
    selectedOrders,
    validOrdersResponse,
    handleSelectAllClick,
    handleRequestSort,
    handleClick,
  } = props;

  const duplicatedRowCount = Object.values(validOrdersResponse ?? {}).reduce(
    (acc, resp) => acc + (resp.isDuplicated ? 1 : 0),
    0,
  );

  return (
    <div style={{ overflow: 'scroll' }}>
      <TableHeader
        numSelected={selectedOrders.length}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        rowCount={columnData.length}
        duplicatedRowCount={duplicatedRowCount}
      />
      <TableBody
        columnData={columnData}
        order={order}
        orderBy={orderBy}
        handleClick={handleClick}
        selectedOrders={selectedOrders}
        validOrdersResponse={validOrdersResponse}
      />
    </div>
  );
}

export default Table;
