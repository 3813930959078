const statusesArr = ['completed', 'finished', 'cancel_request', 'cancelled', 'hold'];

export const haveOrderInProgress = (orders) => {
  if (!orders || !orders.length) return false;

  return orders.some((item) => {
    return !statusesArr.includes(item.order_status);
  });
};

export const getFailedOrPendingOrders = (orders) => {
  return orders.filter((order) => order.order_status === 'failed' || order.order_status === 'pending');
};

export const getModalTextAndType = (inProgress, failedOrders, hasProPhotos) => {
  let modalText = 'Are you sure you want to order professional photos?';
  let type = 'success';

  if (inProgress) {
    if (failedOrders.length) {
      modalText = `This property has failed/pending photo order(s), (order id(s): ${failedOrders.map(
        (order) => order.order_id,
      )}). Are you sure you want to create a new photo order? Choosing (Yes) will mark these failed/pending orders as complete.`;
      type = 'reorder';
    } else {
      modalText =
        'There is already an open order for professional photos for this property. You cannot create a new order until the current order is complete.';
      type = 'fail';
    }
  } else if (hasProPhotos) {
    modalText =
      'There are already professional photos for this property. Are you sure you want to order new professional photos to be taken?';
  }

  return { modalText, type };
};
