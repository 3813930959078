import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

class InlineMessage extends Component {
  static propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
  };

  static defaultProps = {
    type: 'danger',
  };

  render() {
    const { type, message } = this.props;
    return <Alert color={type}>{message}</Alert>;
  }
}

export default InlineMessage;
