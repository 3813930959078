import React from 'react';
import PropTypes from 'prop-types';

export default function View({ className = '', children }) {
  return <div className={`view ${className}`}>{children}</div>;
}

View.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};
