import React from 'react';
import { Flex } from 'grid-styled';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import UsersList from '../../routes/Administration/UsersList';
import AddEditUser from '../../routes/Administration/AddEditUser';
import PropTypes from 'prop-types';
import { withPermissions } from '../../components/HoC';

class Users extends React.Component {
  static propTypes = {
    permissions: PropTypes.array,
  };

  render() {
    const {
      permissions: { canAdminMac },
    } = this.props;

    if (!canAdminMac) return <Redirect to="/properties" />;
    return (
      <Flex flexDirection="column">
        <Switch>
          <Route exact path={`/administration/users`} render={() => <UsersList />} />
          <Route exact path={`/administration/users/add`} render={() => <AddEditUser />} />
          <Route exact path={`/administration/users/:userId`} render={() => <AddEditUser editPage />} />
        </Switch>
      </Flex>
    );
  }
}

export default withPermissions(withRouter(Users));
