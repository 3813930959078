import React, { Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ClearButton = ({
  failed,
  submitted,
  data,
  clearSubmitted,
  processedData,
  importCSV,
  inputKey,
  handleSubmitOrders,
}) => {
  return (
    <>
      <Fragment>
        <div className="bulk-order-buttons">
          <input id="reimport_photo_orders_uploader" type="file" accept=".csv" onChange={importCSV} key={inputKey} />
          {data && processedData.data.length > 0 && (
            <Button
              id="submit-order"
              onClick={() => handleSubmitOrders(processedData.data)}
              className="ui primary button"
            >
              Submit Bulk Orders
            </Button>
          )}

          {data && processedData.data.length === 0 && <div>NO VALID ORDERS</div>}
          {(failed || submitted || data) && (
            <Button id="clear-submit" onClick={() => clearSubmitted()} className="ui primary button">
              Clear
            </Button>
          )}
        </div>
      </Fragment>
    </>
  );
};

export default ClearButton;

ClearButton.propTypes = {
  failed: PropTypes.object,
  submitted: PropTypes.object,
  data: PropTypes.object,
  clearSubmitted: PropTypes.func,
  processedData: PropTypes.object,
  importCSV: PropTypes.func,
  inputKey: PropTypes.string,
  handleSubmitOrders: PropTypes.func,
};
