import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { has } from 'lodash';

import Card from '../components/Card';
import Table from '../components/Table';
import View from '../components/View';
import ViewHeader from '../components/ViewHeader';
import ViewContent from '../components/ViewContent';

import DateRangeSelector from '../components/DateRangeSelector';
import { withPermissions } from '../components/HoC';
import OrderPhotosModal from '../components/order-photos-modal';
import withTableResources from '../containers/TableStateContainer';
import ExportToCVSButton from '../containers/ExportToCSVButton';

import axios from 'utils/axios';
import { MAX_REPORT_SIZE_LIMIT } from '../utils/constants';
class Properties extends Component {
  static propTypes = {
    // inherited from withRouter
    history: PropTypes.object,

    // inherited from withTableResources
    tableState: PropTypes.object,
    onFetchData: PropTypes.func,
    onFilteredChange: PropTypes.func,
    onSortedChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    onBeforeRequestDataSetup: PropTypes.func.isRequired,

    // inherited from withPermissions,
    permissions: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      selected: {},
      propertyId: '',
      filtered: props.tableState.filtered,
      error: false,
    };
  }

  async componentDidMount() {
    const { results } = await this.props.onFetchData();
    this.additionalDataSetup({ results });
  }

  onFilteredChange = async (filtered) => {
    const result = await this.props.onFilteredChange(filtered);
    this.additionalDataSetup(result);
  };

  onSortedChange = async (sorted) => {
    const result = await this.props.onSortedChange(sorted);
    this.additionalDataSetup(result);
  };

  onPageChange = async (page) => {
    const result = await this.props.onPageChange(page);
    this.additionalDataSetup(result);
  };

  onPageSizeChange = async (pageSize, page) => {
    const result = await this.props.onPageSizeChange(pageSize, page);
    this.additionalDataSetup(result);
  };

  onError = (errorMsg) => {
    this.setState({
      error: true,
      errorMsg: errorMsg,
    });
  };

  onDateRangeSubmit = async (date) => {
    const { onBeforeRequestDataSetup, onFetchData } = this.props;
    onBeforeRequestDataSetup({ ...date });
    const { results } = await onFetchData();
    this.additionalDataSetup({ results });
  };

  onDateRangeClear = async () => {
    const { onBeforeRequestDataSetup, onFetchData } = this.props;
    onBeforeRequestDataSetup({});
    const { results } = await onFetchData({ page: 0 });
    this.additionalDataSetup({ results });
  };

  renderError() {
    if (this.state.error) {
      return (
        <Message negative onDismiss={this.hideError} ref={(errorMessage) => (this.errorMessage = errorMessage)}>
          <p>{this.state.errorMsg}</p>
        </Message>
      );
    }

    if (this.errorMessage && this.errorMessage.focus) {
      this.errorMessage.focus();
    }

    return null;
  }

  hideError = () => {
    this.setState({
      error: false,
    });
  };

  date = null;

  additionalDataSetup = ({ results }) => {
    const selected = results.reduce((accum, item) => {
      return {
        ...accum,
        [item.id]: {
          allow_greatschools: item.allow_greatschools,
          pro_photos: item.has_all_pro_photos ? item.has_all_pro_photos : item.pro_photos_count > 1,
          poc: item.poc,
        },
      };
    }, {});

    this.setState({ selected });
  };

  toggle = (id, key) => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[id][key] = !this.state.selected[id][key];
    this.setState(
      {
        selected: newSelected,
      },
      () => {
        axios.put(`/api/admin/properties/${id}`, {
          [key]: this.state.selected[id][key],
        });
      },
    );
  };

  photosModalToggle = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    const { active, propertyId, selected } = this.state;

    const {
      tableState,
      permissions: { canEditPropertyDetails, canManagePropertyPhotos },
    } = this.props;

    const hasProPhotos = propertyId && selected[propertyId] ? selected[propertyId].pro_photos : false;

    const propertyOrderContacts = propertyId && selected[propertyId] ? selected[propertyId].poc : [];

    return (
      <View>
        <ViewHeader title="Home Marketing" subtitle="List view of all properties:" />
        <ViewContent>
          <Card>
            {this.renderError()}
            <OrderPhotosModal
              active={active}
              toggleModal={this.photosModalToggle}
              propertyId={propertyId}
              hasProPhotos={hasProPhotos}
              poc={propertyOrderContacts}
              specialInstructions={this.state.specialInstructions}
            />
            <DateRangeSelector
              onError={this.onError}
              onSubmit={this.onDateRangeSubmit}
              onClear={this.onDateRangeClear}
              onErrorHide={this.hideError}
              filterLabel="Available Date"
              keyName="available"
            />
            <Table
              data={tableState.data}
              columns={tableState.columns}
              page={tableState.page}
              pages={tableState.pages}
              filtered={tableState.filtered}
              sorted={tableState.sorted}
              loading={tableState.loading}
              onFilteredChange={this.onFilteredChange}
              onSortedChange={this.onSortedChange}
              onPageChange={this.onPageChange}
              onPageSizeChange={this.onPageSizeChange}
              getTdProps={(state, rowInfo, column) => {
                if (column.id === 'previous_value' || column.id === 'new_value') {
                  column.Cell = () => <span>{rowInfo.value ? rowInfo.value : 'N/A'}</span>;
                  return {};
                } else if (column.id === 'address_1') {
                  column.Cell = () => (
                    <Link id={rowInfo.original.slug} to={`/property/${rowInfo.original.slug}`}>
                      {rowInfo.original.address_1}
                    </Link>
                  );
                  return {};
                } else if (column.id === 'allow_greatschools') {
                  column.Cell = () =>
                    this.state.selected[rowInfo.original.id] ? (
                      <input
                        type="checkbox"
                        onChange={() => this.toggle(rowInfo.original.id, 'allow_greatschools')}
                        disabled={!canEditPropertyDetails}
                        checked={this.state.selected[rowInfo.original.id]['allow_greatschools']}
                      />
                    ) : null;
                  return {};
                } else if (column.id === 'has_all_pro_photos') {
                  column.Cell = () =>
                    this.state.selected[rowInfo.original.id] ? (
                      <span>
                        <input
                          type="checkbox"
                          disabled={!canManagePropertyPhotos}
                          onChange={() => this.toggle(rowInfo.original.id, 'pro_photos')}
                          checked={this.state.selected[rowInfo.original.id]['pro_photos']}
                        />
                        <button
                          type="button"
                          className="ui primary button ms-4"
                          disabled={!canManagePropertyPhotos}
                          onClick={() => {
                            this.setState(
                              {
                                propertyId: rowInfo.original.id,
                                specialInstructions: rowInfo.original.photo_special_instructions,
                              },
                              () => {
                                this.photosModalToggle();
                              },
                            );
                          }}
                        >
                          Order Photos
                        </button>
                      </span>
                    ) : (
                      <span>&nbsp;</span>
                    );
                  return {};
                } else if (column.id !== 'allow_greatschools' && column.id !== 'has_all_pro_photos') {
                  return {
                    style: {
                      cursor: 'pointer',
                    },
                    onClick: () => {
                      has(rowInfo, 'original') && this.props.history.push(`/property/${rowInfo.original.slug}`);
                    },
                  };
                } else {
                  return {};
                }
              }}
            />
            {ExportToCVSButton({
              state: tableState,
              maxReportSizeLimit: MAX_REPORT_SIZE_LIMIT,
              endpoint: '/api/admin/properties',
              reportName: 'properties',
            })}
          </Card>
        </ViewContent>
      </View>
    );
  }
}

export default withPermissions(
  withTableResources(withRouter(Properties), {
    endpoint: '/api/admin/properties',
  }),
);
