import React, { useState, useEffect } from 'react';
import QueryBuilder, { QueryBuilderContext } from 'components/QueryBuilder';
import { buildQueryConfig } from '../../utils/feeds';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';

export default function FeedsQuery({ query = {} }) {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    buildQueryConfig().then((cfg) =>
      setConfig({
        criteria: {
          ...cfg.criteria,
          criteria_defs: sortBy(cfg.criteria.criteria_defs, ['name']),
        },
        ordering: {
          ...cfg.ordering,
          criteria: sortBy(cfg.ordering.criteria, ['label']),
        },
      }),
    );
  }, []);

  return (
    <QueryBuilderContext.Provider
      value={{
        query: query.query,
        isQueryValid: query.isQueryValid,
        updateQueryState: () => {},
        updateFeatureBlockState: () => {},
      }}
    >
      {config ? (
        <QueryBuilder
          query={query.query}
          queryConfig={config}
          addPropertyToManualPropertyList={() => {}}
          updateQueryState={() => {}}
        />
      ) : null}
    </QueryBuilderContext.Provider>
  );
}
FeedsQuery.propTypes = {
  query: PropTypes.object,
  onChange: PropTypes.func,
};
