import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { Flex, Box } from 'grid-styled';
import SyndicationStatusChangeModal from './SyndicationStatusChangeModal';
import SyndicationStatusNote from './SyndicationStatusNote';
import { Radio, TextField, Select, MenuItem, InputLabel, FormLabel } from '@material-ui/core';
import { withPermissions } from '../../components/HoC';
import { useFormContext, Controller } from 'react-hook-form';
import { translateBooleanToYesNo } from 'utils/syndication';
const Syndication = ({ transformNullValues, classes, permissions: { canManagePropertySyndication } }) => {
  const [syndicationStatusNoteModalActive, setSyndicationStatusNoteModalActive] = useState(false);

  const { watch, control, setValue, register } = useFormContext({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const options = [
    { text: 'Auto', value: 'Auto' },
    { text: 'Always', value: 'Always' },
    { text: 'Never', value: 'Never' },
  ];

  const values = watch();

  const toggleModal = () => setSyndicationStatusNoteModalActive(!syndicationStatusNoteModalActive);

  return values.loading ? (
    <Loader active={values.loading} />
  ) : (
    <Box>
      <SyndicationStatusChangeModal
        active={syndicationStatusNoteModalActive}
        toggleModal={toggleModal}
        syndicationStatus={values.syndication_status}
      />

      <h2>Syndication</h2>
      <Flex flexDirection="column" css="margin-top: 20px;">
        <Box>
          <label>
            <strong>Allow syndication status</strong>
            <div>{values.syndication_status}</div>
          </label>
        </Box>
      </Flex>
      <Box mt={20}>
        <strong>Is Currently Syndicated on InvitationHomes.com</strong>
        <div>{translateBooleanToYesNo(values.is_syndicated)}</div>
      </Box>
      <Box mt={20}>
        <strong>Is Currently Syndicating on 3rd-party ILS</strong>
        <div>{translateBooleanToYesNo(values.is_syndicated)}</div>
      </Box>
      <FormLabel className={classes.label}>List on Company and 3rd-party websites?</FormLabel>
      <Flex>
        <Box>
          <Controller
            control={control}
            name={'syndication_status'}
            render={({ field: { onBlur, name, value, onChange } }) => (
              <Select
                className={classes.select}
                variant="outlined"
                name={name}
                disabled={!canManagePropertySyndication}
                onChange={(e) => {
                  onChange(e);
                  e.target.value === 'Never' && toggleModal();
                }}
                value={value}
              >
                {options.map((option) => {
                  return (
                    <MenuItem key={value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
        </Box>
      </Flex>
      <SyndicationStatusNote
        syndicationStatus={values.syndication_status}
        syndicationStatusNote={values.syndication_status_note}
        syndicationStatusPreviousEntry={values.syndicationStatusPreviousEntry && values.syndicationStatusPreviousEntry}
      />
      <Box className={classes.column}>
        <InputLabel className={classes.label}>Pre-marketing days on InvitationHomes.com</InputLabel>

        <Box>
          <TextField
            size="small"
            variant="outlined"
            {...register('syndication_days')}
            disabled={!canManagePropertySyndication}
            placeholder="Pre-marketing days"
            value={transformNullValues(values.syndication_days)}
            data-testid="syndication_days"
          />
        </Box>
      </Box>
      <Box className={classes.divider}>
        <InputLabel className={classes.label}>Syndicate on Facebook</InputLabel>
        <Radio
          label="Yes"
          disabled={!canManagePropertySyndication}
          name="allow_syndication_facebook"
          checked={values.allow_syndication_facebook}
          onChange={() => {
            setValue('allow_syndication_facebook', true);
          }}
        />
        <FormLabel className={classes.formLabel}>Yes</FormLabel>
        <Radio
          disabled={!canManagePropertySyndication}
          name="allow_syndication_facebook"
          checked={!values.allow_syndication_facebook}
          onChange={() => setValue('allow_syndication_facebook', false)}
        />
        <FormLabel className={classes.formLabel}>No</FormLabel>
      </Box>

      {values.allow_syndication_facebook && (
        <Box className={classes.column}>
          <InputLabel className={classes.label}>Pre-marketing days on Facebook</InputLabel>

          <Box>
            <TextField
              size="small"
              variant="outlined"
              {...register('syndication_days_facebook')}
              disabled={!canManagePropertySyndication}
              placeholder="Pre-marketing days"
              value={transformNullValues(values.syndication_days_facebook)}
              data-testid="syndication_days_facebook"
            />
          </Box>
        </Box>
      )}
      <Box className={classes.divider}>
        <InputLabel className={classes.label}>Syndicate on Realtor</InputLabel>

        <Box>
          <Radio
            label="Yes"
            disabled={!canManagePropertySyndication}
            name="allow_syndication_realtor"
            checked={values.allow_syndication_realtor}
            onChange={() => setValue('allow_syndication_realtor', true)}
          />
          <FormLabel className={classes.formLabel}>Yes</FormLabel>
          <Radio
            disabled={!canManagePropertySyndication}
            name="allow_syndication_realtor"
            checked={!values.allow_syndication_realtor}
            onChange={() => setValue('allow_syndication_realtor', false)}
          />
          <FormLabel className={classes.formLabel}>No</FormLabel>
        </Box>
      </Box>
      {values.allow_syndication_realtor && (
        <Box className={classes.column}>
          <InputLabel className={classes.label}>Pre-marketing days on Realtor.com</InputLabel>

          <Box>
            <TextField
              size="small"
              variant="outlined"
              {...register('syndication_days_realtor')}
              disabled={!canManagePropertySyndication}
              placeholder="Pre-marketing days"
              value={transformNullValues(values.syndication_days_realtor)}
              data-testid="syndication_days_realtor"
            />
          </Box>
        </Box>
      )}
      <Box className={classes.divider}>
        <InputLabel className={classes.label}>Syndicate on Zillow.com</InputLabel>

        <Box>
          <Radio
            label="Yes"
            disabled={!canManagePropertySyndication}
            name="allow_syndication_zillow"
            checked={values.allow_syndication_zillow}
            onChange={() => setValue('allow_syndication_zillow', true)}
          />
          <FormLabel className={classes.formLabel}>Yes</FormLabel>
          <Radio
            disabled={!canManagePropertySyndication}
            name="allow_syndication_zillow"
            checked={!values.allow_syndication_zillow}
            onChange={() => setValue('allow_syndication_zillow', false)}
          />
          <FormLabel className={classes.formLabel}>No</FormLabel>
        </Box>
      </Box>

      {values.allow_syndication_zillow && (
        <Box className={classes.column}>
          <InputLabel className={classes.label}>Pre-marketing days on Zillow.com</InputLabel>

          <Box>
            <TextField
              size="small"
              variant="outlined"
              {...register('syndication_days_zillow')}
              disabled={!canManagePropertySyndication}
              placeholder="Pre-marketing days"
              value={transformNullValues(values.syndication_days_zillow)}
              data-testid="syndication_days_zillow"
            />
          </Box>
        </Box>
      )}
      <Box className={classes.divider}>
        <InputLabel className={classes.label}>Syndicate on Rentals.com</InputLabel>

        <Box>
          <Radio
            label="Yes"
            disabled={!canManagePropertySyndication}
            name="allow_syndication_rentals"
            checked={values.allow_syndication_rentals}
            onChange={() => setValue('allow_syndication_rentals', true)}
          />
          <FormLabel className={classes.formLabel}>Yes</FormLabel>
          <Radio
            disabled={!canManagePropertySyndication}
            name="allow_syndication_rentals"
            checked={!values.allow_syndication_rentals}
            onChange={() => setValue('allow_syndication_rentals', false)}
          />
          <FormLabel className={classes.formLabel}>No</FormLabel>
        </Box>
      </Box>

      {values.allow_syndication_rentals && (
        <Box className={classes.column}>
          <InputLabel className={classes.label}>Pre-marketing days on Rentals.com</InputLabel>

          <Box>
            <TextField
              size="small"
              variant="outlined"
              {...register('syndication_days_rentals')}
              disabled={!canManagePropertySyndication}
              placeholder="Pre-marketing days"
              value={values.syndication_days_rentals}
              data-testid="syndication_days_rentals"
            />
          </Box>
        </Box>
      )}

      <Box className={classes.divider}>
        <InputLabel className={classes.label}>Syndicate on Zumper.com</InputLabel>

        <Box>
          <Radio
            label="Yes"
            disabled={!canManagePropertySyndication}
            name="allow_syndication_zumper"
            checked={values.allow_syndication_zumper}
            onChange={() => setValue('allow_syndication_zumper', true)}
          />
          <FormLabel className={classes.formLabel}>Yes</FormLabel>
          <Radio
            disabled={!canManagePropertySyndication}
            name="allow_syndication_zumper"
            checked={!values.allow_syndication_zumper}
            onChange={() => setValue('allow_syndication_zumper', false)}
          />
          <FormLabel className={classes.formLabel}>No</FormLabel>
        </Box>
      </Box>
      {values.allow_syndication_zumper && (
        <Box className={classes.column}>
          <InputLabel className={classes.label}>Pre-marketing days on Zumper.com</InputLabel>

          <Box>
            <TextField
              size="small"
              variant="outlined"
              {...register('syndication_days_zumper')}
              disabled={!canManagePropertySyndication}
              placeholder="Pre-marketing days"
              value={transformNullValues(values.syndication_days_zumper)}
              data-testid="syndication_days_zumper"
            />
          </Box>
        </Box>
      )}

      <Box className={classes.divider}>
        <InputLabel className={classes.label}>Syndicate on ApartmentList.com</InputLabel>

        <Box>
          <Radio
            label="Yes"
            disabled={!canManagePropertySyndication}
            name="allow_syndication_apartmentlist"
            checked={values.allow_syndication_apartmentlist}
            onChange={() => setValue('allow_syndication_apartmentlist', true)}
          />
          <FormLabel className={classes.formLabel}>Yes</FormLabel>
          <Radio
            disabled={!canManagePropertySyndication}
            name="allow_syndication_apartmentlist"
            checked={!values.allow_syndication_apartmentlist}
            onChange={() => setValue('allow_syndication_apartmentlist', false)}
          />
          <FormLabel className={classes.formLabel}>No</FormLabel>
        </Box>
      </Box>
      {values.allow_syndication_apartmentlist && (
        <Box className={classes.column}>
          <InputLabel className={classes.label}>Pre-marketing days on ApartmentList.com</InputLabel>

          <Box>
            <TextField
              size="small"
              variant="outlined"
              {...register('syndication_days_apartmentlist')}
              disabled={!canManagePropertySyndication}
              placeholder="Pre-marketing days"
              value={transformNullValues(values.syndication_days_apartmentlist)}
              data-testid="syndication_days_apartmentlist"
            />
          </Box>
        </Box>
      )}

      <Box className={classes.divider}>
        <InputLabel className={classes.label}>Syndicate on Apartments.com</InputLabel>

        <Box>
          <Radio
            label="Yes"
            disabled={!canManagePropertySyndication}
            name="allow_syndication_apartments"
            checked={values.allow_syndication_apartments}
            onChange={() => setValue('allow_syndication_apartments', true)}
          />
          <FormLabel className={classes.formLabel}>Yes</FormLabel>
          <Radio
            disabled={!canManagePropertySyndication}
            name="allow_syndication_apartments"
            checked={!values.allow_syndication_apartments}
            onChange={() => setValue('allow_syndication_apartments', false)}
          />
          <FormLabel className={classes.formLabel}>No</FormLabel>
        </Box>
      </Box>
      {values.allow_syndication_apartments && (
        <Box className={classes.column}>
          <InputLabel className={classes.label}>Pre-marketing days on Apartments.com</InputLabel>

          <Box>
            <TextField
              size="small"
              variant="outlined"
              {...register('syndication_days_apartments')}
              disabled={!canManagePropertySyndication}
              placeholder="Pre-marketing days"
              value={transformNullValues(values.syndication_days_apartments)}
              data-testid="syndication_days_apartments"
            />
          </Box>
        </Box>
      )}
      <Box>
        <InputLabel className={classes.label}>Boost on Apartments.com</InputLabel>
        <Box>
          <Radio
            label="Yes"
            disabled={!canManagePropertySyndication}
            name="boost_apartments"
            checked={values.boost_apartments}
            onChange={() => setValue('boost_apartments', true)}
          />
          <FormLabel className={classes.formLabel}>Yes</FormLabel>
          <Radio
            disabled={!canManagePropertySyndication}
            name="boost_apartments"
            checked={!values.boost_apartments}
            onChange={() => setValue('boost_apartments', false)}
          />
          <FormLabel className={classes.formLabel}>No</FormLabel>
        </Box>
      </Box>
    </Box>
  );
};

Syndication.propTypes = {
  permissions: PropTypes.object,
  classes: PropTypes.object,
  transformNullValues: PropTypes.func,
};

export default withPermissions(Syndication);
