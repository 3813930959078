import React, { PureComponent } from 'react';
import { Flex } from 'grid-styled';
import { Header, Dropdown, Button, Icon, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import QueryBuilderHelper from 'utils/queryBuilderHelper';

import './SortCondition.scss';
class SortCondition extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    queryConfig: PropTypes.object,
    parent: PropTypes.object,
    id: PropTypes.string,
    removeQueryItem: PropTypes.func,
    updateQueryCondition: PropTypes.func,
  };

  static defaultProps = {
    item: {
      header: 'Order By',
      field: 'city',
      direction: 'a-z',
    },
  };

  onChangeHandler = ({ value, type }) => {
    const { id, parent, updateQueryCondition, item } = this.props;
    if (type === 'CONDITION_COLUMN') {
      item.field = value;
    } else {
      item.direction = value;
    }
    updateQueryCondition({ id, parent, newValue: item });
  };

  render() {
    const { item, queryConfig, parent, id, removeQueryItem } = this.props;

    const helperInstance = new QueryBuilderHelper(queryConfig);
    const mappedOrderTypes = helperInstance.getMappedOrderTypes();
    const sortConditions = helperInstance.getOrderOperatorByOrderType(item.field);

    return (
      <div className={'sort-condition'}>
        <Flex>
          <Header size={'tiny'}>{item.header}</Header>
          <Menu className={'sort-condition__column'}>
            <Dropdown
              onChange={(e, data) =>
                this.onChangeHandler({
                  value: data.value,
                  type: 'CONDITION_COLUMN',
                })
              }
              value={item.field}
              options={mappedOrderTypes}
              item={true}
              basic={true}
            />
          </Menu>
          <Menu className={'sort-condition__order'}>
            <Dropdown
              onChange={(e, data) =>
                this.onChangeHandler({
                  value: data.value,
                  type: 'CONDITION_ORDER',
                })
              }
              value={item.direction}
              options={sortConditions}
              item={true}
              basic={true}
            />
          </Menu>
          <Button
            onClick={(e) => {
              e.preventDefault();
              removeQueryItem({ parent, id });
            }}
            icon
            color={'red'}
            className={'sort-condition__remove'}
          >
            <Icon name={'minus circle'} />
          </Button>
        </Flex>
      </div>
    );
  }
}

export default SortCondition;
