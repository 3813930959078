import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getBorderColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return 'rgb(102, 102, 102)';
};

const PhotoDropzoneContainer = styled.div`
  align-items: center;
  border-color: ${(props) => getBorderColor(props)};
  border-radius: 2px;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  width: 100%;
  transition: border 0.24s ease-in-out;
`;

function PhotoDropzone(props) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: props.onDrop,
  });

  return (
    <PhotoDropzoneContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <p>Drag a file here to upload, or click this area.</p>
      <br />
      <p>Only images under 30mb will be accepted.</p>
      <br />
      <br />
      <p className="p-margin">
        Large files need to be uploaded within 30 seconds. At least 5mbps upload speed is recommended to be successfully
        uploaded within 30 seconds.
      </p>
    </PhotoDropzoneContainer>
  );
}

export default PhotoDropzone;
