import React, { useMemo } from 'react';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './md-style';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

export default function DragDrop(props) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    disabled: false,
    onDrop: props.onDrop,
    maxFiles: props.maxFiles,
    multiple: props.maxFiles > 1,
    accept: { [props.accept]: [] },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <CloudUpload color="primary" fontSize="large" />
      <span className="drag-drop__text">{props.title}</span>
      <span className="drag-drop__text">or</span>
      <Button variant="contained" color="primary" classes={{ containedPrimary: 'drag-drop__button' }}>
        Browse a file
      </Button>
    </div>
  );
}
