import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import axios from 'utils/axios';
import { Form, Button, Message } from 'semantic-ui-react';
import { Flex, Box } from 'grid-styled';
import { scrollToTop, trimValue } from 'utils';
import ValidationRequired from '../../components/validation/ValidationRequired';
import CustomInput from '../../components/CustomInput';
import { withPermissions } from '../../components/HoC';

class Syndication extends Component {
  static propTypes = {
    marketData: PropTypes.object,
    onUpdateMarketData: PropTypes.func,
    permissions: PropTypes.object,
  };

  state = {
    marketData: {},
  };

  componentDidMount() {
    this.setState({
      marketData: this.props.marketData,
    });
  }

  onCatchError(err) {
    if (err && err.response && err.response.status === 401) {
      axios.clearLogin();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  onChange = (e, props) => {
    const target = e.target;

    this.setState(
      {
        marketData: {
          ...this.state.marketData,
          [props.name]: target.value,
        },
      },
      () => {
        this.isFieldValid(target.name);
      },
    );
  };

  onSave = () => {
    if (!this.areAllFieldsValid()) {
      return;
    }

    this.setState({
      loading: true,
      saved: false,
      error: false,
    });

    axios
      .put(`/api/admin/markets/${this.state.marketData.id}`, this.getPutData())
      .then(() => {
        scrollToTop();
        this.setState({
          loading: false,
          saved: true,
        });
        this.props.onUpdateMarketData();
      })
      .catch((err) => this.onCatchError(err));
  };

  getPutData() {
    return {
      syndication_days_facebook: trimValue(this.state.marketData.syndication_days_facebook),
      syndication_days_realtor: trimValue(this.state.marketData.syndication_days_realtor),
      syndication_days_zillow: trimValue(this.state.marketData.syndication_days_zillow),
      syndication_days_rentals: trimValue(this.state.marketData.syndication_days_rentals),
      syndication_days_zumper: trimValue(this.state.marketData.syndication_days_zumper),
      syndication_days_apartmentlist: trimValue(this.state.marketData.syndication_days_apartmentlist),
      syndication_days_apartments: trimValue(this.state.marketData.syndication_days_apartments),
      syndication_days: trimValue(this.state.marketData.syndication_days),
    };
  }

  areAllFieldsValid() {
    return this.requiredFields.every((field) => {
      return this.isFieldValid(field);
    });
  }

  requiredFields = [
    'syndication_days',
    'syndication_days_facebook',
    'syndication_days_realtor',
    'syndication_days_zillow',
    'syndication_days_rentals',
    'syndication_days_zumper',
    'syndication_days_apartmentlist',
    'syndication_days_apartments',
  ];

  renderMessage() {
    if (this.state.error) {
      return (
        <Message
          negative
          onDismiss={() => {
            this.setState({ error: false });
          }}
        >
          <Message.Header>Error</Message.Header>
          <p>An error has occurred while saving the property.</p>
        </Message>
      );
    } else if (this.state.saved) {
      return (
        <Message
          positive
          onDismiss={() => {
            this.setState({ saved: false });
          }}
        >
          <Message.Header>Success</Message.Header>
          <p>Your changes have been saved.</p>
        </Message>
      );
    }

    return null;
  }

  isFieldValid = (fieldName) => {
    if (this.requiredFields.includes(fieldName) && this.isEmpty(this.state.marketData[fieldName])) {
      this.setState({
        marketData: {
          ...this.state.marketData,
          [`${fieldName}_error`]: true,
        },
      });
      return false;
    }

    this.setState({
      marketData: {
        ...this.state.marketData,
        [`${fieldName}_error`]: false,
      },
    });
    return true;
  };

  isEmpty(input) {
    const trimmed = trimValue(input);
    return isNil(trimmed) || trimmed.length === 0;
  }

  render() {
    const {
      syndication_days,
      syndication_days_facebook,
      syndication_days_realtor,
      syndication_days_zillow,
      syndication_days_rentals,
      syndication_days_zumper,
      syndication_days_apartmentlist,
      syndication_days_apartments,
      syndication_days_error,
      syndication_days_facebook_error,
      syndication_days_realtor_error,
      syndication_days_zillow_error,
      syndication_days_rentals_error,
      syndication_days_zumper_error,
      syndication_days_apartmentlist_error,
      syndication_days_apartments_error,
    } = this.state.marketData;

    const {
      permissions: { canEditMarketDetails },
    } = this.props;

    return (
      <Form onSubmit={this.onSave}>
        {this.renderMessage()}
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>Syndication</h2>
          </Box>
        </Flex>

        <Flex flexDirection="column">
          <Box pl="2%">
            <Box mb={20} width={[1, 1, 1 / 3]}>
              <CustomInput
                withValidation
                validationError={syndication_days_error}
                label="Pre-marketing days on InvitationHomes.com"
                name="syndication_days"
                value={syndication_days}
                onChange={this.onChange}
                disabled={!canEditMarketDetails}
              />
            </Box>
            <Box mb={20} width={[1, 1, 1 / 3]}>
              <CustomInput
                withValidation
                validationError={syndication_days_facebook_error}
                label="Pre-marketing days on Facebook"
                name="syndication_days_facebook"
                value={syndication_days_facebook}
                onChange={this.onChange}
                disabled={!canEditMarketDetails}
              />
            </Box>
            <Box width={[1, 1, 1 / 3]} mb={20}>
              <CustomInput
                withValidation
                validationError={syndication_days_realtor_error}
                label="Pre-marketing days on Realtor.com"
                name="syndication_days_realtor"
                value={syndication_days_realtor}
                onChange={this.onChange}
                disabled={!canEditMarketDetails}
              />
            </Box>
            <Box width={[1, 1, 1 / 3]} mb={20}>
              <CustomInput
                withValidation
                validationError={syndication_days_zillow_error}
                label="Pre-marketing days on Zillow.com"
                name="syndication_days_zillow"
                value={syndication_days_zillow}
                onChange={this.onChange}
                disabled={!canEditMarketDetails}
              />
            </Box>

            <Box width={[1, 1, 1 / 3]} mb={20}>
              <CustomInput
                withValidation
                validationError={syndication_days_rentals_error}
                label="Pre-marketing days on Rentals.com"
                name="syndication_days_rentals"
                value={syndication_days_rentals}
                onChange={this.onChange}
                disabled={!canEditMarketDetails}
              />
            </Box>

            <Box width={[1, 1, 1 / 3]} mb={20}>
              <CustomInput
                withValidation
                validationError={syndication_days_zumper_error}
                label="Pre-marketing days on Zumper.com"
                name="syndication_days_zumper"
                value={syndication_days_zumper}
                onChange={this.onChange}
                disabled={!canEditMarketDetails}
              />
            </Box>

            <Box width={[1, 1, 1 / 3]} mb={20}>
              <CustomInput
                withValidation
                validationError={syndication_days_apartmentlist_error}
                label="Pre-marketing days on ApartmentList.com"
                name="syndication_days_apartmentlist"
                value={syndication_days_apartmentlist}
                onChange={this.onChange}
                disabled={!canEditMarketDetails}
              />
            </Box>

            <Box width={[1, 1, 1 / 3]} mb={20}>
              <CustomInput
                withValidation
                validationError={syndication_days_apartments_error}
                label="Pre-marketing days on Apartments.com"
                name="syndication_days_apartments"
                value={syndication_days_apartments}
                onChange={this.onChange}
                disabled={!canEditMarketDetails}
              />
            </Box>
            <Box mt={20} ml={10}>
              <ValidationRequired /> - Indicates required field
            </Box>
          </Box>
        </Flex>

        {canEditMarketDetails && (
          <Flex justifyContent="flex-end" css="margin-bottom: 40px; margin-top: 20px; margin-right: 10%;">
            <Box>
              <Button type="submit" primary>
                Save
              </Button>
            </Box>
          </Flex>
        )}
      </Form>
    );
  }
}

export default withPermissions(Syndication);
