import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'utils/axios';

import Card from 'components/Card';
import View from 'components/View';
import ViewHeader from 'components/ViewHeader';
import ViewContent from 'components/ViewContent';
import FeedsForm from 'containers/Feeds/Form';

export default function FeedEdit() {
  const { id } = useParams();

  const [subTitle, setSubTitle] = useState('');

  function onSubmit(values) {
    return axios
      .put(`/api/admin/feeds/${id}`, values, { headers: { 'Content-type': 'multipart/form-data' } })
      .then((response) => {
        if (response.status === 200) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return response.data;
        } else {
          throw new Error('An unexpected error was encountered when saving this feed');
        }
      });
  }

  return (
    <View>
      <ViewHeader title="Manage Feed" subtitle={`Editing: "${subTitle}"`} />
      <ViewContent>
        <Card>
          <FeedsForm id={id} onSubmit={onSubmit} setSubTitle={setSubTitle} />
        </Card>
      </ViewContent>
    </View>
  );
}
