import moment from 'moment';

const status = (status, availableDate) => {
  availableDate = moment(availableDate).format('MMM D, YYYY');
  switch (status) {
    case 'Notice Unrented':
      return 'This Invitation Home is being enjoyed by another resident, but it will be available soon. You can still apply – contact us for more details or apply now.';
    case 'Vacant Unrented Not Ready':
      return 'This Invitation Home is currently being renovated, but it will be available soon. You can still apply – contact us for more details or apply now.';
    default:
      return null;
  }
};

const statusModule = {
  status,
};

export default statusModule;
