import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import axios from 'utils/axios';
import App from './App';

// import Register from "./views/pages/register";
import Page404 from './views/pages/404';
import SignIn from './views/pages/signin';
import ForgetPass from './views/pages/forget';

import Properties from './routes/Properties';
import PropertyEdit from './routes/PropertyEdit';
import MarketEdit from './routes/MarketEdit';
import Administration from './routes/Administration/Administration';
import LeasingSpecials from './routes/Specials';
import Logout from './routes/Logout';
// import Offices from "./routes/Offices";
import Markets from './routes/Markets';
import Reports from './routes/Reports/Reports';

import FeedFormatsList from './routes/FeedFormats/List';
import FeedFormatsCreate from './routes/FeedFormats/Create';
import FeedFormatsEdit from './routes/FeedFormats/Edit';
import FeedsList from './routes/Feeds/List';
import FeedsCreate from './routes/Feeds/Create';
import FeedsEdit from './routes/Feeds/Edit';

import Amenities from './routes/Amenities';
import BulkPhotoOrder from './routes/BulkPhotoOrder';
import BulkPhotoUpload from './routes/BulkPhotoUpload';
import BulkPhotoOrdering from './routes/BulkPhotoOrdering';
import PubSubContainer from './containers/pubsubContainer/PubSubContainer';
import AlertPopupQueue from './containers/alertPopupQueue/';

import OperationsDataProvider from './components/OperationsDataProvider';
import EmitterRedirect from './components/EmitterRedirect';
import BulkBoost from 'routes/BulkBoostListing';
import HidePhotosUI from 'routes/HidePhotos';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = axios.hasToken();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
};

export default function Routes() {
  return (
    <PubSubContainer>
      <Router>
        <div>
          <EmitterRedirect />
          <Switch>
            <Route path="/login" component={SignIn} />
            <Route path="/forgot" component={ForgetPass} />
            {/* components that should not receive app layout go above */}
            <OperationsDataProvider>
              <Route
                render={() => (
                  <App>
                    <ProtectedRoute path="/" exact component={() => <div />} />
                    <ProtectedRoute path="/properties" component={Properties} />
                    <ProtectedRoute path="/property/:slug" component={PropertyEdit} />
                    <Route path="/leasing-specials" component={LeasingSpecials} />
                    <Route path="/logout" component={Logout} />
                    <ProtectedRoute path="/markets" component={Markets} />
                    <ProtectedRoute path="/market/:slug" component={MarketEdit} />
                    <ProtectedRoute path="/administration" component={Administration} />
                    <ProtectedRoute exact path="/feed-formats" component={FeedFormatsList} />
                    <ProtectedRoute exact path="/feed-formats/create" component={FeedFormatsCreate} />
                    <ProtectedRoute exact path="/feed-format/:id" component={FeedFormatsEdit} />
                    <ProtectedRoute exact path="/feeds" component={FeedsList} />
                    <ProtectedRoute exact path="/feeds/create" component={FeedsCreate} />
                    <ProtectedRoute exact path="/bulk-photo-order" component={BulkPhotoOrder} />
                    <ProtectedRoute exact path="/bulk-photo-upload" component={BulkPhotoUpload} />
                    <ProtectedRoute exact path="/planomatic-bulk-order" component={BulkPhotoOrdering} />
                    <ProtectedRoute exact path="/bulk-photo-ordering" component={BulkPhotoOrdering} />
                    <ProtectedRoute exact path="/bulk-boost-listing" component={BulkBoost} />
                    <ProtectedRoute exact path="/hide-photos" component={HidePhotosUI} />
                    <ProtectedRoute exact path="/feed/:id" component={FeedsEdit} />
                    <ProtectedRoute path="/reports" component={Reports} />
                    <ProtectedRoute path="/amenities" component={Amenities} />
                    {/*<Route path="/offices" component={Offices} /> */}
                  </App>
                )}
              />
            </OperationsDataProvider>
            <Route component={Page404} />
          </Switch>
          <AlertPopupQueue />
        </div>
      </Router>
    </PubSubContainer>
  );
}
