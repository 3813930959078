import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

const Row = ({ id, market_id, first_name, last_name, email, phone, is_default, onUpdate, onDelete }) => {
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [currEmail, setEmail] = useState(email);
  const [currPhone, setPhone] = useState(phone);
  const [isDefault, setDefault] = useState(is_default);

  useEffect(() => {
    setDefault(is_default);
  }, [is_default]);

  return (
    <TableRow key={id}>
      <TableCell component="th" scope="row">
        <Input fullWidth value={firstName} variant="outlined" onChange={(e) => setFirstName(e.target.value)} />
      </TableCell>
      <TableCell align="left">
        <Input fullWidth value={lastName} variant="outlined" onChange={(e) => setLastName(e.target.value)} />
      </TableCell>
      <TableCell align="left">
        <Input fullWidth value={currEmail} variant="outlined" type="email" onChange={(e) => setEmail(e.target.value)} />
      </TableCell>
      <TableCell align="left">
        <Input fullWidth value={currPhone} variant="outlined" onChange={(e) => setPhone(e.target.value)} />
      </TableCell>
      <TableCell align="left">
        <Checkbox
          checked={isDefault}
          onChange={() => setDefault(!isDefault)}
          color="primary"
          inputProps={{ 'aria-label': 'is default checkbox' }}
        />
      </TableCell>
      <TableCell align="left">
        <IconButton
          color="primary"
          onClick={() =>
            onUpdate({
              id,
              market_id,
              first_name: firstName,
              last_name: lastName,
              email: currEmail,
              phone: currPhone,
              is_default: isDefault,
            })
          }
        >
          <SaveOutlinedIcon />
        </IconButton>
      </TableCell>
      <TableCell align="left">
        <IconButton onClick={() => onDelete(id)}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  id: PropTypes.number,
  market_id: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  is_default: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default Row;
