import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { findIndex } from 'lodash';
import { Tab, Loader } from 'semantic-ui-react';
import { withPermissions } from '../components/HoC';
import axios from 'utils/axios';
import { FormProvider, useForm } from 'react-hook-form';
import { prepareUpdatePayload } from 'utils/syndication';

import { Box, makeStyles, Button } from '@material-ui/core';
import './PropertyEdit.scss';

import Card from '../components/Card';
import View from '../components/View';
import ViewHeader from '../components/ViewHeader';
import ViewContent from '../components/ViewContent';

import Overview from '../containers/property/Overview';
import Amenities from '../containers/property/Amenities';
import Photos from '../containers/property/photos/index.js';
import Syndication from '../containers/property/Syndication';
import SubmitMessage from '../containers/property/overview/SubmitMessage';

const useStyles = makeStyles({
  label: {
    fontSize: '1rem',
    color: 'var(--bs-body-color)',
    fontWeight: 'bold',
    paddingTop: '8px',
    paddingBottom: '15px',
    fontFamily: 'var(--bs-body-font-family)',
    '& .MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
  select: {
    width: '180px',
    height: '40px',
  },
  formLabel: {
    fontFamily: 'var(--bs-body-font-family)',
    color: 'var(--bs-body-color)',
  },
  checkboxLabel: {
    fontSize: '1rem',
    color: 'var(--bs-body-color)',
    fontFamily: 'var(--bs-body-font-family)',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    backgroundColor: '#F8F8F9',
    padding: '14px 12px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    borderRadius: '0.15rem',
  },
  button: {
    backgroundColor: '#2185d0',
    color: 'white',
    width: '71px',
    height: '40px',
  },
  positionEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: '1.5rem',
    color: 'var(--bs-body-color)',
    fontFamily: 'var(--bs-body-font-family)',
    fontWeight: 'bold',
  },
  pageTitle: {
    color: 'var(--bs-body-color)',
    fontFamily: 'var(--bs-body-font-family)',
    fontWeight: 'bold',
  },
  displayNone: {
    display: 'none',
  },
  divider: {
    borderTop: '1px solid #dedede',
    marginTop: '8px',
  },
});

const PropertyEdit = ({ match, permissions: { canEditPropertyDetails = false } }) => {
  const classes = useStyles();
  const statuses = ['Notice Unrented', 'Vacant Unrented Not Ready'];
  const methods = useForm({
    mode: 'onBlur',
    revalidateMode: 'onChange',

    defaultValues: {
      loading: true,
      error: false,
      saved: false,
      title: '',
      shortdesc: '',
      longdesc: '',
      tout: '',
      disclaimer: '',
      active: '',
      clearAmenities: false,
      amenities: [],
      allow_featured: false,
      allow_greatschools: false,
      is_pet_friendly: false,
      new_construction: false,
      btr_community: false,
      est_rehab_complete_date: null,
      rehab_type: null,
      submarket_names: null,
      subdivision: null,
      bid_type: null,
      asset_review_type: null,
      days_on_market: null,
      pro_photos: false,
      syndication_status: '',
      syndication_status_note: '',
      allow_syndication_zillow: false,
      allow_syndication_realtor: false,
      allow_syndication_facebook: false,
      allow_syndication_zumper: false,
      allow_syndication_apartmentlist: false,
      allow_syndication_apartments: false,
      allow_syndication_rentals: false,
      syndication_days: null,
      syndication_days_zillow: null,
      syndication_days_realtor: null,
      syndication_days_facebook: null,
      syndication_days_zumper: null,
      syndication_days_apartmentlist: null,
      syndication_days_apartments: null,
      syndication_days_rentals: null,
      model_home: false,
      model_home_modal_info: null,
      model_home_details: {
        community_name: '',
        operating_hours: '',
        address: '',
        interest_form_url: '',
      },
      preview_status: '',
      property_url: '',
      office: {},
      market: {},
      company_code: null,
      orderStatus: '',
    },
  });
  const { watch, setValue, reset, handleSubmit } = methods;
  const values = watch();
  const onForceRedirect = () => {
    axios.clearLogin();
  };

  const onCatchError = (err) => {
    const unauthorized = 401;
    if (err && err.response && err.response.status === unauthorized) {
      onForceRedirect();
    } else {
      setValue('loading', false);
      setValue('error', true);
    }
  };

  const onSubmit = async () => {
    setValue('loading', true);
    setValue('error', false);
    setValue('saved', false);
    const payload = prepareUpdatePayload(values);
    const { data } = await axios.put(`/api/admin/properties/${values.id}`, payload).catch((err) => onCatchError(err));
    if (data && data.id) {
      reset({
        loading: false,
        error: false,
        saved: true,
        ...data,
        preview_status: data && statuses.indexOf(data.unit_status) !== -1 ? data.unit_status : statuses[0],
      });
    } else {
      setValue('loading', false);
      setValue('error', true);
    }
  };
  const transformNullValues = (value) => {
    if (value === null) {
      return '';
    } else {
      return value;
    }
  };
  useEffect(() => {
    if (match.params.slug) {
      getProperty();
    }
  }, [match.params.slug]);

  const getPhotoIngestion = async (property) => {
    const options = {
      params: {
        withFilters: true,
        filters: JSON.stringify({
          propertyId: property.property_code,
        }),
      },
    };
    const order = await axios.get('/api/admin/photo-ingestion', options);
    const orderStatus = order?.data.results[0]?.status;

    return orderStatus;
  };

  const getProperty = async () => {
    const { data } = await axios.get(`/api/admin/properties/${match.params.slug}?mac=true`).catch((err) => {
      if (err && err.response && err.response.status === 401) {
        axios.clearLogin();
      }
      // eslint-disable-next-line no-console
      console.log(err);
    });
    const orderStatus = await getPhotoIngestion(data);
    reset({
      loading: false,
      error: false,
      saved: false,
      ...data,
      preview_status: data && statuses.indexOf(data.unit_status) !== -1 ? data.unit_status : statuses[0],
      orderStatus: orderStatus,
    });
  };
  return !values.loading ? (
    <View>
      <ViewHeader
        title={`${values.address_1} ${values.address_2 || ''}`}
        subtitle={`${values.city} ${values.state} ${values.zipcode} | ${values.beds} beds, ${values.baths} baths ${values.sqft}ft $${values.market_rent}`}
      />
      <ViewContent>
        <Card>
          <FormProvider {...methods}>
            <SubmitMessage error={values.error} saved={values.saved} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Route
                path="/property/:id/:slug?"
                render={(route) => {
                  const panes = [
                    {
                      menuItem: 'Overview',
                      slug: 'overview',
                      render: () => (
                        <Tab.Pane>
                          <Overview classes={classes} transformNullValues={transformNullValues} />
                        </Tab.Pane>
                      ),
                    },
                    {
                      menuItem: 'Amenities',
                      slug: 'amenities',
                      render: () => (
                        <Tab.Pane>
                          <Amenities classes={classes} />
                        </Tab.Pane>
                      ),
                    },
                    {
                      menuItem: 'Photos',
                      slug: 'photos',
                      render: () => (
                        <Tab.Pane>
                          {values.id ? (
                            <Photos property={values} propertyId={values.id} getProperty={getProperty} />
                          ) : null}
                        </Tab.Pane>
                      ),
                    },
                    {
                      menuItem: 'Syndication',
                      slug: 'syndication',
                      render: () => (
                        <Tab.Pane>
                          <Syndication transformNullValues={transformNullValues} classes={classes} />
                        </Tab.Pane>
                      ),
                    },
                  ];
                  const activeIndex = route.match.params.slug ? findIndex(panes, { slug: route.match.params.slug }) : 0;
                  return (
                    <Tab
                      activeIndex={activeIndex}
                      onTabChange={(e, data) => {
                        route.history.push(`/property/${route.match.params.id}/${data.panes[data.activeIndex].slug}`);

                        //save button does not show on photos page
                        if (data.panes[data.activeIndex].slug === 'photos') {
                          document
                            .getElementById('save-button')
                            .classList.replace(classes.positionEnd, classes.displayNone);
                        } else {
                          document
                            .getElementById('save-button')
                            .classList.replace(classes.displayNone, classes.positionEnd);
                        }
                      }}
                      panes={panes}
                    />
                  );
                }}
              />
              {canEditPropertyDetails && (
                <Box id="save-button" width={1} className={classes.positionEnd} p={1}>
                  <Button className={classes.button} type="submit">
                    Save
                  </Button>
                </Box>
              )}
            </form>
          </FormProvider>
        </Card>
      </ViewContent>
    </View>
  ) : (
    <Loader active={true} />
  );
};

PropertyEdit.propTypes = {
  match: PropTypes.object,
  permissions: PropTypes.object,
};

export default withPermissions(PropertyEdit);
