import React, { useState, Fragment } from 'react';
import Card from '../Card';
import ReactTable from 'react-table';
import { ErrorMessage } from '@invitation-homes/styled-ui/lib/components/Notification';
import ToSubmitTables from './ToSubmitTables';
import SubmittedOrdersTable from './SubmittedOrdersTable';
import Papa from 'papaparse';
import axios from 'utils/axios';
import { toSnakeCase } from 'utils/index';
import { constructTableData, processOrders, processSubmissionData } from 'utils/bulkPhotoOrders';
import BulkPhotoOrderButtons from './BulkPhotoOrderButtons';

const BulkPhotoOrderContent = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [processedData, setProcessedData] = useState({ data: [] });
  const [validationErrors, setValidationErrors] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [error, setError] = useState('');
  const [total, setTotal] = useState({});

  const handleSubmisionData = (response) => {
    const { success, failed } = processSubmissionData(processedData, response.data);

    setTotal({ total: processedData.data.length, submitted: success.length });
    setSubmitted(constructTableData(success));
    setFailed(constructTableData(failed));
  };

  const handleImport = (payload) => {
    setLoading(true);
    if (!loading) {
      return axios
        .post('/api/admin/process-orders', payload)
        .then((response) => {
          setLoading(false);
          return response;
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        });
    }
  };
  const importCSV = (e) => {
    setLoading(true);
    Papa.parse(e.target.files[0], {
      header: true,
      transformHeader: (header) => toSnakeCase(header),
      skipEmptyLines: true,
      chunk: async (results) => {
        setData(constructTableData(results.data));
        let { data } = await handleImport(constructTableData(results.data).data);

        const errors = data.filter((response) => (response.error ? response : false));
        data = data.filter((response) => (response.aggregateOrder ? response : false));
        if (data.length) setProcessedData(constructTableData(data));
        if (errors.length) setValidationErrors(constructTableData(errors));
      },
      complete: () => {
        setLoading(false);
      },
    });
  };

  const handleSubmitOrders = (payload) => {
    const validOrders = processOrders(payload);
    setLoading(true);
    if (!loading) {
      axios
        .post('/api/admin/photo-orders', validOrders)
        .then((response) => {
          handleSubmisionData(response);
          setLoading(false);
          setData(false);
          setProcessedData({ data: [] });
          setValidationErrors(false);
          return response;
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
        });
    }
    setSubmitted(true);
  };
  const clearSubmitted = () => {
    setSubmitted(false);
    setData(false);
    setProcessedData({ data: [] });
    setValidationErrors(false);
    setFailed(false);
    // forces a rerender to remove the input file name
    setInputKey(Date.now());
  };

  function RenderTable(stuff) {
    // stuff is just props, changed the name to make eslint not complain.
    return (
      <Fragment>
        <div>{stuff.title}</div>
        <ReactTable
          data={stuff.data}
          textAlign="center"
          columns={stuff.columns}
          className="-striped -highlight"
          manual
          minRows={0}
          showPagination={false}
          loading={loading}
          sortable={false}
        />
      </Fragment>
    );
  }

  return (
    <Card>
      <BulkPhotoOrderButtons
        failed={failed}
        submitted={submitted}
        data={data}
        clearSubmitted={clearSubmitted}
        processedData={processedData}
        importCSV={importCSV}
        inputKey={inputKey}
        handleSubmitOrders={handleSubmitOrders}
      />

      {error && <ErrorMessage>Your order was not submitted. {error}. Please try again.</ErrorMessage>}
      <br />
      <ToSubmitTables
        data={data}
        validationErrors={validationErrors}
        processedData={processedData}
        RenderTable={RenderTable}
      />

      <SubmittedOrdersTable submitted={submitted} RenderTable={RenderTable} total={total} failed={failed} />
    </Card>
  );
};

export default BulkPhotoOrderContent;
