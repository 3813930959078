import { get, isEmpty } from 'lodash';
import shortid from 'shortid';

class QueryBuilderHelper {
  constructor(options) {
    const criteria = get(options, 'criteria');
    const ordering = get(options, 'ordering');

    this._criteria_defs = [];
    this._operator_defs = [];
    this._operator_group_defs = [];
    this._operatorGroups = [];
    this._validationArray = [];

    this._ordering_operators_defs = [];
    this._ordering_criteria = [];

    if (criteria) {
      this._criteria_defs = get(criteria, 'criteria_defs', []);

      this._operator_defs = get(criteria, 'operator_defs', []);

      this._operator_group_defs = get(criteria, 'operator_group_defs', []);

      this._operatorGroups = Object.keys(this._operator_group_defs).map((key) => {
        const values = this._operator_group_defs[key];
        const valuesObject = values.reduce((acc, value) => {
          acc[value] = this._operator_defs[value];

          return acc;
        }, {});

        return {
          [`group:${key}`]: valuesObject,
        };
      });

      this._validationArray = get(criteria, 'validation', []);
    }

    if (ordering) {
      this._ordering_operators_defs = get(ordering, 'operators_defs', []);
      this._ordering_criteria = get(ordering, 'criteria', []);
    }
  }

  // eslint-disable-next-line complexity
  hasValidQuery = (query = [], isValid = true) => {
    let hasValidQuery = isValid;
    if (query && query.children && hasValidQuery) {
      if (this.hasValidGroup(query)) {
        for (let index = 0; index < query.children.length; index++) {
          hasValidQuery = this.hasValidQuery(query.children[index], hasValidQuery);
        }
      } else {
        // If a group does not have any child conditions it is
        // still a valid group if that group is the root group.
        // If it is not a root group, but a child group with no
        // child conditions or groups. That group is invalid.
        hasValidQuery = query.isRoot;
      }
    } else if (hasValidQuery) {
      hasValidQuery = this.hasValidCondition(query);
    }

    return hasValidQuery;
  };

  hasValidCondition = (condition) => {
    const { field, value } = condition || {};

    if (typeof field === 'undefined' || typeof value === 'undefined') {
      return false;
    }

    const conditionValidationObj = this.getConditionValidationObject(field);

    const { regex, opts } = conditionValidationObj;

    return RegExp(regex, opts).test(value);
  };

  hasValidGroup = (group) => {
    return (group && group.operator && group.children && group.children.length > 0) || false;
  };

  /**
   * getMappedCriteriaTypes
   *
   * Useful for displaying Criteria Types in a Dropdown
   *
   * @returns {Array} An array of mapped criteria types
   */
  getMappedCriteriaTypes() {
    return this._criteria_defs
      .filter((cd) => cd !== undefined && cd !== null)
      .map((cd) => {
        return {
          key: cd.name,
          text: cd.label,
          value: cd.name,
          data_type: cd.data_type,
        };
      });
  }

  /**
   * getCriteriaByValue
   * @param {String} The critreria being searched for
   * @returns {Object} The criteria onbject
   */
  getCriteriaByValue(criteria) {
    return this._criteria_defs.find((ct) => {
      return ct.label === criteria || ct.name === criteria;
    });
  }

  /**
   * getOperatorTypesByGroup
   * @param {string} groupOperator
   * @returns {Object} Found group operator
   */
  getOperatorTypesByGroup(groupOperator) {
    const operatorGroup = this._operatorGroups.find((og) => og[groupOperator]);

    if (operatorGroup) {
      const groupOperatorObj = operatorGroup[groupOperator];
      return Object.keys(groupOperatorObj).map((op) => {
        return {
          key: op,
          text: groupOperatorObj[op].label,
          value: groupOperatorObj[op].operator,
        };
      });
    }
    return [];
  }

  getOperatorsByDefinition(operators) {
    return operators.map((operator) => {
      const operatorValue = this._operator_defs[operator];
      return {
        key: operator,
        value: operatorValue.operator,
        text: operatorValue.label,
      };
    });
  }

  getMappedOrderTypes() {
    return this._ordering_criteria.map((oc) => {
      return { key: oc.name, text: oc.label, value: oc.name };
    });
  }

  getOrderOperatorByOrderType(orderType) {
    const order = this._ordering_criteria.filter((ood) => {
      return ood.name === orderType || ood.label === orderType;
    })[0];
    return (
      (order &&
        order.operators.map((operator) => {
          const criteria = this._ordering_operators_defs[operator];
          return {
            key: operator,
            text: criteria.label,
            value: criteria.direction,
          };
        })) ||
      []
    );
  }

  getConditionValidationObject(criteria) {
    const criteriaToValidate = this.getCriteriaByValue(criteria);
    if (criteriaToValidate && criteriaToValidate.validation) {
      return this._validationArray[criteriaToValidate.validation[0]];
    }

    return {
      regex: '',
      opts: '',
      message: '',
    };
  }

  mapCriteriaValuesForDropdowns(criteria) {
    return criteria.values
      ? criteria.values.map((value) => {
          const v = Object.keys(value).filter((value) => value !== null && value !== undefined);
          return {
            key: shortid.generate(),
            text: value[v],
            value: v[0],
          };
        })
      : null;
  }

  getCriteriaLabelByValue(criteriaValue) {
    return this._criteria_defs
      .filter((cd) => cd.name === criteriaValue)
      .map((c) => c.label)
      .join();
  }

  getOrderingLabelByCondition(sortCondition) {
    return this._ordering_criteria
      .filter((oc) => oc.name === sortCondition)
      .map((sc) => sc.label)
      .join();
  }

  getConditionValueByCriteriaAndKey(criteria, valueKey) {
    const result = this._criteria_defs
      .filter((cd) => {
        return cd.name === criteria;
      })
      .map((fcd) => {
        return (fcd.values || [])
          .reduce((acc, valueObj) => {
            if (valueObj[valueKey]) {
              acc.push(valueObj[valueKey]);
            }

            return acc;
          }, [])
          .join();
      })
      .join();

    return isEmpty(result) ? valueKey || '' : result;
  }
}

export default QueryBuilderHelper;
