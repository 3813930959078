import React from 'react';
import { Flex, Box } from 'grid-styled';
import deleteIcon from '../../../icons/delete.svg';
import rearrangeIcon from '../../../icons/rearrange.svg';
import { rowStyle, widerRowStyle, titleStyle } from '../config';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

export const DragHandle = SortableHandle(() => (
  <Box pl={10} pr={20}>
    <img alt="" className="cursor-pointer" src={rearrangeIcon} />
  </Box>
));

export const SortableList = SortableContainer(({ rows, onDelete, isEditing }) => (
  <div className="rt-tbody" style={{ minWidth: '900px', overflow: 'visible' }}>
    {rows.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        i={index}
        onDelete={onDelete}
        index={index}
        row={value}
        isEditing={isEditing}
      />
    ))}
  </div>
));

export const SortableItem = SortableElement(({ row, i, onDelete, isEditing }) => (
  <div className="rt-tr-group">
    <div className={`rt-tr ${i % 2 ? '-even' : '-odd'}`}>
      <div className="rt-td" style={rowStyle}>
        <Flex flexDirection="row">
          <DragHandle />
          <Box>{i + 1}</Box>
        </Flex>
      </div>
      {isEditing && (
        <div className={`rt-td ${row.is_syndicated ? 'positive' : 'negative'}-row`} style={widerRowStyle}>
          {row.is_syndicated.toString()}
        </div>
      )}
      <div className="rt-td" style={titleStyle}>
        {row.address_1}
      </div>
      <div className="rt-td" style={widerRowStyle}>
        {row.city}
      </div>
      <div className="rt-td" style={rowStyle}>
        {row.state}
      </div>
      <div className="rt-td" style={rowStyle}>
        {row.zipcode}
      </div>
      <div className="rt-td" style={widerRowStyle}>
        {row.property_code}
      </div>
      <div className="rt-td" style={rowStyle}>
        <img
          alt="delete"
          className="cursor-pointer"
          src={deleteIcon}
          onClick={() => {
            onDelete(i);
          }}
        />
      </div>
    </div>
  </div>
));
