import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from 'grid-styled';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { has } from 'lodash';

import DateRangeSelector from '../../components/DateRangeSelector';
import { withPermissions } from '../../components/HoC';

import { openInNewTab } from '../../utils';

class MarketReport extends Component {
  static propTypes = {
    onFetchData: PropTypes.func.isRequired,
    showLoading: PropTypes.func.isRequired,
    onBeforeRequestDataSetup: PropTypes.func.isRequired,
    history: PropTypes.object,
    headline: PropTypes.string,
    endpoint: PropTypes.string,
    render: PropTypes.func,
    permissions: PropTypes.object,
  };

  state = {
    error: false,
  };

  onError = (errorMsg) => {
    this.setState({
      error: true,
      errorMsg: errorMsg,
    });
  };

  onSubmit = (date) => {
    const { onBeforeRequestDataSetup, onFetchData } = this.props;
    onBeforeRequestDataSetup({ ...date });
    onFetchData();
  };

  onClear = () => {
    const { onBeforeRequestDataSetup, onFetchData } = this.props;
    onBeforeRequestDataSetup({});
    onFetchData({ page: 0 });
  };

  onModalClose = (type) => {
    this.setState({
      modalActive: false,
    });

    if (type === 'success') {
      this.props.onFetchData();
    }
  };

  getTdProps = (rowInfo, column) => {
    const key = column.id;
    const style = {
      cursor: 'pointer',
    };

    if (key !== 'is_syndicated' && key !== 'is_syndicated_ils') {
      if (rowInfo && rowInfo.original[key] === 'Yes') {
        style['backgroundColor'] = '#80FF80';
      } else if (rowInfo && rowInfo.original[key] === 'No') {
        style['backgroundColor'] = '#FCFF80';
      }
    }

    if (column.id === 'address_1') {
      column.Cell = () => <Link to={`/property/${rowInfo.original.slug}`}>{rowInfo.original.address_1}</Link>;
      return {};
    }
    return {
      style: style,
      onClick: () => {
        has(rowInfo, 'original') && openInNewTab(`/property/${rowInfo.original.slug}`);
      },
    };
  };

  date = null;

  renderError() {
    if (this.state.error) {
      return (
        <Message negative onDismiss={this.hideError} ref={(errorMessage) => (this.errorMessage = errorMessage)}>
          <p>{this.state.errorMsg}</p>
        </Message>
      );
    }

    if (this.errorMessage && this.errorMessage.focus) {
      this.errorMessage.focus();
    }

    return null;
  }

  hideError = () => {
    this.setState({
      error: false,
    });
  };

  render() {
    const { date } = this.state;

    return (
      <div>
        {this.renderError()}
        <Flex>
          <Box pb={60} pt={20}>
            <span>{this.props.headline}</span>
          </Box>
        </Flex>
        <DateRangeSelector
          onError={this.onError}
          onSubmit={this.onSubmit}
          onClear={this.onClear}
          onErrorHide={this.hideError}
          filterLabel="Available Date"
          keyName="available"
        />
        {this.props.render(this.getTdProps, date)}
      </div>
    );
  }
}

export default withPermissions(MarketReport);
