import React, { Component } from 'react';
import { Flex, Box } from 'grid-styled';
import { Link, withRouter } from 'react-router-dom';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import axios from 'utils/axios';
import rearrangeIcon from '../../icons/rearrange.svg';
import editIcon from '../../icons/edit.svg';
import deleteIcon from '../../icons/delete.svg';
import PropTypes from 'prop-types';

class FeaturedBlocks extends Component {
  static propTypes = {
    marketId: PropTypes.string,
    slug: PropTypes.string,
  };

  constructor() {
    super();
    this.state = {
      rows: [],
      loadingRows: [],
    };
  }

  componentDidMount() {
    const { marketId } = this.props;
    axios
      .get(`/api/admin/featured?market_id=${marketId}`)
      .then((res) => {
        this.setState({
          rows: res.data,
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          axios.clearLogin();
        }
      });
  }

  onChangeOrder = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        rows: arrayMove(this.state.rows, oldIndex, newIndex),
      },
      () => {
        const payload = [...this.state.rows].map((item, i) => {
          return {
            id: item.id,
            order: i,
          };
        });

        axios
          .put(`/api/admin/featured`, payload)
          .then(() => {})
          .catch((err) => {
            if (err && err.response && err.response.status === 401) {
              axios.clearLogin();
            }
          });
      },
    );
  };

  onChange = (e) => {
    const copyRows = [...this.state.rows],
      target = e.target,
      currentCheckbox = this.state.rows[target.id];

    const newState = copyRows.map((item, i) => {
      return {
        ...item,
        is_visible: parseFloat(target.id) === i ? target.checked : item.is_visible,
      };
    });

    this.setState(
      {
        rows: newState,
        loadingRows: [...this.state.loadingRows, currentCheckbox.id],
      },
      () => {
        const payload = new FormData();
        payload.append('is_visible', this.state.rows[target.id].is_visible);

        axios
          .put(`/api/admin/featured/${currentCheckbox.id}`, payload)
          .then(() => {
            this.setState({
              loadingRows: this.state.loadingRows.filter((item) => item !== currentCheckbox.id),
            });
          })
          .catch((err) => {
            if (err && err.response && err.response.status === 401) {
              axios.clearLogin();
            }
          });
      },
    );
  };

  deleteFeatureBlock = (id) => {
    const copyRows = [...this.state.rows],
      isConfirmed = window.confirm('Are you sure you want to delete this feature block?');

    if (!isConfirmed) return;

    const newRows = copyRows.reduce((accum, item, i) => {
      if (i !== id) {
        accum.push(item);
      }
      return accum;
    }, []);

    axios
      .delete(`/api/admin/featured/${this.state.rows[id].id}`)
      .then(() => {})
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          axios.clearLogin();
        }
      });

    this.setState({
      rows: newRows,
    });
  };

  render() {
    const { rows, loadingRows } = this.state;
    const { slug } = this.props;

    return (
      <div>
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>Featured Homes</h2>
          </Box>
        </Flex>
        <Flex>
          <Box pl="2%" pb={20} width={1}>
            <div className="ReactTable -striped -highlight">
              <div className="rt-table">
                <div className="rt-thead -header" style={{ minWidth: '800px' }}>
                  <div className="rt-tr">
                    <div className="rt-th rt-resizable-header" style={rowStyle}>
                      <div className="rt-resizable-header-content">Order</div>
                    </div>
                    <div className="rt-th rt-resizable-header" style={titleStyle}>
                      <div className="rt-resizable-header-content">Title</div>
                    </div>
                    <div className="rt-th rt-resizable-header" style={rowStyle}>
                      <div className="rt-resizable-header-content">View type</div>
                    </div>
                    <div className="rt-th rt-resizable-header" style={rowStyle}>
                      <div className="rt-resizable-header-content">Visible ?</div>
                    </div>
                    <div className="rt-th rt-resizable-header" style={rowStyle}>
                      <div className="rt-resizable-header-content">Edit</div>
                    </div>
                    <div className="rt-th rt-resizable-header" style={rowStyle}>
                      <div className="rt-resizable-header-content">Remove</div>
                    </div>
                  </div>
                </div>
                <SortableList
                  rows={rows}
                  loadingRows={loadingRows}
                  onSortEnd={this.onChangeOrder}
                  helperClass={'rt-tr-group--sortable'}
                  onDelete={this.deleteFeatureBlock}
                  onChange={this.onChange}
                  slug={slug}
                  useDragHandle
                />
              </div>
            </div>
          </Box>
        </Flex>
        <Flex>
          <Box ml={'2%'} width={1}>
            <Flex>
              <Box>Click and drag rows to reorder</Box>
              <Box ml={'auto'}>
                <Link to={`/market/${slug}/featured-homes/add`} className={'ui button'}>
                  Add featured block
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </div>
    );
  }
}

export default withRouter(FeaturedBlocks);

const DragHandle = SortableHandle(({ id }) => (
  <Box pl={10} pr={20}>
    <img id={id} alt="" className="cursor-pointer" src={rearrangeIcon} />
  </Box>
));

const rowStyle = { maxWidth: '100px', textAlign: 'center' };
const titleStyle = { maxWidth: '500px', minWidth: '300px' };

const SortableList = SortableContainer(({ rows, loadingRows, onDelete, slug, onChange }) => (
  <div className="rt-tbody" style={{ overflow: 'visible', minWidth: '800px' }}>
    {rows.map((value, index) => (
      <SortableItem
        key={`item-${index}`}
        i={index}
        onDelete={onDelete}
        onChange={onChange}
        index={index}
        row={value}
        loading={loadingRows.find((item) => item === value.id)}
        slug={slug}
      />
    ))}
  </div>
));

const SortableItem = SortableElement(({ row, loading, i, onDelete, slug, onChange }) => (
  <div className="rt-tr-group">
    <div className={`rt-tr ${i % 2 ? '-even' : '-odd'}`}>
      <div className="rt-td" style={rowStyle}>
        <Flex flexDirection="row">
          <DragHandle id={`drag_${row.slug}`} />
          <Box>{i + 1}</Box>
        </Flex>
      </div>
      <div id="featured_block_title" className="rt-td" style={titleStyle}>
        {row.title}
      </div>
      <div className="rt-td" style={rowStyle}>
        {row.view_type}
      </div>
      <div className="rt-td" style={rowStyle}>
        <input type="checkbox" onChange={onChange} id={i} checked={row.is_visible} disabled={loading} />
      </div>
      <div className="rt-td" style={rowStyle}>
        {!row.default_type ? (
          <Link id={`edit_${row.slug}`} to={`/market/${slug}/featured-homes/${row.id}`}>
            <img alt="edit" className="cursor-pointer edit" src={editIcon} />
          </Link>
        ) : (
          '-'
        )}
      </div>
      <div className="rt-td" style={rowStyle}>
        {!row.default_type ? (
          <img
            id={`delete_${row.slug}`}
            alt="delete"
            className="cursor-pointer delete"
            src={deleteIcon}
            onClick={() => {
              onDelete(i);
            }}
          />
        ) : (
          '-'
        )}
      </div>
    </div>
  </div>
));
