import React from 'react';
import PropTypes from 'prop-types';

export default function ViewContent({ children }) {
  return <div className="view-content view-components">{children}</div>;
}

ViewContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
