import React, { PureComponent } from 'react';
import { Dropdown, Button, Menu, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Flex } from 'grid-styled';
import { QueryBuilderContext } from '../../components/QueryBuilder';

import './Group.scss';

class Group extends PureComponent {
  static propTypes = {
    isRoot: PropTypes.bool,
    id: PropTypes.string,
    item: PropTypes.object,
    parent: PropTypes.object,
    addQueryItem: PropTypes.func,
    removeQueryItem: PropTypes.func,
    updateQueryCondition: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    isQueryValid: PropTypes.bool,
  };

  static defaultProps = {
    isRoot: true,
    id: null,
    item: { operator: 'AND', children: [] },
    parent: { operator: 'AND', children: [] },
    addQueryItem: () => {},
    removeQueryItem: () => {},
  };

  onChangeHandler = (e, { value }, query) => {
    const { updateQueryCondition, id, parent, item } = this.props;
    const newValue = { ...item, operator: value };

    if (id === query.criteria.id) {
      query.criteria.operator = value;
    }

    updateQueryCondition({ parent, id, newValue, query });
  };

  render() {
    const { isRoot, children, item, id, parent, addQueryItem, removeQueryItem, isQueryValid } = this.props;
    return (
      <QueryBuilderContext.Consumer>
        {({ query }) => (
          <div className={'group'} id={id}>
            <Flex>
              <Menu>
                <Dropdown
                  defaultValue={item.operator}
                  options={[
                    { text: 'AND', value: 'AND', key: 'AND' },
                    { text: 'OR', value: 'OR', key: 'OR' },
                  ]}
                  onChange={(e, data) => this.onChangeHandler(e, data, query)}
                  item
                />
              </Menu>
              <Button
                color={'green'}
                icon
                onClick={(e) => {
                  e.preventDefault();
                  addQueryItem({ type: 'QUERY_CONDITION', parent: item });
                }}
              >
                <Icon name={'plus circle'} />
                Add Condition
              </Button>
              <Button
                color={'green'}
                icon
                onClick={(e) => {
                  e.preventDefault();
                  addQueryItem({ type: 'QUERY_GROUP', parent: item });
                }}
              >
                <Icon name={'plus circle'} />
                Add Group
              </Button>
              <Button
                color={isRoot ? 'green' : 'red'}
                icon
                onClick={(e) => {
                  e.preventDefault();
                  isRoot ? addQueryItem({ type: 'QUERY_SORT_ORDER', parent }) : removeQueryItem({ parent, id });
                }}
              >
                {isRoot ? <Icon name={'plus circle'} /> : <Icon name={'minus circle'} />}
                {isRoot ? 'Add Sort Ordering' : 'Remove Group'}
              </Button>
              <div className={'group__error-message'}>
                {!isQueryValid &&
                  !isRoot &&
                  children.length === 0 &&
                  'A group must has at least one condition or group'}
              </div>
            </Flex>
            {children}
          </div>
        )}
      </QueryBuilderContext.Consumer>
    );
  }
}

export default Group;
