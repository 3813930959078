import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Box, Flex } from 'grid-styled';
import { Loader } from 'semantic-ui-react';
import Form from './Form';
import { API } from '../utils';
import backIcon from '../../../icons/backArrow.svg';

class EditSpecial extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  state = {
    special: null,
    isLoading: true,
    notificationMsg: '',
  };

  componentDidMount() {
    API.get(this.id).then((r) => {
      this.setState({ special: r.data, isLoading: false });
    });
  }

  get id() {
    return this.props.match.params.specialId;
  }

  renderLoading() {
    return (
      <Box pt="2%" pb="2%" style={{ textAlign: 'center' }}>
        <Box p="2%">
          <Loader active={true} inline="centered" />
        </Box>
        <p>Please wait while we load the special for editing.</p>
      </Box>
    );
  }

  render() {
    const { special, isLoading } = this.state;
    return (
      <Box pb={100}>
        {special && (
          <Flex>
            <Box pl="2%" pb={20} pt={20}>
              <Link to={`/leasing-specials/view/${special.id}`} style={{ color: 'var(--green)' }}>
                <img src={backIcon} alt="" /> Back to View Special
              </Link>
            </Box>
          </Flex>
        )}
        <Flex>
          <Box pl="2%" pb={20}>
            <h2>Leasing Special Details</h2>
          </Box>
        </Flex>
        {isLoading ? this.renderLoading() : <Form special={special} />}
      </Box>
    );
  }
}

export default withRouter(EditSpecial);
