import { snakeToTitleCase } from './index';

export const constructTableData = (data) => {
  if (data.length === 0) return;
  let dataLocation;
  let selectedData;
  if (data[0].aggregateOrder) {
    dataLocation = data[0].aggregateOrder;
    selectedData = data.map((item) => item.aggregateOrder);
  } else if (data[0].error) {
    dataLocation = data[0].error;
    selectedData = data.map((item) => item.error);
  } else {
    dataLocation = data[0];
    selectedData = data;
  }
  return {
    columns: Object.keys(dataLocation).map((key) => {
      return {
        Header: snakeToTitleCase(key),
        accessor: key,
        minWidth: 150,
      };
    }),
    data: selectedData,
  };
};

export const processSubmissionData = (processedData, responseData) => {
  const failed = [];
  const success = [];

  processedData?.data.forEach((item, i) => {
    const results = responseData.find((row) => row.order.property_id === item.property_id);

    if (results?.result) {
      if (!results.result.success) {
        failed.push({
          property_code: item.property_code,
          service: item.service,
          order_id: 'Order failed',
          error_message: results.result.message,
        });
      } else {
        success.push({
          property_code: item.property_code,
          order_id: results.result.results[0].orderId,
          project_id: results.result.results[0].projectId,
          status: results.result.results[0].status,
          service: item.service,
        });
      }
    }
  });

  return { success, failed };
};

export const processOrders = (orders = []) => {
  return orders.map((order) => {
    let newOrder = { ...order };
    delete newOrder.property_code;
    delete newOrder.has_tour;
    delete newOrder.has_all_pro_photos;
    delete newOrder.is_syndicated;
    delete newOrder.has_floorplan;
    newOrder = { ...newOrder, vendor: 'InsideMaps' };
    return newOrder;
  });
};
