import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { toSnakeCase } from 'utils/index';
import axios from 'utils/axios';
import AttachFile from '@material-ui/icons/AttachFile';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { Message } from 'semantic-ui-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './md-style';
import Card from '../Card';
import './style.scss';

const BulkBoostListing = () => {
  const [disabled, setDisabled] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const boostProperties = (propertyCodes) => {
    return axios.post('/api/admin/properties/boost', propertyCodes);
  };

  const handleFileUpload = () => {
    setDisabled(true);
    setHasError(false);
    setMessage(undefined);

    Papa.parse(selectedFile, {
      complete: async (result) => {
        try {
          if (result.data.length === 0) {
            setHasError(true);
            setMessage({ messageType: 'negative', description: 'File is empty' });
          } else if (!('property_code' in result.data[0])) {
            setHasError(true);
            setMessage({
              messageType: 'negative',
              description:
                'The file is not in the correct format. The header must be named "property code", "property_code" or "Property Code"',
            });
          } else {
            const propertyCodes = result.data.map((column) => column['property_code']);
            const resp = await boostProperties(propertyCodes);
            setMessage({ messageType: 'positive', description: resp.data });
            setColumnData(propertyCodes);
            setSelectedFile(undefined);
          }
        } catch (error) {
          setHasError(true);
          setMessage({ messageType: 'negative', description: 'There was an error uploading the file' });
        } finally {
          setDisabled(false);
        }
      },
      header: true,
      transformHeader: (header) => toSnakeCase(header),
      skipEmptyLines: true,
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setHasError(false);
    setMessage(undefined);
    setColumnData([]);
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    disabled,
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: { 'text/csv': [] },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(hasError ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, hasError],
  );

  const renderPropertyCodes = () => {
    return (
      columnData.length > 0 && (
        <div className="file-details__container">
          <br />
          <h4>Property Codes:</h4>
          <ul>
            {columnData.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        </div>
      )
    );
  };

  const messageTitle = message?.messageType === 'positive' ? 'Success' : 'Fail';

  return (
    <Card>
      {message ? (
        <Message
          positive={message.messageType === 'positive'}
          negative={message.messageType === 'negative'}
          onDismiss={() => {
            setMessage(undefined);
          }}
        >
          <Message.Header>{messageTitle}</Message.Header>
          <p>{message.description}</p>
        </Message>
      ) : null}

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <CloudUpload color="primary" fontSize="large" />
        <span className="drag-drop__text">Drag and drop your CSV file here to start uploading</span>
        <span className="drag-drop__text">or</span>
        <Button
          variant="contained"
          color="primary"
          classes={{ containedPrimary: 'drag-drop__button' }}
          disabled={disabled}
        >
          Browse a file
        </Button>
      </div>
      {selectedFile ? (
        <div className="file-upload">
          {disabled ? <LinearProgress /> : null}

          <div className="file-upload__text">
            <AttachFile color="primary" />
            <span>{selectedFile.name}</span>
            <Button
              variant="contained"
              color="primary"
              classes={{ containedPrimary: 'file-upload__button' }}
              disabled={disabled}
              onClick={handleFileUpload}
            >
              Boost
            </Button>
          </div>
        </div>
      ) : null}

      {renderPropertyCodes()}
    </Card>
  );
};

export default BulkBoostListing;
