import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

export default function CardComponent({ children }) {
  return (
    <Card>
      <CardBody className="table-responsive">{children}</CardBody>
    </Card>
  );
}

CardComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
