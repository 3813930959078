import React from 'react';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import { withRouter } from 'react-router-dom';
// import './bulkOrder.scss';
import BulkPhotoUploadContent from '../../components/BulkPhotoUpload';

const BulkPhotoUpload = () => {
  return (
    <View>
      <ViewHeader title="Bulk Photo Upload" />
      <ViewContent>
        <BulkPhotoUploadContent />
      </ViewContent>
    </View>
  );
};

export default withRouter(BulkPhotoUpload);
