import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { has } from 'lodash';

import MarketReport from './MarketReport';
import Table from '../../components/Table';

import ExportToCVSButton from '../../containers/ExportToCSVButton';
import withTableResources from '../../containers/TableStateContainer';

import { MAX_REPORT_SIZE_LIMIT } from '../../utils/constants';
import { openInNewTab } from 'utils/index';
import { transformValues } from 'utils/reports';

class PreMarketingReport extends Component {
  static propTypes = {
    // inherited from withRouter
    history: PropTypes.object,

    // inherited from withTableResources
    tableState: PropTypes.object,
    onFetchData: PropTypes.func,
    onFilteredChange: PropTypes.func,
    onSortedChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    onBeforeRequestDataSetup: PropTypes.func,
  };

  async componentDidMount() {
    await this.props.onFetchData();
  }

  render() {
    const {
      tableState,
      onFilteredChange,
      onSortedChange,
      onPageChange,
      onPageSizeChange,
      onFetchData,
      showLoading,
      onBeforeRequestDataSetup,
    } = this.props;

    return (
      <MarketReport
        onFetchData={onFetchData.bind(this)}
        showLoading={showLoading.bind(this)}
        onBeforeRequestDataSetup={onBeforeRequestDataSetup.bind(this)}
        history={this.props.history}
        headline="View properties that are syndicated but not on the market yet."
        render={() => (
          <div>
            <Table
              data={tableState.data}
              columns={tableState.columns}
              page={tableState.page}
              pages={tableState.pages}
              filtered={tableState.filtered}
              sorted={tableState.sorted}
              loading={tableState.loading}
              onFilteredChange={onFilteredChange}
              onSortedChange={onSortedChange}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              getTdProps={(state, rowInfo, column) => {
                const style = {
                  cursor: 'pointer',
                };
                if (column.id === 'address_1') {
                  column.Cell = () => (
                    <Link to={`/property/${rowInfo.original.slug}`}>{rowInfo.original.address_1}</Link>
                  );
                  return {};
                }
                return {
                  style: style,
                  onClick: () => {
                    has(rowInfo, 'original') && openInNewTab(`/property/${rowInfo.original.slug}`);
                  },
                };
              }}
            />
            {ExportToCVSButton({
              state: tableState,
              maxReportSizeLimit: MAX_REPORT_SIZE_LIMIT,
              reportName: 'pre-marketing',
              endpoint: '/api/admin/properties/pre-marketing',
              transformValues: transformValues,
            })}
          </div>
        )}
      />
    );
  }
}

export default withTableResources(withRouter(PreMarketingReport), {
  endpoint: '/api/admin/properties/pre-marketing',
});
