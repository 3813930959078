import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const UploadPropertiesCSVInput = ({ importCSV }) => {
  return (
    <div className="bulk-photo-upload-buttons">
      <input type="file" accept=".csv" onChange={importCSV} />
    </div>
  );
};

export default UploadPropertiesCSVInput;

UploadPropertiesCSVInput.propTypes = {
  importCSV: PropTypes.func,
};
