import React from 'react';
import ValidationError from '../components/validation/ValidationError';
import ValidationRequired from '../components/validation/ValidationRequired';
import { Input } from 'semantic-ui-react';
import { Box } from 'grid-styled';
import PropTypes from 'prop-types';

class CustomInput extends React.Component {
  static propTypes = {
    validationError: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    withValidation: PropTypes.bool,
    input: PropTypes.object,
    onChange: PropTypes.func,
    LabelComponent: PropTypes.any,
  };

  render() {
    const { validationError, label, name, value, type, disabled, withValidation, onChange, input, LabelComponent } =
      this.props;

    const inputProps = {};
    if (LabelComponent) {
      inputProps.label = LabelComponent;
      inputProps.labelPosition = 'right';
    }

    return (
      <div className="custom-input">
        <label>
          <strong>
            {label} {withValidation && <ValidationRequired />}
          </strong>
        </label>
        <Input
          {...inputProps}
          fluid={true}
          name={name}
          disabled={disabled}
          type={type || 'text'}
          placeholder={label}
          value={value}
          input={input}
          onChange={onChange}
          className="custom-input__input"
        />
        <Box pt="1%" pb="1%">
          {withValidation && validationError && <ValidationError>{validationError}</ValidationError>}
        </Box>
      </div>
    );
  }
}

export default CustomInput;
