import * as React from 'react';
import PropTypes from 'prop-types';
import { filtersReducer } from 'utils/reports';

const FiltersStateContext = React.createContext();
const FiltersDispatchContext = React.createContext();

export function FiltersProvider({ children }) {
  const initialState = {
    createdAt: {
      startDate: '',
      endDate: '',
      error: '',
    },
    updatedAt: {
      startDate: '',
      endDate: '',
      error: '',
    },
    completedAt: {
      startDate: '',
      endDate: '',
      error: '',
    },
  };
  const [state, dispatch] = React.useReducer(filtersReducer, initialState);
  return (
    <FiltersStateContext.Provider value={state}>
      <FiltersDispatchContext.Provider value={dispatch}>{children}</FiltersDispatchContext.Provider>
    </FiltersStateContext.Provider>
  );
}

FiltersProvider.propTypes = {
  children: PropTypes.node,
};

export function useFiltersState() {
  const context = React.useContext(FiltersStateContext);

  if (context === undefined) {
    throw new Error('useFiltersState must be used within a FiltersProvider');
  }

  return context;
}

export function useFiltersDispatch() {
  const context = React.useContext(FiltersDispatchContext);

  if (context === undefined) {
    throw new Error('useFiltersDispatch must be used within a FiltersProvider');
  }

  return context;
}

export function usePhotoOrdersReportFilters() {
  const { createdAt, updatedAt, completedAt } = useFiltersState();
  const dispatch = useFiltersDispatch();

  return {
    state: {
      createdAt,
      updatedAt,
      completedAt,
    },
    actions: {
      setCreatedAtDates: setCreatedAtDates(dispatch),
      setCreatedAtDatesError: setCreatedAtDatesError(dispatch),
      setUpdatedAtDates: setUpdatedAtDates(dispatch),
      setUpdatedAtDatesError: setUpdatedAtDatesError(dispatch),
      setCompletedAtDates: setCompletedAtDates(dispatch),
      setCompletedAtDatesError: setCompletedAtDatesError(dispatch),
    },
  };
}

function setCreatedAtDates(dispatch) {
  return ({ startDate, endDate }) =>
    dispatch({
      type: 'createdat:dates',
      createdAt: {
        startDate,
        endDate,
      },
    });
}

function setCreatedAtDatesError(dispatch) {
  return (error) =>
    dispatch({
      type: 'createdat:error',
      createdAt: { error },
    });
}

function setUpdatedAtDates(dispatch) {
  return ({ startDate, endDate }) =>
    dispatch({
      type: 'updatedat:dates',
      updatedAt: {
        startDate,
        endDate,
      },
    });
}

function setUpdatedAtDatesError(dispatch) {
  return (error) =>
    dispatch({
      type: 'updatedat:error',
      updatedAt: { error },
    });
}

function setCompletedAtDates(dispatch) {
  return ({ startDate, endDate }) =>
    dispatch({
      type: 'completedat:dates',
      completedAt: {
        startDate,
        endDate,
      },
    });
}

function setCompletedAtDatesError(dispatch) {
  return (error) =>
    dispatch({
      type: 'completedat:error',
      completedAt: { error },
    });
}
