export const rowStyle = { maxWidth: '100px', textAlign: 'center' };

export const widerRowStyle = {
  maxWidth: '150px',
  minWidth: '100px',
  textAlign: 'center',
};

export const titleStyle = { maxWidth: '500px', minWidth: '200px' };

export const MAX_NUM_PROPERTIES = 24;
