import React from 'react';
import PropTypes from 'prop-types';
import AlertPopup from '../../components/AlertPopup';
import './AlertPopupQueue.scss';
import { AlertPopupQueueContext } from './AlertPopupQueueContext';

class AlertPopupQueue extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  };

  static contextType = AlertPopupQueueContext;

  state = {
    alertQueue: [],
  };

  componentDidMount() {
    this.context.emitter.on('alert-queue:add', this.addAlertsToQueue);
    this.context.emitter.on('alert-queue:remove', this.removeAlertsFromQueue);
  }

  addAlertsToQueue = (eventObj) => {
    const { alertQueue } = this.state;

    // make the keys unique
    eventObj.key = `${Date.now()}-${alertQueue.length}`;
    alertQueue.push(eventObj);

    this.setState({ alertQueue });
  };

  removeAlertsFromQueue = (eventId) => {
    const { alertQueue } = this.state;

    alertQueue.splice(
      alertQueue.findIndex((e) => {
        return e.key === eventId;
      }),
      1,
    );

    this.setState({ alertQueue });
  };

  render() {
    const { alertQueue } = this.state;
    return (
      <div className={'alert-queue'}>
        {alertQueue.map((eventObj) => {
          return <AlertPopup type={eventObj.type} message={eventObj.message} key={eventObj.key} id={eventObj.key} />;
        })}
      </div>
    );
  }
}

export default AlertPopupQueue;
