import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from 'grid-styled';
import { sortUsers } from '../../utils';
import editIcon from '../../icons/edit.svg';
import deleteIcon from '../../icons/delete.svg';
import axios from 'utils/axios';
import { confirmAndFilterValues } from 'utils/administration';

class UsersList extends React.Component {
  state = {
    users: [],
  };

  componentDidMount() {
    axios.get(`/api/admin/users`).then((r) => {
      const sortedUsers = sortUsers(r.data);
      this.setState({
        users: sortedUsers,
      });
    });
  }

  deleteUser = (id) => {
    const newUsers = confirmAndFilterValues([...this.state.users], id, 'Are you sure you want to delete user?');

    this.setState({
      users: newUsers,
    });

    axios.delete(`/api/admin/users/${id}`);
  };

  renderUsers = () => {
    const { users } = this.state;

    return users.map((user, i) => (
      <div className="rt-tr-group" key={user.id}>
        <div className={`rt-tr ${i % 2 ? '-even' : '-odd'}`}>
          <div className="rt-td" style={emailStyle}>
            {user.email}
          </div>
          <div className="rt-td" style={rowStyle}>
            {user.last_name}
          </div>
          <div className="rt-td" style={rowStyle}>
            {user.first_name}
          </div>
          <div className="rt-td" style={rowStyle}>
            <Link to={`/administration/users/${user.id}`}>
              <img alt="edit" className="cursor-pointer" src={editIcon} />
            </Link>
          </div>
          <div className="rt-td" style={rowStyle}>
            <img
              alt="delete"
              className="cursor-pointer"
              src={deleteIcon}
              onClick={() => {
                this.deleteUser(user.id);
              }}
            />
          </div>
        </div>
      </div>
    ));
  };

  render() {
    return (
      <div>
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>Users list</h2>
          </Box>
        </Flex>
        <Flex>
          <Box pl="2%" pb={20} width={1}>
            <div className="ReactTable -striped -highlight">
              <div className="rt-table">
                <div className="rt-thead -header" style={{ minWidth: '900px' }}>
                  <div className="rt-tr">
                    <div className="rt-th" style={emailStyle}>
                      <div>Email</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Last Name</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>First Name</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Edit</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Remove</div>
                    </div>
                  </div>
                </div>
                <div className="rt-tbody" style={{ minWidth: '900px', overflow: 'visible' }}>
                  {this.renderUsers()}
                </div>
              </div>
            </div>
          </Box>
        </Flex>
        <Flex>
          <Box ml={'2%'} width={1}>
            <Flex>
              <Box ml={'auto'}>
                <Link to={`/administration/users/add`} className={`ui button primary`}>
                  Add User
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </div>
    );
  }
}

const rowStyle = { maxWidth: '150px', textAlign: 'center' };
const emailStyle = {
  minWidth: '300px',
  maxWidth: '500px',
  textAlign: 'center',
};

export default UsersList;
