import React from 'react';
import PropTypes from 'prop-types';
import { MdMenu } from 'react-icons/md';

// style
import './style.scss';

export default function Header(props) {
  return (
    <header className="site-head d-flex align-items-center justify-content-between">
      <div className="wrap me-4">
        <MdMenu size="24" color="#fff" onClick={props.toggleNav} style={{ cursor: 'pointer' }} />
      </div>
      <div className="right-elems ml-auto d-flex">{}</div>
    </header>
  );
}

Header.propTypes = {
  toggleNav: PropTypes.func,
};
