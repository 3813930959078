import React, { Component } from 'react';
import { Form, TextArea, Button, Message } from 'semantic-ui-react';
import { Flex, Box } from 'grid-styled';
import axios from 'utils/axios';
import { scrollToTop, trimValue } from 'utils';
import PropTypes from 'prop-types';
import { withPermissions } from '../../components/HoC';

class PhotoDetails extends Component {
  static propTypes = {
    marketData: PropTypes.object,
    onUpdateMarketData: PropTypes.func,
    permissions: PropTypes.object,
  };

  state = {
    marketData: {},
  };

  componentDidMount() {
    this.setState({
      marketData: this.props.marketData,
    });
  }

  onCatchError(err) {
    if (err && err.response && err.response.status === 401) {
      axios.clearLogin();
    } else {
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  onSave = () => {
    if (!this.isAllFieldsValid) return;

    this.setState({
      loading: true,
      saved: false,
      error: false,
    });

    axios
      .put(`/api/admin/markets/${this.state.marketData.id}`, this.getPutData())
      .then(() => {
        scrollToTop();
        this.setState({
          loading: false,
          saved: true,
          error: false,
        });
        this.props.onUpdateMarketData();
      })
      .catch((err) => this.onCatchError(err));
  };

  onChange = (e, props) => {
    const target = e.target;

    this.setState(
      {
        marketData: {
          ...this.state.marketData,
          [props.name]: e.target.value,
        },
      },
      () => {
        this.validateFields(target);
      },
    );
  };

  getPutData() {
    return {
      name: trimValue(this.state.marketData.name),
      h2_heading: trimValue(this.state.marketData.h2_heading),
      longdesc: trimValue(this.state.marketData.longdesc),
      offer_title: trimValue(this.state.marketData.offer_title),
      offer_details: trimValue(this.state.marketData.offer_details),
      shortdesc: trimValue(this.state.marketData.shortdesc),
      disclaimer: trimValue(this.state.marketData.disclaimer),
      banner_url: trimValue(this.state.marketData.banner_url),
      thumbnail_url: trimValue(this.state.marketData.thumbnail_url),
      lat: trimValue(this.state.marketData.lat),
      lng: trimValue(this.state.marketData.lng),
      phone_general: trimValue(this.state.marketData.phone_general),
      phone_leasing: trimValue(this.state.marketData.phone_leasing),
      photo_special_instructions: trimValue(this.state.marketData.photo_special_instructions),
      meta_title: trimValue(this.state.marketData.meta_title),
      meta_description: trimValue(this.state.marketData.meta_description),
      headline: trimValue(this.state.marketData.headline),
      enable_notice: this.state.marketData.enable_notice === 'Yes',
      notice_show_icon: this.state.marketData.notice_show_icon === 'Yes',
      notice_content: trimValue(this.state.marketData.notice_content),
      pls_market_content: trimValue(this.state.marketData.pls_market_content),
      ils_market_content: trimValue(this.state.marketData.ils_market_content),
      ils_disclaimer: trimValue(this.state.marketData.ils_disclaimer),
    };
  }

  get isAllFieldsValid() {
    return !Object.keys(this.state).some((curVal) => {
      return curVal.includes('_error') && this.state[curVal];
    });
  }

  validateFields = (target) => {
    const requiredFields = ['name', 'lat', 'lng'],
      fieldName = target.name;

    if (requiredFields.includes(fieldName)) {
      if (!this.state.marketData[fieldName]) {
        this.setState({
          [`${fieldName}_error`]: true,
        });
      } else {
        this.setState({
          [`${fieldName}_error`]: false,
        });
      }
    }
  };

  renderMessage() {
    if (this.state.error) {
      return (
        <Message
          negative
          onDismiss={() => {
            this.setState({ error: false });
          }}
        >
          <Message.Header>Error</Message.Header>
          <p>An error has occurred while saving the Photo Details.</p>
        </Message>
      );
    } else if (this.state.saved) {
      return (
        <Message
          positive
          onDismiss={() => {
            this.setState({ saved: false });
          }}
        >
          <Message.Header>Success</Message.Header>
          <p>Your changes have been saved.</p>
        </Message>
      );
    }

    return null;
  }

  render() {
    const { photo_special_instructions } = this.state.marketData;

    const {
      permissions: { canEditMarketDetails },
    } = this.props;

    return (
      <Form onSubmit={this.onSave}>
        {this.renderMessage()}
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>Photo Order Details</h2>
          </Box>
        </Flex>
        <Box p={10}>
          <label>
            <strong>Special Instructions</strong>
          </label>
          <TextArea
            name="photo_special_instructions"
            disabled={!canEditMarketDetails}
            value={photo_special_instructions}
            onChange={this.onChange}
          />
        </Box>
        {canEditMarketDetails && (
          <Flex justifyContent="flex-end" css="margin-bottom: 40px; margin-top: 20px; margin-right: 10%;">
            <Box>
              <Button type="submit" primary>
                Save
              </Button>
            </Box>
          </Flex>
        )}
      </Form>
    );
  }
}

export default withPermissions(PhotoDetails);
