import React from 'react';
import { Flex } from 'grid-styled';
import { withRouter, Route, Switch } from 'react-router-dom';
import PropertyUpdatesReport from '../../routes/Reports/PropertyUpdatesReport';

const reports = () => (
  <Flex flexDirection="column">
    <Switch>
      <Route exact path={`/reports/property`} render={() => <PropertyUpdatesReport />} />
    </Switch>
  </Flex>
);

export default withRouter(reports);
