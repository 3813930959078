export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#71d8ab',
  borderStyle: 'dashed',
  backgroundColor: '#edf4eb',
  outline: 'none',
  minHeight: 200,
  transition: 'border .24s ease-in-out',
};

export const focusedStyle = {
  borderColor: '#71d8ab',
};

export const acceptStyle = {
  borderColor: '#009412',
};

export const rejectStyle = {
  borderColor: '#ff1744',
  backgroundColor: '#ff174410',
};
