import moment from 'moment';
import axios from 'utils/axios';

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export class API {
  static list() {
    return axios.get('/api/admin/specials');
  }

  static get(id, withProperties = false) {
    return axios.get(`/api/admin/specials/${id}?withProperties=${withProperties}`);
  }

  static create(values) {
    return axios.post(`/api/admin/specials`, values);
  }

  static edit(id, values) {
    return axios.put(`/api/admin/specials/${id}`, values);
  }

  static delete(id) {
    return axios.delete(`/api/admin/specials/${id}`);
  }

  static deleteSpecialProperty(id) {
    return axios.delete(`/api/admin/specials/special_property/${id}`);
  }
}

/**
 * Format the month number to a month name.
 * @param {number} num the number of the month, 1-based
 */
export const displayMonthFromConcessionMonth = (num) => {
  return moment(num, 'M').format('MMMM');
};

/**
 * Format display of date for specials.
 * @param {Date} date
 * @returns {string} in the format MM/DD/YYYY
 */
export const formatDate = (date) => {
  return moment.utc(date).format('MM/DD/YYYY');
};

/**
 * Get a value that can be used as the default "end_at" date.
 * Will be the last day of the current month.
 * @returns {string} in the format MM/DD/YYYY
 */
export const getDefaultEndAtDate = () => {
  return moment().endOf('month').format('MM/DD/YYYY');
};
/**
 * Get a value that can be used as the default "concession_month"
 * Will be the month number (1-12) of the next month.
 * @returns {string}
 */
export const getDefaultConcessionMonth = () => {
  return moment().add(1, 'months').format('M');
};
