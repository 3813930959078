import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withEmitter = (WrappedComponent) => {
  return class EmitterComponent extends Component {
    static contextTypes = {
      emitter: PropTypes.object.isRequired,
    };

    render() {
      const { emitter } = this.context;
      return <WrappedComponent {...this.props} emitter={emitter} />;
    }
  };
};

export default withEmitter;
