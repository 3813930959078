import React from 'react';
import Card from '../../components/Card';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import { Tab } from 'semantic-ui-react';
import { findIndex } from 'lodash';
import { Route, Redirect } from 'react-router-dom';
import PropertyUpdatesReport from '../../containers/reports/PropertyUpdatesReport';
import OnTheMarketingReport from '././OnTheMarketReport';
import PreMarketingReport from './PreMarketingReport';
import PhotoOrdersReport from './PhotoOrdersReport';
import PhotoIngestionReport from './PhotoIngestionReport';
import PropTypes from 'prop-types';
import { withPermissions } from '../../components/HoC';

class Reports extends React.Component {
  static propTypes = {
    permissions: PropTypes.object,
  };

  state = {
    hasPermission: true,
  };

  renderTabs = (route) => {
    const panes = [
      {
        menuItem: 'Property Updates',
        tabSlug: 'property',
        render: () => (
          <Tab.Pane key="property">
            <PropertyUpdatesReport />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Pre-marketing Report',
        tabSlug: 'pre-marketing',
        render: () => (
          <Tab.Pane key="pre-marketing">
            <PreMarketingReport />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'On The Market Properties Report',
        tabSlug: 'on-the-market',
        render: () => (
          <Tab.Pane key="on-the-market">
            <OnTheMarketingReport />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Photo Orders Report',
        tabSlug: 'photo-orders-report',
        render: () => (
          <Tab.Pane key="photo-orders-report">
            <PhotoOrdersReport />
          </Tab.Pane>
        ),
      },
    ];

    if (process.env.REACT_APP_ENABLE_PHOTO_INGESTION_REPORT === 'true') {
      panes.push({
        menuItem: 'Photo Ingestion Report',
        tabSlug: 'photo-ingestion-report',
        render: () => (
          <Tab.Pane key="photo-ingestion-report">
            <PhotoIngestionReport />
          </Tab.Pane>
        ),
      });
    }

    const params = route.match.params;
    const activeIndex = params.tabSlug ? findIndex(panes, { tabSlug: params.tabSlug }) : 0;
    return (
      <Tab
        activeIndex={activeIndex}
        onTabChange={(e, data) => {
          route.history.push(`/reports/${data.panes[data.activeIndex].tabSlug}`);
        }}
        panes={panes}
      />
    );
  };

  render() {
    const {
      permissions: { canViewReports },
    } = this.props;

    return !canViewReports ? (
      <Redirect to="/properties" />
    ) : (
      <View>
        <ViewHeader title={'Reports'} />
        <ViewContent>
          <Card>
            <Route path="/reports/:tabSlug?" render={this.renderTabs} />
          </Card>
        </ViewContent>
      </View>
    );
  }
}

export default withPermissions(Reports);
