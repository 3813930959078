import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ToSubmitTables = ({ data, validationErrors, processedData, RenderTable }) => {
  return (
    <Fragment>
      {data.columns && (
        <Fragment>
          {validationErrors.columns && (
            <RenderTable
              columns={validationErrors.columns}
              title={'PROPERTIES WITH ISSUES THAT WILL NOT BE SUBMITTED:'}
              data={validationErrors.data}
            />
          )}
          <br />
          {processedData.columns && (
            <RenderTable
              columns={processedData.columns}
              title={'Properties able to be submitted:'}
              data={processedData.data}
            />
          )}
          <br />
          <RenderTable columns={data.columns} title={'All properties in file:'} data={data.data} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ToSubmitTables;

ToSubmitTables.propTypes = {
  data: PropTypes.object,
  validationErrors: PropTypes.object,
  processedData: PropTypes.object,
  RenderTable: PropTypes.func,
};
