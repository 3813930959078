import React from 'react';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import HidePhotos from 'components/HidePhotos';

const HidePhotosUI = () => {
  return (
    <View>
      <ViewHeader title="Hide Photos" subtitle="Upload a csv file to get property photos to hide"></ViewHeader>

      <ViewContent>
        <HidePhotos />
      </ViewContent>
    </View>
  );
};

export default HidePhotosUI;
