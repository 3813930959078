import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Flex, Box } from 'grid-styled';
import questionIcon from '../../icons/inactive.svg';
import backIcon from '../../icons/backArrow.svg';
import PropTypes from 'prop-types';
import Autosuggest from '../../components/Autosuggest';
import { rowStyle, widerRowStyle, titleStyle, MAX_NUM_PROPERTIES } from './config';
import { SortableList } from './components';

import withFeatureBlockResources from './AddEditFeatureBlock';
import AddEditFeatureBlockForm from './AddEditFeatureBlockForm';
import QueryBuilder, { QueryBuilderContext } from '../../components/QueryBuilder';
import { checkQueryConditions } from 'utils/index';

import { Checkbox, Popup, Icon, Message } from 'semantic-ui-react';
import axios from 'utils/axios';

class EditFeaturedBlock extends Component {
  static propTypes = {
    match: PropTypes.object,
    marketId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    params: PropTypes.object,
    backLink: PropTypes.string,

    // inherited from withFeatureBlockResources
    featureBlockState: PropTypes.object,
    onChange: PropTypes.func,
    onImageUpload: PropTypes.func,
    onPropertySelect: PropTypes.func,
    onBlur: PropTypes.func,
    onChangeOrder: PropTypes.func,
    deleteFeatureBlock: PropTypes.func,
    addProperty: PropTypes.func,
    updateFeatureBlock: PropTypes.func,
    getMarketFeatureBlock: PropTypes.func,
    onPropertyAdditionCheckboxSelection: PropTypes.func,
    getQueryConfig: PropTypes.func,
    addPropertyToManualPropertyList: PropTypes.func,
    setSuccessRedirectState: PropTypes.func,
    setSubmitFailureState: PropTypes.func,
    setupAndReturnPayload: PropTypes.func,
    updateQueryState: PropTypes.func,
    updateFeatureBlockState: PropTypes.func,
    isValid: PropTypes.func,
    removeImageFromState: PropTypes.func,
  };

  state = {
    isSaving: false,
    error: false,
  };

  async componentDidMount() {
    const { featuredId } = this.props.match.params;

    await this.props.getMarketFeatureBlock({ featuredId });
    await this.props.getQueryConfig();
  }

  onSubmitHandler = (query, blockId) => {
    const { setSuccessRedirectState, setSubmitFailureState } = this.props;
    if (!this.props.isValid()) return;

    let queryChecker = 0;
    if (query.criteria) {
      queryChecker = checkQueryConditions(query.criteria, []);
    }

    if (queryChecker > 0) {
      this.setState({
        error:
          'At least 2 query components are required to use "OR" as a condition. Please add another group or condition or use "AND" instead of "OR".',
      });
      return;
    }
    const endpoint = `/api/admin/featured/${blockId}`;
    const payload = this.props.setupAndReturnPayload(query);

    this.setState({ isSaving: true });

    if (payload) {
      axios
        .put(endpoint, payload)
        .then(setSuccessRedirectState)
        .catch((err) => {
          this.setState({ isSaving: false });
          setSubmitFailureState(err);
        });
    }
  };

  render() {
    const {
      backLink,
      marketId,
      featureBlockState,
      onChange,
      onImageUpload,
      onPropertySelect,
      onChangeOrder,
      deleteFeatureBlock,
      addProperty,
      onPropertyAdditionCheckboxSelection,
      updateFeatureBlockState,
      updateQueryState,
      removeImageFromState,
    } = this.props;

    const { blockData, query, blockId } = featureBlockState;

    if (featureBlockState.successRedirect) {
      return <Redirect to={`/market/${backLink}`} />;
    }

    return (
      <QueryBuilderContext.Provider
        value={{
          query,
          isQueryValid: featureBlockState.isQueryValid,
          updateQueryState,
          updateFeatureBlockState,
        }}
      >
        <AddEditFeatureBlockForm
          onSubmit={(queryState) =>
            this.onSubmitHandler(
              {
                ...queryState,
                manually_added_properties: query.manually_added_properties,
                excluded_properties: query.excluded_properties,
              },
              blockId,
            )
          }
          isSaving={this.state.isSaving}
          onChange={onChange}
          backLink={backLink}
          notificationMsg={featureBlockState.notification.msg}
          notificationType={featureBlockState.notification.type}
          backIcon={backIcon}
          headline="Edit Feature Block"
          maxNumPropertiesAllowed={MAX_NUM_PROPERTIES}
          title_error={featureBlockState.title_error}
          title={blockData.title}
          view_type={blockData.view_type}
          description_error={featureBlockState.description_error}
          description={blockData.description}
          long_description_error={featureBlockState.long_description_error}
          long_description={blockData.long_description}
          previewImg={featureBlockState.previewImg}
          is_visible={blockData.is_visible}
          show_promo_button={blockData.show_promo_button}
          no_lifestyle_photo={blockData.no_lifestyle_photo}
          questionIcon={questionIcon}
          onImageUpload={onImageUpload}
          marketId={this.props.marketId}
          query={query}
          removeImageFromState={removeImageFromState}
          displayQueryBuilder={featureBlockState.displayQueryBuilder}
          displayManualPropertyAdditions={featureBlockState.displayManualPropertyAdditions}
        >
          <Flex>
            <Box width={1}>
              <Box pl="2%" pb={20} pt={20}>
                <strong>Properties</strong>
                <p>
                  Add properties by manually selecting individual properties by address or property code, and/or by
                  creating a query to automatically populate properties based on filtered criteria.
                </p>
              </Box>
              <Box pl="2%" pb={20} width={1}>
                <Flex>
                  <Box>
                    <Checkbox
                      name={'displayManualPropertyAdditions'}
                      onChange={onPropertyAdditionCheckboxSelection}
                      checked={featureBlockState.displayManualPropertyAdditions}
                    />
                  </Box>
                  <Box pl={'10px'} pr={'5px'}>
                    <span>Manually Add Individual Properties</span>
                  </Box>
                  <Box>
                    <Popup
                      wide={'very'}
                      position="right center"
                      trigger={<Icon color={'green'} name={'question circle'} />}
                    >
                      Manually add a property to control the order in which it is shown. If combining manually added
                      properties with a query, all manually selected properties that are syndicated will display within
                      the Featured Block before any properties added through a query
                    </Popup>
                  </Box>
                </Flex>
              </Box>
              <Box pl="2%" pb={20} width={1}>
                {featureBlockState.displayManualPropertyAdditions && (
                  <div className="ReactTable -striped -highlight" style={{ margin: '15px 0' }}>
                    {featureBlockState.searching && (
                      <Autosuggest onSuggestionSelected={onPropertySelect} marketId={marketId} />
                    )}
                    <div className="rt-table">
                      <div className="rt-thead -header" style={{ minWidth: '900px' }}>
                        <div className="rt-tr">
                          <div className="rt-th" style={rowStyle}>
                            <div>Order</div>
                          </div>
                          <div className="rt-th" style={widerRowStyle}>
                            <div>Is Syndicated</div>
                          </div>
                          <div className="rt-th" style={titleStyle}>
                            <div>Street Address</div>
                          </div>
                          <div className="rt-th" style={widerRowStyle}>
                            <div>City</div>
                          </div>
                          <div className="rt-th" style={rowStyle}>
                            <div>State</div>
                          </div>
                          <div className="rt-th" style={rowStyle}>
                            <div>ZIP Code</div>
                          </div>
                          <div className="rt-th" style={widerRowStyle}>
                            <div>Property Code</div>
                          </div>
                          <div className="rt-th" style={rowStyle}>
                            <div>Remove</div>
                          </div>
                        </div>
                      </div>
                      <SortableList
                        rows={featureBlockState.rows}
                        onSortEnd={onChangeOrder}
                        helperClass={'rt-tr-group--sortable'}
                        onDelete={deleteFeatureBlock}
                        isEditing
                        useDragHandle
                      />
                      <Box pt={2} pb={2} pl={10}>
                        {!featureBlockState.searching && featureBlockState.rows.length >= MAX_NUM_PROPERTIES ? (
                          <span>To add a new property, remove a property above.</span>
                        ) : (
                          <strong className="cursor-pointer" style={{ color: 'var(--blue)' }} onClick={addProperty}>
                            + Add property
                          </strong>
                        )}
                      </Box>
                    </div>
                  </div>
                )}
                <Box pb={20} width={1}>
                  <Flex>
                    <Box>
                      <Checkbox
                        data-testid="display-query-builder__checkbox"
                        name={'displayQueryBuilder'}
                        checked={featureBlockState.displayQueryBuilder}
                        onChange={onPropertyAdditionCheckboxSelection}
                      />
                    </Box>
                    <Box pl={'10px'} pr={'5px'}>
                      <span>Automatically Populate Properties with a Query</span>
                    </Box>
                    <Box>
                      <Popup
                        wide={'very'}
                        position="right center"
                        trigger={<Icon color={'green'} name={'question circle'} />}
                      >
                        Add filters to populate a Featured Homes block automatically to ensure all homes are syndicated.
                        Individual properties returned by the query can be excluded or added to the manually added
                        properties so that you can control the order in which they are shown
                      </Popup>
                    </Box>
                  </Flex>
                  {featureBlockState.displayQueryBuilder && featureBlockState.queryConfig && (
                    <div>
                      {featureBlockState.displayQueryBuilder}
                      <QueryBuilder
                        query={featureBlockState.query}
                        queryConfig={featureBlockState.queryConfig}
                        marketId={this.props.marketId}
                        addPropertyToManualPropertyList={this.props.addPropertyToManualPropertyList}
                        updateQueryState={this.props.updateQueryState}
                      />
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          </Flex>
          {this.state.error && (
            <Message negative>
              <Message.Header>Failed to create feed</Message.Header>
              <p>{this.state.error}</p>
            </Message>
          )}
        </AddEditFeatureBlockForm>
      </QueryBuilderContext.Provider>
    );
  }
}

export default withFeatureBlockResources(withRouter(EditFeaturedBlock));
