import React from 'react';
import { createRoot } from 'react-dom/client';
import Routes from './routes';

import 'semantic-ui-css/semantic.min.css';
import 'react-table/react-table.css';
import 'react-select/dist/react-select.css';
import './index.scss';

const rootEl = window.document.getElementById('root');
const root = createRoot(rootEl);
root.render(<Routes />);

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextApp = require('./routes').default;
    root.render(<NextApp />);
  });
}
