import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { Flex } from 'grid-styled';

const RenderTable = ({ data, columns, getTrProps }) => {
  if (!data || !columns) return null;

  return (
    <Flex css="padding-top: 20px;">
      <ReactTable
        data={data}
        textAlign="center"
        columns={columns}
        className="-striped -highlight"
        manual
        minRows={0}
        showPagination={false}
        sortable={false}
        getTrProps={getTrProps}
      />
    </Flex>
  );
};
export default RenderTable;

RenderTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  getTrProps: PropTypes.func,
};
