import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Card from '../../components/Card';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import SpecialsList from './components/List';
import AddSpecial from './components/Add';
import EditSpecial from './components/Edit';
import ViewSpecial from './components/View';

class Specials extends React.Component {
  render() {
    return (
      <View>
        <ViewHeader title={'Leasing Specials'} />
        <ViewContent>
          <Card>
            <Switch>
              <Route exact path={`/leasing-specials/add`} render={() => <AddSpecial />} />
              <Route exact path={`/leasing-specials/view/:specialId`} render={() => <ViewSpecial />} />
              <Route exact path={`/leasing-specials/edit/:specialId`} render={() => <EditSpecial />} />
              <Route render={() => <SpecialsList />} />
            </Switch>
          </Card>
        </ViewContent>
      </View>
    );
  }
}

export default Specials;
