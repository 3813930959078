import React from 'react';
import PropTypes from 'prop-types';

import { Loader, Tab } from 'semantic-ui-react';

import { Route, Redirect } from 'react-router-dom';
import axios from 'utils/axios';

import './MarketEdit.scss';

import Card from '../components/Card';
import View from '../components/View';
import ViewHeader from '../components/ViewHeader';
import ViewContent from '../components/ViewContent';
import { PhotoDetails, FeaturedHomes, GeneralInformation, Syndication, ContactDetails } from '../containers/market';
import { findIndex } from 'lodash';
import { withPermissions } from '../components/HoC';

class MarketEdit extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    permissions: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {
      loading: true,
      marketData: {},
    };
  }

  componentDidMount() {
    if (this.props.match.params.slug) {
      this.updateMarketData();
    }
  }

  updateMarketData = () => {
    axios
      .get(`/api/admin/markets/${this.props.match.params.slug}`)
      .then((res) => {
        this.setState({
          loading: false,
          marketData: {
            ...res.data,
            enable_notice: res.data.enable_notice ? 'Yes' : 'No',
            notice_show_icon: res.data.notice_show_icon ? 'Yes' : 'No',
          },
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          axios.clearLogin();
        }
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  renderTabs = (route) => {
    const {
      location: { pathname },
      permissions: { canEditMarketFeaturedHomes },
    } = this.props;

    const { marketData } = this.state;
    const { slug } = this.props.match.params;

    const panes = [
      {
        menuItem: 'General Info',
        tabSlug: 'general-info',
        render: () => (
          <Tab.Pane>
            <GeneralInformation marketData={marketData} onUpdateMarketData={this.updateMarketData} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Syndication',
        tabSlug: 'syndication',
        render: () => (
          <Tab.Pane>
            <Syndication marketData={marketData} onUpdateMarketData={this.updateMarketData} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Featured Homes',
        tabSlug: 'featured-homes',
        render: () => (
          <Tab.Pane>
            <FeaturedHomes marketData={marketData} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Contact Details',
        tabSlug: 'contact-details',
        render: () => (
          <Tab.Pane>
            <ContactDetails market_id={marketData.id} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Photo Order Details',
        tabSlug: 'photo-details',
        render: () => (
          <Tab.Pane>
            <PhotoDetails marketData={marketData} onUpdateMarketData={this.updateMarketData} />
          </Tab.Pane>
        ),
      },
    ];

    if (!canEditMarketFeaturedHomes) {
      panes.pop();

      if (pathname.includes('featured-homes')) {
        return <Redirect to={`/market/${slug}`} />;
      }
    }

    const params = route.match.params;
    const activeIndex = params.tabSlug ? findIndex(panes, { tabSlug: params.tabSlug }) : 0;
    return (
      <Tab
        activeIndex={activeIndex}
        onTabChange={(e, data) => {
          route.history.push(`/market/${params.slug}/${data.panes[data.activeIndex].tabSlug}`);
        }}
        panes={panes}
      />
    );
  };

  render() {
    const { loading } = this.state;
    const { slug } = this.props.match.params;

    return !loading ? (
      <View>
        <ViewHeader title={slug} subtitle={`Edit info for ${slug} market`} />
        <ViewContent>
          <Card>
            <Route path="/market/:slug/:tabSlug?" render={this.renderTabs} />
          </Card>
        </ViewContent>
      </View>
    ) : (
      <Loader active={true} />
    );
  }
}

export default withPermissions(MarketEdit);
