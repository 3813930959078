import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';

import Row from './Row';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  error: {
    background: theme.palette.error.light,
    border: `2px solid ${theme.palette.error.dark}`,
    color: theme.palette.error.contrastText,
    padding: 10,
  },
  success: {
    background: theme.palette.success.light,
    border: `2px solid ${theme.palette.success.dark}`,
    color: theme.palette.success.contrastText,
    padding: 10,
  },
}));

const List = ({ market_id }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [marketContacts, setMarketContacts] = useState([]);
  const classes = useStyles();

  const getMarketContacts = async () => {
    const { data, status } = await axios
      .get(`/api/admin/order-contacts/${market_id}`)
      .catch((err) => ({ data: [], status: err.status }));

    if (status === 200) {
      setMarketContacts(data);
    }
  };

  useEffect(() => {
    getMarketContacts();
  }, []);

  const onUpdate = async (contact) => {
    const { status } = await axios
      .put('/api/admin/order-contact', contact)
      .catch((err) => ({ data: err, status: err.status }));

    if (status === 200) {
      await getMarketContacts();
      setError('');
      setSuccess(`Sucessfully updated contact - ${contact.first_name} ${contact.last_name}.`);
    } else {
      setSuccess('');
      setError('There was a problem updating this contact.  Please check your input and try again.');
    }
  };
  const onDelete = async (contact) => {
    const { status } = await axios
      .delete(`/api/admin/order-contact/${contact}`)
      .catch((err) => ({ data: err, status: err.status }));

    if (status === 200) {
      getMarketContacts();
      setError('');
      setSuccess(`Sucessfully deleted contact.`);
    } else {
      setSuccess('');
      setError('There was a problem deleting this contact.  Please check your input and try again.');
    }
  };

  const onCreate = async () => {
    if (!firstName && !lastName && !email && !phone) {
      setError('All fields cannot be empty.');
    } else {
      const { status } = await axios
        .post('/api/admin/order-contact', {
          market_id,
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          is_default: isDefault,
        })
        .catch(() => ({ status: 400 }));

      if (status === 200) {
        getMarketContacts();
        setError('');
        setSuccess(`Sucessfully created contact - ${firstName} ${lastName}.`);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setIsDefault(false);
      } else {
        setSuccess('');
        setError('There was a problem creating this contact.  Please check your input and try again.');
      }
    }
  };

  const mappedContacts = marketContacts.map((contact) => {
    return <Row key={contact.id} {...contact} onUpdate={onUpdate} onDelete={onDelete} />;
  });

  return (
    <TableContainer component={Paper}>
      {error ? (
        <Typography className={classes.error}>{error}</Typography>
      ) : success ? (
        <Typography className={classes.success}>{success}</Typography>
      ) : null}
      <Table aria-label="caption table">
        <caption>Checking default and saving will make that row the primary point of contact.</caption>
        <TableHead>
          <TableRow>
            <TableCell align="left">First Name</TableCell>
            <TableCell align="left">Last Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Phone Number</TableCell>
            <TableCell align="left">Default</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mappedContacts}

          <TableRow>
            <TableCell component="th" scope="row">
              <Input fullWidth value={firstName} variant="outlined" onChange={(e) => setFirstName(e.target.value)} />
            </TableCell>
            <TableCell align="left">
              <Input fullWidth value={lastName} variant="outlined" onChange={(e) => setLastName(e.target.value)} />
            </TableCell>
            <TableCell align="left">
              <Input
                fullWidth
                value={email}
                variant="outlined"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </TableCell>
            <TableCell align="left">
              <Input fullWidth value={phone} variant="outlined" onChange={(e) => setPhone(e.target.value)} />
            </TableCell>
            <TableCell align="left">
              <Checkbox
                checked={isDefault}
                onChange={() => setIsDefault(!isDefault)}
                color="primary"
                inputProps={{ 'aria-label': 'is default checkbox' }}
              />
            </TableCell>

            <TableCell align="left">
              <IconButton onClick={onCreate} color="primary">
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
List.propTypes = {
  market_id: PropTypes.string,
};

export default List;
