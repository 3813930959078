import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  FormLabel,
  TextField,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import ValidationError from '../../components/validation/ValidationError';
import { useFormContext } from 'react-hook-form';

const SyndicationStatusChangeModal = (props) => {
  const {
    watch,
    register,
    trigger,
    formState: { errors },
  } = useFormContext({
    mode: 'onBlur',
    revalidateMode: 'onChange',
  });

  const values = watch();

  const saveAndClose = () => {
    trigger().then(() => {
      if (errors.syndication_status_note) {
        return;
      } else {
        props.toggleModal();
      }
    });
  };

  return (
    <Dialog open={props.active} className="order-photos-modal">
      <DialogTitle>Allow Syndication Change</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please describe the reason for changing the syndication status to <b>{values.syndication_status}</b>.
        </DialogContentText>
        <div>
          {errors.syndication_status_note && (
            <ValidationError>{errors.syndication_status_note.message}</ValidationError>
          )}
          <FormLabel>Enter note for future users</FormLabel>
          <TextField
            margin="normal"
            multiline
            fullWidth
            variant="outlined"
            minRows="3"
            name="syndication_status_note"
            {...register('syndication_status_note', {
              required: values.syndication_status === 'Never' && 'Required Field',
            })}
            value={values.syndication_status_note}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleModal} tabIndex={3} className="ui button">
          Cancel
        </Button>
        <Button onClick={saveAndClose} tabIndex={2}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SyndicationStatusChangeModal.propTypes = {
  active: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
};

export default SyndicationStatusChangeModal;
