import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { normalizePermissions } from '../../utils';

const withPermissions = (WrappedComponent) => {
  return class WithPermissions extends Component {
    static contextTypes = {
      userPermissions: PropTypes.array,
    };

    get permissions() {
      const { userPermissions } = this.context;
      const perms = normalizePermissions(userPermissions);
      return perms;
    }

    render() {
      return <WrappedComponent permissions={this.permissions} {...this.props} />;
    }
  };
};

export default withPermissions;
