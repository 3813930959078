import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Box, Flex } from 'grid-styled';
import { Loader } from 'semantic-ui-react';
import Form from './Form';
import Properties from './Properties';
import { API } from '../utils';
import backIcon from '../../../icons/backArrow.svg';

class ViewSpecial extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    special: null,
    isLoading: true,
    notificationMsg: '',
  };

  componentDidMount() {
    API.get(this.id, true).then((r) => {
      this.setState({ special: r.data, isLoading: false });
    });
  }

  get id() {
    return this.props.match.params.specialId;
  }

  renderLoading() {
    return (
      <Box pt="2%" pb="2%" style={{ textAlign: 'center' }}>
        <Box p="2%">
          <Loader active={true} inline="centered" />
        </Box>
        <p>Please wait while we load the special for viewing.</p>
      </Box>
    );
  }

  render() {
    const { special, isLoading } = this.state;
    return (
      <Box pb={100}>
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <Link to={`/leasing-specials`} style={{ color: 'var(--green)' }}>
              <img src={backIcon} alt="" /> Back to All Leasing Specials
            </Link>
          </Box>
        </Flex>
        <Flex>
          <Box pl="2%" pb={20}>
            <h2>Leasing Special Details</h2>
          </Box>
        </Flex>
        {isLoading ? (
          this.renderLoading()
        ) : (
          <Fragment>
            <Form readonly special={special} />
            <Properties special={special} />
          </Fragment>
        )}
      </Box>
    );
  }
}

export default withRouter(ViewSpecial);
