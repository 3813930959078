import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { get } from 'lodash';
import './AlertPopup.scss';
import { AlertPopupQueueContext } from '../../containers/alertPopupQueue/AlertPopupQueueContext';

class AlertPopup extends Component {
  static propTypes = {
    event: PropTypes.string,
    message: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    displayedClassModifier: PropTypes.string,
    hiddenClassModifier: PropTypes.string,
  };

  static contextType = AlertPopupQueueContext;

  state = {
    className: '',
  };

  componentDidMount() {
    this.displayAlertPopup(this.props);
  }

  displayAlertPopup = (options) => {
    const duration = get(options, 'duration', this.baseConfig.duration);
    const className = get(options, 'className', this.baseConfig.baseClassName);
    const displayedClassModifier = get(options, 'displayedClassModifier', this.baseConfig.displayedClassModifier);
    const hiddenClassModifier = get(options, 'hiddenClassModifier', this.baseConfig.hiddenClassModifier);

    this.setState({ className: `${className} ${displayedClassModifier}` });

    setTimeout(() => {
      this.setState({ className: `${className} ${hiddenClassModifier}` });
      this.alert &&
        this.alert.addEventListener(
          'transitionend',
          (event) => {
            if (event.propertyName === 'opacity' && parseFloat(window.getComputedStyle(this.alert).opacity) === 0) {
              this.context.emitter.emit('alert-queue:remove', this.props.id);
            }
          },
          false,
        );
    }, duration);
  };

  baseConfig = {
    type: '',
    message: '',
    baseClassName: 'notification',
    displayedClassModifier: 'notification--displayed',
    hiddenClassModifier: 'notification--hidden',
    duration: 3000,
  };

  render() {
    const { className } = this.state;
    const { type, message } = this.props;
    const { baseClassName } = this.baseConfig;

    return (
      <div data-testid="alert-popup" className={`${baseClassName} ${className}`} ref={(el) => (this.alert = el)}>
        <Alert color={type}>{message}</Alert>
      </div>
    );
  }
}

export default AlertPopup;
