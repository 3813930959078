import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import MUITableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import './style.scss';

const headCells = [
  { id: 'property_id', numeric: true, disabledPadding: true, label: 'Property ID' },
  { id: 'status', numeric: false, disabledPadding: true, label: 'Status' },
  { id: 'services', numeric: false, disabledPadding: true, label: 'Services' },
  { id: 'method_of_access', numeric: false, disabledPadding: true, label: 'Method of Access' },
  { id: 'gate_code', numeric: false, disabledPadding: true, label: 'Gate Code' },
  { id: 'order_notes', numeric: false, disabledPadding: true, label: 'Order Notes' },
];

function TableHead(props) {
  const { order, orderBy, onRequestSort, duplicatedRowCount = 0, numSelected, onSelectAllClick } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MUITableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Tooltip title="Select duplicated orders">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < duplicatedRowCount}
              checked={duplicatedRowCount > 0 && numSelected === duplicatedRowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all duplicated' }}
            />
          </Tooltip>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  );
}

export default TableHead;
