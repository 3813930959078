import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { has } from 'lodash';

import Card from '../components/Card';
import Table from '../components/Table';
import View from '../components/View';
import ViewHeader from '../components/ViewHeader';
import ViewContent from '../components/ViewContent';

import withTableResources from '../containers/TableStateContainer';

import { openInNewTab } from '../utils';

class Markets extends Component {
  static propTypes = {
    // inherited from withRouter
    history: PropTypes.object,

    // inherited from withTableResources
    tableState: PropTypes.object,
    onFetchData: PropTypes.func,
    onFilteredChange: PropTypes.func,
    onSortedChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  render() {
    const { tableState, onFilteredChange, onSortedChange, onPageChange, onPageSizeChange } = this.props;
    return (
      <View>
        <ViewHeader title="Markets" subtitle="List view of all markets" />
        <ViewContent>
          <Card>
            <Table
              data={tableState.data}
              columns={tableState.columns}
              page={tableState.page}
              pages={tableState.pages}
              filtered={tableState.filtered}
              sorted={tableState.sorted}
              loading={tableState.loading}
              onFilteredChange={onFilteredChange}
              onSortedChange={onSortedChange}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              getTdProps={(state, rowInfo, column) => {
                const style = {
                  cursor: 'pointer',
                };
                switch (column.id) {
                  case 'name':
                    column.Cell = () => <Link to={`/market/${rowInfo.original.slug}`}>{rowInfo.original.slug}</Link>;
                    return {};
                  default:
                    return {
                      style,
                      onClick: () => {
                        has(rowInfo, 'original') && openInNewTab(`/market/${rowInfo.original.slug}`);
                      },
                    };
                }
              }}
            />
          </Card>
        </ViewContent>
      </View>
    );
  }
}

export default withTableResources(withRouter(Markets), {
  endpoint: '/api/admin/markets',
});
