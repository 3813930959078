import { camelCase } from 'lodash';
import axios from './axios';

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function openInNewTab(url) {
  const win = window.open(url, '_blank');
  win.focus();
}
// sort user by last name than first name in alphabetical order
export function sortUsers(users) {
  return users.sort((a, b) => {
    const nameA = a.first_name.toLowerCase(),
      nameB = b.first_name.toLowerCase(),
      lastNameA = a.last_name.toLowerCase(),
      lastNameB = b.last_name.toLowerCase();

    if (lastNameA > lastNameB) {
      return 1;
    } else if (lastNameA < lastNameB) {
      return -1;
    }

    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
}

export function getUserPermissions(userId) {
  return axios.get(`/api/admin/users/${userId}/operations`).then((r) => {
    return r.data;
  });
}

export function hasPermission(permissions, permission) {
  if (!permissions) {
    return false;
  }
  return permissions.includes(permission);
}

/**
 * Get an array of camelCase permissions
 * @param {string[]} permissions the SNAKE_CASE permissions names.
 * @returns {object[]} keyed by camelCase permission name
 */
export function normalizePermissions(permissions) {
  if (!Array.isArray(permissions)) {
    return {};
  }

  return permissions.reduce((acc, permission) => {
    const cc = camelCase(permission);
    acc[cc] = hasPermission(permissions, permission);
    return acc;
  }, {});
}

export function toSnakeCase(str) {
  if (!str) {
    return '';
  }

  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');
}

export function snakeToTitleCase(str) {
  if (!str) {
    return '';
  }

  return str
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function checkQueryConditions(query, ordering, prevErrors) {
  let errors = prevErrors || 0;

  if (query.children) {
    if (query.operator === 'OR' && query.children.length <= 1) {
      errors += 1;
    }
    query.children.map((child) => {
      if (child.operator === 'OR' && child.children.length <= 1) {
        errors += 1;
      }
      if (child.children) {
        return checkQueryConditions(child, ordering, errors);
      }
    });
  }

  return errors;
}

export const trimValue = (value) => {
  if (!value) {
    return null;
  }
  if (typeof value === 'string') {
    return value.trim();
  }
  return value;
};
