import { removeIdsFromQueryState } from './queryBuilder';
import { findIndex } from 'lodash';

export const filterAndConcatProperties = (rowData, manuallyAddedPropertyIds, manuallyAddedProperties) => {
  const propertyResults = rowData.filter((p) => manuallyAddedPropertyIds.indexOf(p.id) < 0);
  return manuallyAddedProperties.concat(propertyResults);
};

// eslint-disable-next-line complexity
export const sanitizeAndReturnQueryObj = (query) => {
  const sanitizedObject = {};
  if (query) {
    if (query.criteria) {
      sanitizedObject.criteria = removeIdsFromQueryState([query.criteria])[0];
    }

    if (query.ordering) {
      sanitizedObject.ordering = removeIdsFromQueryState(query.ordering);
    }

    if (query.manually_added_properties) {
      sanitizedObject.manually_added_properties = query.manually_added_properties;
    }

    if (query.excluded_properties) {
      sanitizedObject.excluded_properties = query.excluded_properties;
    }
  }

  return sanitizedObject;
};

export const sortManuallyAddedProperties = (mapArray = [], sortByIdlist = []) => {
  return sortByIdlist.map((id) => {
    return mapArray[findIndex(mapArray, ['id', id])];
  });
};
