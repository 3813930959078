import React, { Component } from 'react';
import MarketReport from './MarketReport';
import withTableResources from '../../containers/TableStateContainer';
import Table from '../../components/Table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ExportToCVSButton from '../../containers/ExportToCSVButton';
import { MAX_REPORT_SIZE_LIMIT } from '../../utils/constants';
import { transformValues } from 'utils/reports';

class OnTheMarketingReport extends Component {
  static propTypes = {
    // inherited from withRouter
    history: PropTypes.object,

    // inherited from withTableResources
    tableState: PropTypes.object,
    onFetchData: PropTypes.func,
    onFilteredChange: PropTypes.func,
    onSortedChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    showLoading: PropTypes.func,
    hideLoading: PropTypes.func,
    onBeforeRequestDataSetup: PropTypes.func,
  };

  async componentDidMount() {
    await this.props.onFetchData();
  }

  render() {
    const {
      tableState,
      onBeforeRequestDataSetup,
      onFilteredChange,
      onSortedChange,
      onPageChange,
      onPageSizeChange,
      onFetchData,
      showLoading,
    } = this.props;

    return (
      <MarketReport
        onFetchData={onFetchData.bind(this)}
        showLoading={showLoading.bind(this)}
        onBeforeRequestDataSetup={onBeforeRequestDataSetup.bind(this)}
        history={this.props.history}
        headline="View properties that are on the market."
        render={(getTdProps) => (
          <div>
            <Table
              data={tableState.data}
              columns={tableState.columns}
              page={tableState.page}
              pages={tableState.pages}
              filtered={tableState.filtered}
              sorted={tableState.sorted}
              loading={tableState.loading}
              onFilteredChange={onFilteredChange}
              onSortedChange={onSortedChange}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              getTdProps={getTdProps}
            />
            {ExportToCVSButton({
              state: tableState,
              maxReportSizeLimit: MAX_REPORT_SIZE_LIMIT,
              reportName: 'on-the-market',
              endpoint: '/api/admin/properties/on-the-market',
              transformValues: transformValues,
            })}
          </div>
        )}
      />
    );
  }
}

export default withTableResources(withRouter(OnTheMarketingReport), {
  endpoint: '/api/admin/properties/on-the-market',
});
