import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../Card';
import { arrayMove } from '@dnd-kit/sortable';
import { Button } from '@material-ui/core';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './md-style';
import UploadPropertiesCSVInput from './UploadPropertiesCSVInput';
import Papa from 'papaparse';
import { toSnakeCase } from 'utils/index';
import Images from 'containers/property/photos/Images';
import { withPermissions } from 'components/HoC';
import { getPhotoTags } from 'api/photo-tags';
import { uploadPhotosForProperties } from 'api/bulk-photo-upload';

const BulkPhotoUploadContent = ({ permissions: { canManagePropertyPhotos } }) => {
  const [, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setLoading(true);

    getPhotoTags()
      .then((res) => setTags(res.data))
      .finally(() => setLoading(false));
  }, []);

  const [disabled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [propertyIds, setPropertyIds] = useState([]);
  const [propertyCount, setPropertyCount] = useState(undefined);

  const importPropertiesCSV = (e) => {
    const file = e.target?.files?.[0];

    if (file !== undefined) {
      setLoading(true);

      Papa.parse(file, {
        header: true,
        transformHeader: (header) => toSnakeCase(header),
        skipEmptyLines: true,
        chunk: async (results) => {
          const ids = results.data.filter((d) => d.property_code !== undefined).map((d) => d.property_code);

          setPropertyIds(ids);
          setPropertyCount(ids.length);
        },
        complete: () => setLoading(false),
      });
    }
  };

  const fileToPropertyImage = (file, order) => {
    const image_url = URL.createObjectURL(file);

    return {
      id: image_url,
      blob: file,
      image_url,
      pro: true,
      tags: [],
      hidden: false,
      type: 'tourStock',
      order,
    };
  };

  const onChangeCheckbox = (checkbox, index, checked) => {
    const image = images[index];

    if (checkbox === 'floorplan') {
      image.type = checked ? 'floorplan' : '';
    } else {
      image[checkbox] = checked;
    }

    setImages(() => [...images]);
  };

  const onChangeOrder = ({ oldIndex, newIndex }) => {
    setImages(() => arrayMove(images, oldIndex, newIndex));
  };

  const onUpdateTag = (e, index) => {
    const image = images[index];

    const tag = tags.find((t) => t.name === e.target.value);

    if (tag !== undefined) {
      image.tags = [tag];

      setImages(() => [...images]);
    }
  };

  const onDeletePhoto = (index) => {
    images.splice(index, 1);

    setImages(() => [...images]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setHasError(false);
    setImages(acceptedFiles.map(fileToPropertyImage));
  }, []);

  const onSubmit = async () => {
    const files = images.map((img) => img.blob);
    const imgs = images.map(({ id, image_url, blob, ...others }) => ({ ...others }));

    const formData = new FormData();

    formData.append('properties', propertyIds.join(','));

    for (const file of files) {
      formData.append('files', file);
    }

    formData.append('images', JSON.stringify(imgs));

    const res = await uploadPhotosForProperties(formData);

    if (res.success === true) {
      setImages([]);
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    disabled,
    onDrop,
    maxFiles: 20,
    multiple: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(hasError ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, hasError],
  );

  return (
    <Card>
      <p>Upload a CSV file with a list of property ids</p>
      <UploadPropertiesCSVInput importCSV={importPropertiesCSV} />
      {propertyCount && <p>Total properties: ({propertyCount})</p>}

      <hr />

      <div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <CloudUpload color="primary" fontSize="large" />
          <span className="drag-drop__text">Drag and drop your image file(s) here</span>
          <span className="drag-drop__text">or</span>
          <Button
            variant="contained"
            color="primary"
            classes={{ containedPrimary: 'drag-drop__button' }}
            disabled={disabled}
          >
            Browse a file
          </Button>
        </div>

        <div>
          {images.length > 0 && (
            <Images
              hasPermission={canManagePropertyPhotos}
              tags={tags}
              items={images}
              disableSelectPhotos={true}
              disableDownload={true}
              disableMakePrimary={true}
              onUpdateTag={onUpdateTag}
              deletePhoto={onDeletePhoto}
              onChangeOrder={onChangeOrder}
              onChangeCheckbox={onChangeCheckbox}
            />
          )}

          <hr />

          <Button
            disabled={!(propertyCount > 0 && images.length > 0)}
            variant="contained"
            color="primary"
            startIcon={<CloudUpload />}
            onClick={onSubmit}
          >
            Upload Images
          </Button>
        </div>
      </div>
    </Card>
  );
};

BulkPhotoUploadContent.propTypes = {
  permissions: PropTypes.object.isRequired,
};

export default withPermissions(BulkPhotoUploadContent);
