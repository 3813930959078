import React, { Component } from 'react';
import Autocomplete from 'react-autosuggest';
import axios from 'utils/axios';
import PropTypes from 'prop-types';
import { assign } from 'lodash';

export default class Autosuggest extends Component {
  static propTypes = {
    onSuggestionSelected: PropTypes.func,
    marketId: PropTypes.string,
    inputProps: PropTypes.object,
  };
  constructor() {
    super();
    this.state = {
      value: '',
      suggestions: [],
    };
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.searchProperties(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSelect = (e, { suggestion }) => {
    const { onSuggestionSelected } = this.props;

    this.setState({
      value: '',
    });

    onSuggestionSelected(suggestion);
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.address_1;
  };

  getSectionSuggestions = (section) => {
    return section.suggestions;
  };

  renderSectionTitle = (section) => {
    return <strong>{section.title}</strong>;
  };

  renderSuggestion = (suggestion) => {
    return (
      <div className="react-autosuggest__item">
        {suggestion._type === 'byAddress' ? suggestion.address_1 : suggestion.property_code}
      </div>
    );
  };

  filterSuggestions(suggestions, type) {
    const filtered = suggestions.filter((suggestion) => suggestion.allow_featured);
    return filtered.map((i) => ({ ...i, _type: type }));
  }

  searchProperties = (e) => {
    return axios.get(`/api/admin/properties/autocomplete?market_id=${this.props.marketId}&q=${e}`).then((r) => {
      const byAddress = this.filterSuggestions(r.data.propertiesByAddress, 'byAddress'),
        byCode = this.filterSuggestions(r.data.propertiesByCode, 'byCode');

      this.setState({
        suggestions: [
          { title: 'Street Addresses', suggestions: byAddress },
          { title: 'Property Codes', suggestions: byCode },
        ],
      });
    });
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = assign(
      {
        placeholder: 'Search by Street Address or Property Code',
        value,
        onChange: this.onChange,
        autoFocus: true,
      },
      this.props.inputProps || {},
    );

    return (
      <div className="featured">
        <Autocomplete
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.onSelect}
          getSectionSuggestions={this.getSectionSuggestions}
          renderSectionTitle={this.renderSectionTitle}
          multiSection={true}
          inputProps={inputProps}
        />
      </div>
    );
  }
}
