import React from 'react';
import { Box } from 'grid-styled';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';

const SyndicationStatusNote = () => {
  const { watch } = useFormContext({
    mode: 'onBlur',
    revalidateMode: 'onChange',
  });

  const values = watch();
  return (
    <div>
      {values.syndication_status === 'Never' && values.syndication_status_note && (
        <Box mt={10} width={1 / 3}>
          <div className="ui visible message">
            {values.syndicationStatusPreviousEntry && (
              <React.Fragment>
                Changed to Never on {moment(values.syndicationStatusPreviousEntry.created_at).format('MM/DD/YYYY')}
                <br />
              </React.Fragment>
            )}
            {values.syndicationStatusPreviousEntry &&
              values.syndicationStatusPreviousEntry.first_name &&
              values.syndicationStatusPreviousEntry.last_name && (
                <React.Fragment>
                  Change by: {values.syndicationStatusPreviousEntry.first_name}{' '}
                  {values.syndicationStatusPreviousEntry.last_name}
                  <br />
                </React.Fragment>
              )}
            {values.syndication_status_note && (
              <React.Fragment>
                <span>Message: {values.syndication_status_note}</span>
              </React.Fragment>
            )}
          </div>
        </Box>
      )}
    </div>
  );
};

export default SyndicationStatusNote;
