import React, { PureComponent } from 'react';
import { Dropdown, Button, Input, Menu, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Flex } from 'grid-styled';
import QueryBuilderHelper from 'utils/queryBuilderHelper';

import './Condition.scss';

class Condition extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    item: PropTypes.object,
    parent: PropTypes.object,
    queryConfig: PropTypes.object,
    removeQueryItem: PropTypes.func,
    updateQueryCondition: PropTypes.func,
    isQueryValid: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    item: { field: 'City', parent: {} },
    parent: { field: 'City', parent: {} },
    removeQueryItem: () => {},
  };

  onChangeHandler = ({ value, type }) => {
    const { updateQueryCondition, id, item, parent } = this.props;

    if (type === 'CRITERIA_TYPE') {
      item.field = value;

      const criteriaType = this._helperInstance.getCriteriaByValue(value);

      if (criteriaType && criteriaType.type === 'list') {
        const mappedCriteriaValues = this._helperInstance.mapCriteriaValuesForDropdowns(criteriaType);

        item.value = mappedCriteriaValues[0].value;
      } else {
        item.value = '';
      }

      item.data_type = criteriaType.data_type;

      if (['available', 'est_rehab_complete_date'].includes(criteriaType.name)) {
        item.operator = this._helperInstance.getOperatorsByDefinition(criteriaType.operators)[0].value;
      } else {
        item.operator = this._helperInstance.getOperatorTypesByGroup(criteriaType.operators)[0].value;
      }
    } else if (type === 'CRITERIA_OPERATOR') {
      item.operator = value;
    } else {
      item.value = value;
    }

    updateQueryCondition({ id, parent, newValue: item });
  };

  _helperInstance = new QueryBuilderHelper(this.props.queryConfig);

  render() {
    const { item, removeQueryItem, id, parent, isQueryValid } = this.props;
    const defaultCriteriaType = this._helperInstance.getCriteriaByValue(item.field);
    // check if the operator is a group
    let defaultOperatorValueArray = defaultCriteriaType
      ? this._helperInstance.getOperatorTypesByGroup(defaultCriteriaType.operators[0])
      : [];

    // check if using an operator definition
    if (defaultCriteriaType && defaultOperatorValueArray.length === 0) {
      defaultOperatorValueArray = this._helperInstance.getOperatorsByDefinition(defaultCriteriaType.operators);
    }

    const getMappedCriteriaTypes = this._helperInstance.getMappedCriteriaTypes();

    let mappedCriteriaValues;
    let errorMessage = '';

    if (defaultCriteriaType && defaultCriteriaType.type === 'list') {
      mappedCriteriaValues = this._helperInstance.mapCriteriaValuesForDropdowns(defaultCriteriaType);
    } else {
      const inputValidationObject = this._helperInstance.getConditionValidationObject(item.field);

      const { description } = inputValidationObject;
      errorMessage = description;
    }

    return (
      <div className={'condition'} id={id}>
        <Flex>
          <Menu>
            <Dropdown
              value={item.field}
              className={'condition__criteria-type'}
              options={getMappedCriteriaTypes}
              onChange={(e, data) =>
                this.onChangeHandler({
                  value: data.value,
                  type: 'CRITERIA_TYPE',
                })
              }
              item
              scrolling
              fluid
              basic
            />
          </Menu>
          <Menu>
            <Dropdown
              value={item.operator}
              options={defaultOperatorValueArray}
              className={'condition__comparison-operator'}
              onChange={(e, data) =>
                this.onChangeHandler({
                  value: data.value,
                  type: 'CRITERIA_OPERATOR',
                })
              }
              item
              scrolling
            />
          </Menu>
          {defaultCriteriaType && defaultCriteriaType.type === 'list' ? (
            <Menu>
              <Dropdown
                value={item.value || mappedCriteriaValues[0].value}
                options={mappedCriteriaValues}
                className={'condition__criteria-value'}
                onChange={(e, data) =>
                  this.onChangeHandler({
                    value: data.value,
                    type: 'CRITERIA_VALUE',
                  })
                }
                item
                scrolling
                fluid
                basic
              />
            </Menu>
          ) : (
            <Input
              value={item.value}
              className={'condition__criteria-value'}
              onChange={(e, data) =>
                this.onChangeHandler({
                  value: data.value,
                  type: 'CRITERIA_VALUE',
                  criteria: item.field,
                })
              }
            />
          )}
          <Button
            color={'red'}
            className={'condition__remove-condition'}
            icon
            onClick={() => removeQueryItem({ parent, id })}
          >
            <Icon name={'minus circle'} />
          </Button>
          <div className={'condition__error-message'}>
            {!isQueryValid && !this._helperInstance.hasValidCondition(item) && errorMessage}
          </div>
        </Flex>
      </div>
    );
  }
}

export default Condition;
