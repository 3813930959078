import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import { Loader, Button } from 'semantic-ui-react';
import { Flex, Box } from 'grid-styled';
import { isEmpty } from 'lodash';

class DownloadReportModal extends Component {
  static propTypes = {
    getDataFunctionCall: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    filename: PropTypes.string,
    isActive: PropTypes.bool,
  };

  state = {
    isLoading: this.props.isActive,
    data: [],
    headers: [],
    filename: '',
    message: '',
  };

  componentDidMount() {
    this.props.getDataFunctionCall().then((res) => {
      this.setStateWithModal(res);
    });
  }

  setStateWithModal = (res) => {
    this.setState({
      isLoading: false,
      data: res.rowData,
      headers: res.headers,
      filename: res.filename,
      message: res.message,
    });
  };

  render() {
    const { isLoading, data, headers, filename, message } = this.state;

    return (
      <Modal isOpen={this.props.isActive}>
        <ModalHeader>
          {isLoading
            ? 'Report is downloading'
            : isEmpty(message)
              ? 'Report is ready to download and view'
              : 'Unable to generate report'}
        </ModalHeader>
        <ModalBody>
          <Flex>
            {isLoading ? (
              <Loader active={true} inline="centered" content="Downloading CSV File..." />
            ) : (
              <Box width={1}>
                <span>{isEmpty(message) ? `Filename: ${filename}` : message}</span>
              </Box>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          {isLoading || !isEmpty(message) ? (
            <Button onClick={this.props.toggleModal}>Cancel</Button>
          ) : (
            <Flex>
              <Box mr={5}>
                <Button onClick={this.props.toggleModal}>Cancel</Button>
              </Box>
              <Box onClick={this.props.toggleModal}>
                <CSVLink
                  className="ui primary button export"
                  data={data}
                  headers={headers}
                  filename={filename}
                  target="_blank"
                >
                  Download Report
                </CSVLink>
              </Box>
            </Flex>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

export default DownloadReportModal;
