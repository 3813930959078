import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'grid-styled';
import { Button, Checkbox, Form, Input } from 'semantic-ui-react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { arrayMove } from '@dnd-kit/sortable';
import PhotoDropzone from './PhotoDropzone';
import axios from 'utils/axios';
import OrderPhotosModal from '../../../components/order-photos-modal';
import Images from './Images';
import InlineMessage from '../../../components/InlineMessage';
import { withPermissions } from '../../../components/HoC';
import fileSaver from 'utils/filesaver';
import {
  resortItems,
  transformPhotoItems,
  getTagByName,
  moveUnHiddenPhotoToEndOfNonHiddenList,
  moveHiddenPhotoToEnd,
} from 'utils/photos';

import './Photos.scss';

class Photos extends Component {
  static propTypes = {
    property: PropTypes.object,
    permissions: PropTypes.object,
    propertyId: PropTypes.string,
    getProperty: PropTypes.func,
    setRecord: PropTypes.func,
  };

  static contextTypes = {
    emitter: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploadModal: false,
      loading: false,
      active: false,
      uploading: false,
      items: [],
      notification: {},
      tags: [],
      progress: 0,
      has_pro_photos:
        this.props.property.has_all_pro_photos || this.props.property.orderStatus === 'delivered' ? 'yes' : 'no',
      has_tour: this.props.property.has_tour_link ? 'yes' : 'no',
      has_floorplan: this.props.property.has_floorplan ? 'yes' : 'no',
      virtual_tour_link: '',
      tour_id: '',
      photo_special_instructions: this.props.property.photo_special_instructions,
      tours: [],
      selectedPhotos: [],
      previouslySelectedId: null,
    };
  }

  componentDidMount() {
    this.onFetchTags();
    this.setPhotos();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.property.has_tour !== this.props.property.has_tour) {
      this.setPhotos();
    }
  }

  onChangeOrder = ({ oldIndex, newIndex }) => {
    let newItems = arrayMove(this.state.items, oldIndex, newIndex);
    newItems = newItems.map((e, i) => {
      e.primary = i === 0;
      return e;
    });
    this.setState({
      items: newItems,
    });
    const payload = {
      photos: newItems.map((e, i) => {
        return {
          picture_id: e.id,
          property_id: e.property_id,
          order: i,
          primary: e.primary,
        };
      }),
      property_update_action: 'REARRANGE_PHOTOS',
    };
    this.onPushUpdate({ payload }).then((res) => {
      if (res && res.data.success) {
        this.context.emitter.emit('alert-queue:add', {
          type: 'success',
          message: 'Successfully updated photos location',
        });
      }
    });
  };

  onUpdateTag = (e, idx) => {
    const collection = this.state.items;
    const tags = [e.target.value];
    const { id, property_id } = this.state.items[idx];
    collection[idx].tags[0] = getTagByName(e.target.value, this.state.tags);
    this.setState({ items: collection });
    const payload = { id, property_id, tags };
    this.onPushUpdate({ payload, id }).then((res) => {
      if (tags !== '' && res && res.data.success) {
        this.context.emitter.emit('alert-queue:add', {
          type: 'success',
          message: `${tags} tag has been applied`,
        });
      } else {
        this.context.emitter.emit('alert-queue:add', this.defaultErrorNotification);
      }
    });
  };

  onChangeCheckbox = (type, itemIndex) => {
    const items = this.state.items;
    const element = items[itemIndex];
    const newValue = !element[type];
    let collection = items;
    const notification = {
      type: 'success',
    };
    if (type === 'floorplan') {
      if (element.type !== 'floorplan') {
        element.type = 'floorplan';
      } else if (element.type === 'floorplan') {
        element.type = null;
      }
    } else {
      element[type] = newValue;
    }

    if (type === 'hidden') {
      if (newValue) {
        collection = moveHiddenPhotoToEnd({
          photoList: items,
          hiddenPhotoIndex: itemIndex,
        });
        notification.message = 'Photo has been sucessfully updated';
      } else {
        collection = moveUnHiddenPhotoToEndOfNonHiddenList({
          photoList: items,
          nonHiddenPhotoIndex: itemIndex,
        });
        notification.message = 'Photo has been sucessfully updated';
      }
    } else if (type === 'floorplan') {
      notification.message = 'Photo type has been updated';
    } else {
      notification.message = 'Professional photo has been updated';
    }

    const proPhotos = items.filter((photo) => photo.pro);

    this.setState({
      items: collection,
      has_pro_photos: this.props.property.pro_photos_count !== '0' ? 'yes' : 'no',
      pro_photos_count: proPhotos,
    });

    const payload = {
      picture_id: element.id,
      property_id: element.property_id,
      hidden: element.hidden,
      pro: element.pro,
      type: element.type,
    };

    this.onPushUpdate({ payload, id: element.id }).then((res) => {
      if (res && res.data.success) {
        this.context.emitter.emit('alert-queue:add', notification);
      } else {
        this.context.emitter.emit('alert-queue:add', this.defaultErrorNotification);
      }
    });
  };
  onDropImages = (acceptedFiles) => {
    this.setState({ uploading: true });
    const fileUploads = acceptedFiles.map((file, i) => {
      const formData = new FormData();
      // If uploading photos for first time, set the first file uploaded to primary
      if (this.state.items.length === 0) {
        if (i === 0) {
          formData.append('primary', 'true');
        }
      }
      formData.append('file', file, file.name);
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({ progress });
        },
      };

      return axios
        .post(`/api/admin/properties/${this.props.property.id}/pictures`, formData, config)
        .then(() => {
          this.context.emitter.emit('alert-queue:add', {
            type: 'success',
            message: 'Successfully updated photos index',
          });
        })
        .catch((err) => this.onCatchError(err));
    });

    Promise.all(fileUploads).then(() => {
      this.setState({ uploading: false, uploadModal: false });
      this.onForceFetch();
    });
  };

  onDownloadPhoto = async (idx) => {
    const { id, image_url } = this.state.items[idx];
    await fileSaver(image_url, `${id}.jpg`);
  };
  onDeletePhoto = (idx) => {
    const { id, property_id } = this.state.items[idx];
    const payload = { id, property_id };
    const boutToDoIt = window.confirm('Are you sure you want to delete this photo?');
    if (boutToDoIt) {
      axios
        .delete(`/api/admin/properties/${property_id}/pictures/${id}`, {
          params: payload,
        })
        .then((res) => {
          if (res && res.data.success) {
            this.onForceFetch();
            this.context.emitter.emit('alert-queue:add', {
              type: 'success',
              message: 'Photo successfully removed',
            });
          }
        })
        .catch((err) => this.onCatchError(err));
    }
  };

  onDeleteSelected = () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${this.state.selectedPhotos.length} ${
        this.state.selectedPhotos.length > 1 ? `photos` : `photo`
      } of this property?`,
    );
    if (!confirmDelete) {
      return;
    }
    const promises = [];
    this.state.selectedPhotos.forEach((item) => {
      return promises.push(
        axios.delete(`/api/admin/properties/${item.property_id}/pictures/${item.id}`, {
          params: { id: item.id, property_id: item.property_id },
        }),
      );
    });

    return Promise.all(promises)
      .then(() => {
        this.onForceFetch();
        this.context.emitter.emit('alert-queue:add', {
          type: 'success',
          message: 'Photos successfully removed',
        });
        this.setState({ selectedPhotos: [] });
      })
      .catch((err) => this.onCatchError(err));
  };

  deleteTour = () => {
    const id = this.state.tours?.[0]?.id;
    const property_id = this.props.property.id;
    const payload = { id, property_id };
    const boutToDoIt = window.confirm('Are you sure you want to delete this photo?');
    if (boutToDoIt) {
      axios
        .delete(`/api/admin/properties/${property_id}/pictures/deleteTour`, {
          params: payload,
        })
        .then((res) => {
          if (res && res.data.success) {
            this.onForceFetch();
            this.context.emitter.emit('alert-queue:add', {
              type: 'success',
              message: 'Tour successfully removed',
            });
          }
        })
        .catch((err) => this.onCatchError(err));
    }
    window.location.reload();
  };

  onPushUpdate = ({ payload, endpoint = 'pictures', id = '' }) => {
    return axios
      .put(`/api/admin/properties/${this.props.property.id}/${endpoint}${id ? `/${id}` : ''}`, payload)
      .then((res) => {
        this.props.getProperty();
        return res;
      })
      .catch((err) => this.onCatchError(err));
  };

  onUploadTour = ({ payload }) => {
    return axios
      .post(`/api/admin/properties/${this.props.property.id}/pictures/addTour`, payload)
      .then((res) => {
        this.props.getProperty();
        return res;
      })
      .catch((err) => this.onCatchError(err));
  };
  onUpdateTour = ({ payload }) => {
    return axios
      .post(`/api/admin/properties/${this.props.property.id}/pictures/updateTour`, payload)
      .then((res) => {
        this.props.getProperty();
        return res;
      })
      .catch((err) => this.onCatchError(err));
  };

  onFetchTags() {
    this.setState({ loading: true });
    axios
      .get('/api/admin/photo-tags')
      .then((res) => {
        this.setState({ tags: res.data, loading: false });
      })
      .catch((err) => this.onCatchError(err));
  }

  onForceRedirect() {
    axios.clearLogin();
  }

  onCatchError(err) {
    if (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            this.onForceRedirect();
            break;
          case 403:
            // wil be handled globally via axios interceptor and redirect
            break;
          case 413:
            this.context.emitter.emit('alert-queue:add', {
              type: 'danger',
              message: 'File Size exceeds 30mb',
            });
            break;
          case 503:
            this.context.emitter.emit('alert-queue:add', {
              type: 'danger',
              message: 'Your upload bandwidth is too low or server is down.',
            });
            break;
          default:
            this.context.emitter.emit('alert-queue:add', this.defaultErrorNotification);
            break;
        }
      }
    } else {
      this.setState({
        notification: { type: 'danger', message: err.message },
      });
    }
  }

  onForceFetch() {
    this.setState({ loading: true });
    axios
      .get(`/api/admin/properties/${this.props.property.id}/pictures`)
      .then((res) => {
        this.setState({
          items: res.data,
          loading: false,
        });
        this.props.setRecord({
          ...this.props.property,
          photos: res.data,
        });
      })
      .catch((err) => this.onCatchError(err));
  }

  updateTour = async () => {
    const payload = {
      picture_id: this.state.tour_id,
      original_url: this.state.virtual_tour_link,
      hidden: false,
      property_id: this.state.items[0].property_id,
    };
    if (!this.state.tour_id) {
      await this.onUploadTour({ payload });
    } else {
      await this.onUpdateTour({ payload });
    }
  };

  onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { has_tour, has_floorplan, virtual_tour_link, tours, items } = this.state;
    const copyItems = resortItems([...items, ...tours], has_tour, has_floorplan);
    const payload = {
      photos: transformPhotoItems(copyItems, { has_tour, has_floorplan }),
      property_update_action: 'SET_ALL_PROFESSIONAL',
      virtual_tour_link,
    };

    const records = await this.onPushUpdate({ payload });
    if (has_tour !== 'no') {
      this.updateTour();
    }
    await this.props.getProperty();
    this.setState({
      items: records.data.records,
      notification: {
        type: 'success',
        message: 'Changes have been saved',
      },
    });
    setTimeout(() => {
      this.setState({ notification: { message: '' } });
    }, 1500);
  };
  setPhotos() {
    const items = this.props.property.photos
      .filter((item) => ['photo', 'floorplan', 'tourStock', null].includes(item.type))
      .map((e, index) => {
        const result = { ...e };

        if (!e.property_id) {
          result.property_id = this.props.property.id;
        }
        result.order = index;
        return result;
      });

    const tours = this.props.property.photos.filter((item) => ['tour-embedded', 'tour-direct'].includes(item.type));
    this.setState({
      tours,
      items,
      has_tour: this.props.property.has_tour_link ? 'yes' : 'no',
      virtual_tour_link: tours[0]?.original_url ?? '',
      tour_id: tours[0]?.id ?? '',
    });
  }

  handleCheckbox = (_, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleProCheckbox = async (_, { name, value }) => {
    let payload;

    if (value === 'yes') {
      payload = this.state.items.map((item) => {
        return {
          pro: true,
          picture_id: item.id,
        };
      });
    } else {
      payload = this.state.items.map((item) => {
        return {
          pro: false,
          picture_id: item.id,
        };
      });
    }
    this.setState({
      [name]: value,
    });
    await this.onPushUpdate({ payload: { photos: payload } });
  };

  handleVirtualTourLink = (_, { name, value }) => {
    this.setState({ [name]: value });
  };

  toggleModal = () => {
    this.setState({ uploadModal: !this.state.uploadModal });
  };

  makePrimary = (index) => {
    const itemsArr = [...this.state.items];

    itemsArr.unshift(itemsArr.splice(index, 1)[0]);

    this.setState({
      items: itemsArr,
    });

    const payload = {
      photos: itemsArr.map((item, i) => {
        return {
          picture_id: item.id,
          property_id: item.property_id,
          order: i,
          primary: i === 0,
        };
      }),
      property_update_action: 'MAKE_PHOTO_PRIMARY',
    };

    this.onPushUpdate({ payload }).then(() => {
      this.context.emitter.emit('alert-queue:add', {
        type: 'success',
        message: 'Succesully updated photo to be primary',
      });
    });
  };

  /**
   * downloadPhotos
   *
   * Send get request to an endpoint that returns a blob of data
   * that represents a zip of photos, save that zip to local machine
   *
   * @param {Array} ids An array of photoIds to fetch a zip for
   */
  downloadPhotos = (ids) => {
    axios
      .get(`/api/admin/properties/${this.props.property.unit_id}/pictures/download/`, {
        params: {
          photo_ids: ids,
        },
        responseType: 'blob',
      })
      .then(async (res) => {
        await fileSaver(res.data, `${this.props.property.address_1.toLowerCase()}.zip`);
      });
  };

  /**
   * downloadSelectedPhotos
   *
   * This is a handler for a button click on
   * 'Download selected photos'. It determines
   * whether it needs to fetch all photos, or
   * whether it can just serve a .jpg download.
   *
   */
  downloadSelectedPhotos = async () => {
    const photoIds = this.state.selectedPhotos
      .filter((photo) => !this.state.items[this.state.items.findIndex((pic) => pic.id === photo.id)].hidden)
      .map((pic) => pic.id);

    if (parseFloat(photoIds.length) === 1) {
      const image_url = this.state.items.find((obj) => {
        return obj.id === this.state.selectedPhotos[0].id;
      }).image_url;

      return await fileSaver(image_url, `${this.state.selectedPhotos[0].id}.jpg`);
    }

    return this.downloadPhotos(photoIds);
  };

  /**
   * downloadAllPhotos
   *
   * This handles a click on 'Download all photos'.
   * It sends an array of all photoIds for photos
   * that are not hidden to downloadPhotos.
   *
   */
  downloadAllPhotos = () => {
    this.downloadPhotos(this.state.items.filter((item) => !item.hidden).map((photo) => photo.id));
  };

  hideSelectedPhotos = () => {
    const confirmHide = window.confirm(
      `Are you sure you want to hide ${this.state.selectedPhotos.length} ${
        this.state.selectedPhotos.length > 1 ? `photos` : `photo`
      } of this property?`,
    );
    if (!confirmHide) return;
    const promises = [];
    this.state.selectedPhotos.forEach((item) => {
      const payload = {
        picture_id: item.id,
        property_id: item.property_id,
        hidden: true,
      };
      promises.push(this.onPushUpdate({ payload, id: item.id }));
    });

    Promise.all(promises)
      .then(() => {
        this.onForceFetch();
        this.context.emitter.emit('alert-queue:add', {
          type: 'success',
          message: 'Changes have been saved. Photos successfully hid.',
        });
      })
      .catch(() => {
        return this.context.emitter.emit('alert-queue:add', this.defaultErrorNotification);
      });
    this.setState({ selectedPhotos: [] });
  };

  hideAllPhotos = async () => {
    const isConfirmed = window.confirm('Are you sure you want to hide all photos of this property?');
    const copyItems = [...this.state.items];
    if (!isConfirmed) return;

    const payload = {
      photos: copyItems.map((item) => {
        return {
          picture_id: item.id,
          property_id: item.property_id,
          hidden: true,
        };
      }),
      property_update_action: 'HIDE_ALL_PHOTOS',
    };

    await this.onPushUpdate({ payload });
    this.setState({
      items: copyItems.map((item) => {
        return {
          ...item,
          hidden: true,
        };
      }),
    });
    this.context.emitter.emit('alert-queue:add', {
      type: 'success',
      message: 'Changes have been saved. Successfully hid all photos',
    });
  };

  deleteAllPhotos = () => {
    const isConfirmed = window.confirm('Are you sure you want to delete all photos of this property?');

    if (!isConfirmed) return;

    const photos = [...this.state.items, ...this.state.tours];

    const payload = photos.map((item) => {
      return item.id;
    });

    axios
      .delete(`/api/admin/properties/${this.props.property.id}/pictures`, {
        data: { photo_ids: payload },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({
            items: [],
          });

          this.context.emitter.emit('alert-queue:add', {
            type: 'success',
            message: 'Changes have been saved. Successfully removed all photos',
          });
        }
      })
      .catch((err) => this.onCatchError(err));
  };

  selectPhoto = (id, property_id, selectBetween = false) => {
    const { items } = this.state;
    let selectedPhotos = [...this.state.selectedPhotos];
    const isSelected = selectedPhotos.some((item) => {
      return item.id === id;
    });
    const currentIndex = items.findIndex((item) => item.id === id);
    const previouslySelectedId = this.state.previouslySelectedId;
    const prevIsSelected = selectedPhotos.some((item) => {
      return item.id === previouslySelectedId;
    });
    const prevIndex = items.findIndex((item) => item.id === previouslySelectedId);

    if (previouslySelectedId && prevIsSelected && selectBetween && currentIndex !== prevIndex) {
      const start = prevIndex < currentIndex ? prevIndex : currentIndex;
      const end = prevIndex < currentIndex ? currentIndex : prevIndex;
      const photosToSelect = items
        .slice(start, end)
        .map((item) => ({
          id: item.id,
          property_id: item.property_id,
        }))
        .concat([
          {
            id,
            property_id,
          },
        ]);

      selectedPhotos = selectedPhotos.concat(photosToSelect);
    } else if (previouslySelectedId && !prevIsSelected && selectBetween && currentIndex !== prevIndex) {
      const start = prevIndex < currentIndex ? prevIndex : currentIndex;
      const end = prevIndex < currentIndex ? currentIndex : prevIndex;
      const photosToDeselect = items
        .slice(start, end)
        .map((item) => item.id)
        .concat([id]);

      selectedPhotos = selectedPhotos.filter((i) => !photosToDeselect.includes(i.id));
    } else if (!isSelected) {
      selectedPhotos = selectedPhotos.concat([
        {
          id,
          property_id,
        },
      ]);
    } else if (isSelected) {
      selectedPhotos = selectedPhotos.filter((i) => i.id !== id);
    }

    this.setState({
      selectedPhotos: selectedPhotos.reduce((prev, curr) => {
        if (!prev.some((p) => p.id === curr.id)) {
          prev.push(curr);
        }

        return prev;
      }, []),
      previouslySelectedId: id,
    });
  };

  orderPhotos = () => {
    const { id } = this.props.property;

    this.setState({
      propertyId: id,
    });
    this.photosModalToggle();
  };

  photosModalToggle = () => {
    this.setState({ active: !this.state.active });
  };

  defaultErrorNotification = {
    type: 'danger',
    message: 'Something went wrong',
  };

  render() {
    const {
      loading,
      uploading,
      uploadModal,
      items,
      tags,
      active,
      propertyId,
      notification,
      progress,
      has_tour,
      has_pro_photos,
      has_floorplan,
      virtual_tour_link,
      tours,
    } = this.state;
    const {
      permissions: { canManagePropertyPhotos },
      property,
    } = this.props;

    const proPhotos = items.filter((photo) => photo.pro);

    return (
      <div>
        <h2>Photos</h2>
        {notification.message && <InlineMessage type={notification.type} message={notification.message} />}
        {!!items.length && canManagePropertyPhotos && (
          <Form>
            <Flex flexDirection="column" css="margin-top: 40px; margin-bottom: 40px;">
              <Box>
                <label>
                  <strong>Has professional photos:</strong>
                </label>
              </Box>
              <Flex alignItems="center">
                <Box mr={15}>
                  <Checkbox
                    radio
                    label="Yes"
                    value="yes"
                    name="has_pro_photos"
                    checked={has_pro_photos === 'yes'}
                    onChange={this.handleProCheckbox}
                  />
                </Box>
                <Box mr={60}>
                  <Checkbox
                    radio
                    label="No"
                    value="no"
                    name="has_pro_photos"
                    checked={has_pro_photos === 'no'}
                    onChange={this.handleProCheckbox}
                  />
                </Box>
                <Box>
                  <label>
                    Professional photos: {proPhotos.length} / {this.props.property.photos.length}
                  </label>
                </Box>
              </Flex>
              <React.Fragment>
                <Box>
                  <label>
                    <strong>
                      3D Tour:
                      {has_tour === 'yes' && (
                        <>
                          <Input
                            name="virtual_tour_link"
                            className="virtual-tour__input"
                            value={virtual_tour_link}
                            onChange={this.handleVirtualTourLink}
                          />
                          {tours?.[0]?.original_url && (
                            <>
                              <a
                                href={has_tour && tours.length && tours[0].original_url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <Button type="button">Preview Tour</Button>
                              </a>
                              <Button negative onClick={this.deleteTour} type="button">
                                Delete Tour
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </strong>
                  </label>
                </Box>
                <Flex alignItems="center">
                  <Box mr={15}>
                    <Checkbox
                      id="has_tour_yes"
                      radio
                      label="Yes"
                      value="yes"
                      name="has_tour"
                      checked={has_tour === 'yes'}
                      onChange={this.handleCheckbox}
                    />
                  </Box>
                  <Box mr={60}>
                    <Checkbox
                      id="has_tour_no"
                      radio
                      label="No"
                      value="no"
                      name="has_tour"
                      checked={has_tour === 'no'}
                      onChange={this.handleCheckbox}
                    />
                  </Box>
                </Flex>
              </React.Fragment>
              <React.Fragment>
                <Box>
                  <label>
                    <strong>Floorplan:</strong>
                  </label>
                </Box>
                <Flex alignItems="center">
                  <Box mr={15}>
                    <Checkbox
                      id="has_floorplan_yes"
                      radio
                      label="Yes"
                      value="yes"
                      name="has_floorplan"
                      checked={has_floorplan === 'yes'}
                      onChange={this.handleCheckbox}
                    />
                  </Box>
                  <Box mr={60}>
                    <Checkbox
                      id="has_floorplan_no"
                      radio
                      label="No"
                      value="no"
                      name="has_floorplan"
                      checked={has_floorplan === 'no'}
                      onChange={this.handleCheckbox}
                    />
                  </Box>
                </Flex>
              </React.Fragment>
              <Box>
                <Button id="save_toggle_changes" type="submit" onClick={this.onSubmit} primary>
                  Save
                </Button>
              </Box>
              <Flex>
                {this.state.selectedPhotos.length > 0 && (
                  <Box mt={5}>
                    <Box mt={50}>
                      <Button primary type="button" onClick={() => this.setState({ selectedPhotos: [] })}>
                        Unselect All
                      </Button>
                      <Button primary type="button" onClick={this.hideSelectedPhotos}>
                        Hide selected photos
                      </Button>
                      <Button negative type="button" onClick={this.onDeleteSelected}>
                        Delete selected photos
                      </Button>
                    </Box>
                    <Box mt={10}>
                      <Button primary type="button" onClick={this.downloadAllPhotos}>
                        Download all photos
                      </Button>
                      <Button primary type="button" onClick={this.downloadSelectedPhotos}>
                        Download selected photos
                      </Button>
                    </Box>
                  </Box>
                )}
                {this.state.selectedPhotos.length === 0 && (
                  <Box mt="5px">
                    <Box mt={50}>
                      <Button primary type="button" onClick={this.hideAllPhotos}>
                        Hide all photos
                      </Button>
                      <Button negative type="button" onClick={this.deleteAllPhotos}>
                        Delete all photos
                      </Button>
                    </Box>
                    <Box mt={10}>
                      <Button primary type="button" onClick={this.downloadAllPhotos}>
                        Download all photos
                      </Button>
                    </Box>
                  </Box>
                )}
              </Flex>
            </Flex>
          </Form>
        )}

        <p>
          Re-arrange photos in order by dragging and dropping. The First photo is the primary (initially shown) photo.
        </p>
        {loading ? (
          <div>
            <h2>Loading, Please Wait..</h2>
            <div
              className="progress-bar bg-success progress-bar-striped"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: '100%' }}
            />
          </div>
        ) : (
          <div className="property-photos__container">
            <OrderPhotosModal
              active={active}
              toggleModal={this.photosModalToggle}
              propertyId={propertyId}
              hasProPhotos={this.state.has_pro_photos === 'yes'}
              poc={property.poc}
              specialInstructions={this.state.photo_special_instructions}
            />
            <Images
              className="property-photos"
              onChangeOrder={this.onChangeOrder}
              onChangeCheckbox={this.onChangeCheckbox}
              onUpdateTag={this.onUpdateTag}
              deletePhoto={this.onDeletePhoto}
              downloadPhoto={this.onDownloadPhoto}
              makePrimary={this.makePrimary}
              hasPermission={canManagePropertyPhotos}
              selectPhoto={this.selectPhoto}
              selectedPhotos={this.state.selectedPhotos}
              items={items}
              tags={tags}
            />
            {canManagePropertyPhotos && (
              <Flex justifyContent="flex-end" css="margin-top: 20px;">
                <Box>
                  <Button type="button" onClick={this.toggleModal}>
                    Add New Photo(s)
                  </Button>
                  <Button type="button" onClick={this.orderPhotos} primary>
                    Order photos
                  </Button>
                </Box>
              </Flex>
            )}

            <Modal isOpen={uploadModal} toggle={this.toggleModal}>
              <ModalHeader>Upload Photos</ModalHeader>
              <ModalBody>
                <Flex>
                  <Box width={1}>
                    {uploading ? (
                      <div>
                        <h2>Uploading, Please Wait..</h2>
                        <div
                          className="progress-bar bg-success progress-bar-striped"
                          role="progressbar"
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: progress }}
                        />
                      </div>
                    ) : (
                      <PhotoDropzone onDrop={this.onDropImages} />
                    )}
                  </Box>
                </Flex>
              </ModalBody>
              {!uploading && (
                <ModalFooter>
                  <Button negative onClick={this.toggleModal}>
                    Cancel
                  </Button>
                  <Button positive>Upload</Button>
                </ModalFooter>
              )}
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default withPermissions(Photos);
