import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Input, Label, FormGroup, Button, Card, CardBody } from 'reactstrap';
import { Message, Dimmer, Loader } from 'semantic-ui-react';
import axios from 'utils/axios';

import '../style.scss';

import Logo from '../../../components/Logo';

class Login extends Component {
  state = {
    email: '',
    password: '',
    message: '',
    redirect: false,
    loading: false,
  };
  onValidateLogin(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ message: '', loading: true });
    axios
      .post('/auth/login', {
        email: this.state.email.toLowerCase(),
        password: this.state.password,
      })
      .then((res) => {
        if (res.status === 200) {
          axios.setLogin(res.data.token);
          this.setState({ redirect: true, loading: false });
        }
      })
      .catch((err) => {
        if (!err.status || err.status === 400) {
          this.setState({
            message: 'Your username or password is incorrect. Please try again.',
            loading: false,
          });
        } else {
          this.setState({ message: err.message, loading: false });
        }
      });
  }
  render() {
    if (axios.hasToken() || this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/properties',
          }}
        />
      );
    }
    return (
      <div className="view">
        <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
          <Card className="mb-3 form-card">
            <CardBody>
              <header className="mb-5">
                <Link to="/">
                  <Logo />
                </Link>
              </header>
              {!this.state.loading ? (
                <Form onSubmit={(e) => this.onValidateLogin(e)}>
                  {this.state.message && (
                    <Message>
                      <p>{this.state.message}</p>
                    </Message>
                  )}
                  <FormGroup className="mb-4">
                    <Label>Email Address</Label>
                    <Input
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => this.setState({ email: e.target.value.trim() })}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label>Password</Label>
                    <Input
                      type="password"
                      placeholder="Password"
                      onEnter
                      onChange={(e) => this.setState({ password: e.target.value.trim() })}
                    />
                  </FormGroup>
                  <FormGroup className="text-right">
                    <Button type="submit" color="primary" style={{ cursor: 'pointer' }}>
                      Sign In
                    </Button>
                  </FormGroup>
                </Form>
              ) : (
                <div style={{ minHeight: `${150}px` }}>
                  <Dimmer active inverted>
                    <Loader inverted>
                      <p>Logging In..</p>
                    </Loader>
                  </Dimmer>
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default Login;
