import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DownloadReportModal from '../components/DownloadReportModal';

class ExportToCSVButton extends Component {
  static propTypes = {
    transformDataForCSV: PropTypes.func.isRequired,
  };

  state = {
    showModal: false,
  };

  getDataHandler = () => {
    return this.props
      .transformDataForCSV()
      .then((res) => {
        return res;
      })
      .catch(() => {
        return {
          message: 'Something went wrong.',
        };
      });
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  render() {
    const { showModal } = this.state;

    return (
      <div>
        {showModal ? (
          <DownloadReportModal
            isActive={showModal}
            toggleModal={this.toggleModal}
            getDataFunctionCall={this.getDataHandler}
          />
        ) : (
          <button type="button" className="ui primary button export" onClick={this.toggleModal}>
            Export Report
          </button>
        )}
      </div>
    );
  }
}

export default ExportToCSVButton;
