import axios from './axios';
import { get, isArray, isEmpty } from 'lodash';
import moment from 'moment';

export const buildFilters = (filtered) => {
  const filters = {};

  (filtered || []).forEach((f) => {
    const value = extractFilterValue(f.value);
    if (isValidValue(value)) {
      filters[f.id] = value;
    }
  });

  return filters;
};

export const extractFilterValue = (value) => {
  if (isArray(value)) {
    return value.map((e) => (e && e.value !== undefined ? e.value : e));
  } else if (value && value.value) {
    return value.value;
  }
  return value;
};

export const isValidValue = (value) => {
  return !!value && value.length > 0;
};

export const buildSorting = (sorted) => {
  const sorting = {};
  (sorted || []).forEach((s) => {
    sorting[s.id] = s.desc ? 'desc' : 'asc';
  });
  return sorting;
};

export const fetchData = (endpoint, payload, transformValues) => {
  return axios.get(endpoint, payload).then((res) => {
    const rowData = res.data.results.map((result) => {
      const tmp = { ...result };
      res.data.filters.forEach((filter) => {
        if (transformValues) {
          tmp[filter.key] = transformValues(filter.key, tmp[filter.key], tmp);
        }
        if (tmp[filter.key] !== undefined && tmp[filter.key] !== null) {
          tmp[filter.key] = tmp[filter.key].toString().replace(/"/g, '');
        }
      });
      return tmp;
    });

    const headers = res.data.filters.map((filter) => ({ label: filter.label, key: filter.key }));

    return { headers, rowData };
  });
};

/**
 * @name getTransformedDataForCSV
 * @description - Makes a service request to retrieve data based on options provided.
 * Transforms all property values to be strings; converts timestamp values to formatted
 * values if needed; sets the user_assigned_user_id to an empty string; and finally, returns
 * and object which can be consumed and converted to a csv file.
 * @param {Object} options
 * @param {Object} options.date - An object which contains the following values: availableStartDate, availableEndDate.
 * @param {Number} options.total - The total number of records.
 * @param {Number} options.limit - The max number of records we can operate on.
 * @param {String} options.endpoint - Where to make the service request.
 * @param {Array} options.sorted - Sorted filter values.
 * @param {Array} options.filtered - Applied filter values.
 * @param {String} options.filename - The filename for the generated csv file.
 * @param {Function} options.transformValues - Callback function that is called after an axios get call to retrieve report data (filters and results)
 * if provided as an option for getTransformedDataForCSV. Once called transformValues exposes the following: the filter key, the value of the result
 * based on the filter key, and the result object (in that order). The value being returned from the callback gets set to a temporary result object and sets it's value
 * based on what gets returned [from the callback function]: tmp[filter.key] = options.transformValues(filter.key, tmp[filter.key], tmp);
 * @returns {Promise} - Once resolved will return on object with the following properties: headers, rowData, filename, and canDownloadFile flag.
 */
export const getTransformedDataForCSV = (options) => {
  const dateFilters = get(options, 'dateFilters', {});
  const total = get(options, 'total', 0);
  const endpoint = get(options, 'endpoint', '');
  const sorted = get(options, 'sorted', []);
  const filtered = get(options, 'filtered', []);
  const filename = get(options, 'filename', `${Date.now()}.csv`);

  const filters = buildFilters(filtered);
  const sorting = buildSorting(sorted);

  const payload = {
    params: {
      pageSize: total === 0 ? 1 : total,
      withFilters: true,
      sorting: JSON.stringify(sorting),
      filters: JSON.stringify(filters),
      ...dateFilters,
    },
  };

  return fetchData(endpoint, payload, options.transformValues).then(({ headers, rowData }) => ({
    headers,
    rowData,
    filename,
  }));
};

/**
 * @name getReportErrorMessage
 * @description Returns and error message if one exists; else it returns an empty string
 * @param {Object} options
 * @param {Object} options.total - The total number of results for a report
 * @param {Object} options.limit - The max number of records we can download for a report
 *
 * @returns {String}
 */
export const getReportErrorMessage = (options) => {
  const { total, limit } = options;

  if (total > limit) {
    return 'There are too many properties to generate a report.  Please use filters to refine your search results.';
  } else if (total === 0) {
    return 'There are no results for this report.';
  }

  return '';
};

export const transformDataForCSV = ({ state, maxReportSizeLimit, transformValues, endpoint, reportName }) => {
  const total = state.total;

  if (total > 0 && total <= maxReportSizeLimit) {
    return getTransformedDataForCSV({
      dateFilters: {
        availableStartDate: state.availableStartDate,
        availableEndDate: state.availableEndDate,
        createdAtStartDate: state.createdAtStartDate,
        createdAtEndDate: state.createdAtEndDate,
        updatedAtStartDate: state.updatedAtStartDate,
        updatedAtEndDate: state.updatedAtEndDate,
        completedAtStartDate: state.completedAtStartDate,
        completedAtEndDate: state.completedAtEndDate,
      },
      filtered: state.filtered,
      sorted: state.sorted,
      filename: `${reportName}-${Date.now()}.csv`,
      transformValues,
      endpoint,
      total,
    });
  } else {
    return Promise.resolve({
      message: getReportErrorMessage({
        limit: maxReportSizeLimit,
        total,
      }),
    });
  }
};

export const isValidateDate = (date) => {
  return moment(date, 'L', true).isValid();
};

export const isDateRangeInvalid = (startDate, endDate) => {
  return !moment(endDate).isAfter(moment(startDate));
};

export const invalidAny = ({ startDate, endDate }) => {
  return (!isEmpty(startDate) && !isValidateDate(startDate)) || (!isEmpty(endDate) && !isValidateDate(endDate));
};

// eslint-disable-next-line complexity
export const filtersReducer = (state, action) => {
  const { createdAt, updatedAt, completedAt } = action;
  const { createdAt: stateCreatedAt, updatedAt: stateUpdatedAt, completedAt: stateCompletedAt } = state;

  switch (action.type) {
    case 'createdat:dates': {
      const { startDate = stateCreatedAt.startDate, endDate = stateCreatedAt.endDate } = createdAt;
      return {
        ...state,
        createdAt: { ...stateCreatedAt, startDate, endDate },
      };
    }
    case 'createdat:error': {
      const error = createdAt.error;
      return { ...state, createdAt: { ...stateCreatedAt, error } };
    }
    case 'updatedat:dates': {
      const { startDate = stateUpdatedAt.startDate, endDate = stateUpdatedAt.endDate } = updatedAt;
      return { ...state, updatedAt: { startDate, endDate } };
    }
    case 'updatedat:error': {
      const error = updatedAt.error;
      return { ...state, updatedAt: { ...stateUpdatedAt, error } };
    }
    case 'completedat:dates': {
      const { startDate = stateCompletedAt.startDate, endDate = stateCompletedAt.endDate } = completedAt;
      return { ...state, completedAt: { startDate, endDate } };
    }
    case 'completedat:error': {
      const error = completedAt.error;
      return { ...state, completedAt: { ...stateCompletedAt, error } };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const transformValues = (key, value) => {
  // don't display the assigned users id in the generated csv file
  if (key === 'user_assigned_user_id') {
    return '';
  }

  return value;
};
