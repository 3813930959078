import React, { Fragment } from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const SubmitMessage = ({ error, saved }) => {
  return (
    <Fragment>
      {error ? (
        <Message
          negative
          onDismiss={() => {
            this.setState({ error: false });
          }}
        >
          <Message.Header>Error</Message.Header>
          <p>An error has occurred while saving the property.</p>
        </Message>
      ) : saved ? (
        <Message
          positive
          onDismiss={() => {
            this.setState({ saved: false });
          }}
        >
          <Message.Header>Success</Message.Header>
          <p>Your changes have been saved.</p>
        </Message>
      ) : null}
    </Fragment>
  );
};

export default SubmitMessage;

SubmitMessage.propTypes = {
  error: PropTypes.bool,
  saved: PropTypes.bool,
};
