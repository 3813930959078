/* eslint-disable complexity */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import ScrollArea from 'react-scrollbar';

import Logo from '../Logo';

// icons
import { MdFormatListBulleted, MdPowerSettingsNew } from 'react-icons/md';

import './style.scss';
import { withPermissions } from '../HoC';

const ItemLabel = ({ children }) => <span className="name">{children}</span>;

export class NavItem extends Component {
  static propTypes = {
    children: PropTypes.func,
    selected: PropTypes.bool,
    label: PropTypes.string,
    permissions: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    to: PropTypes.string,
  };

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick(this.props.to, e);
  };
  render() {
    const { label, to, selected, children } = this.props;
    return (
      <li onClick={this.handleClick} className={selected ? 'selected' : ''}>
        <Link to={to}>{children ? children({ Label: ItemLabel }) : <ItemLabel>{label}</ItemLabel>}</Link>
      </li>
    );
  }
}

ItemLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
export const DividerLabel = ({ text }) => (
  <li>
    <div className="nav-list-title">{text}</div>
  </li>
);

DividerLabel.propTypes = {
  text: PropTypes.string.isRequired,
};

const NavHead = () => (
  <header className="nav-head" style={{ padding: '12px 0 0 0', margin: 0 }}>
    <Link to="/" style={{ display: 'block', padding: '0 10px' }}>
      <Logo style={{ width: 165 }} />
    </Link>
  </header>
);

export const ListContainer = ({ children }) => (
  <ScrollArea
    className="nav-list-container"
    horizontal={false}
    verticalScrollbarStyle={{ width: '4px', marginLeft: '10px' }}
  >
    <ul className="list-unstyled nav-list clearfix">{children}</ul>
  </ScrollArea>
);

ListContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const NavGroupContainer = ({ children }) => <ul className="inner-drop list-unstyled">{children}</ul>;

NavGroupContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export class NavGroup extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    name: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    selected: PropTypes.bool,
    labelRender: PropTypes.func,
  };

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick(this.props.name, e);
  };
  render() {
    const { children, selected, labelRender } = this.props;
    return (
      <li onClick={this.handleClick}>
        {labelRender()}
        <Collapse isOpen={selected}>
          {children({
            Items: NavGroupContainer,
            Item: NavItem,
            handleClick: this.props.onClick.bind(this, this.props.name),
          })}
        </Collapse>
      </li>
    );
  }
}

class NavList extends Component {
  static propTypes = {
    permissions: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
  };

  static contextTypes = {
    emitter: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasCount: false,
      selected: '',
    };
  }

  handleClick = (selected) => {
    this.setState({ selected });
  };

  render() {
    const {
      permissions: {
        canAdminMac,
        canViewReports,
        canManageLeasingSpecials,
        canManagePropertyPhotos,
        canManageFeeds,
        canCreateFeeds,
        canManageFilters,
      },
    } = this.props;

    return (
      <ListContainer>
        <NavItem onClick={this.handleClick} selected={this.state.selected === '/properties'} to="/properties">
          {({ Label }) => (
            <span>
              <MdFormatListBulleted size="18" />
              <Label>Properties</Label>
            </span>
          )}
        </NavItem>
        <NavItem onClick={this.handleClick} selected={this.state.selected === '/markets'} to="/markets">
          {({ Label }) => (
            <span>
              <MdFormatListBulleted size="18" />
              <Label>Markets</Label>
            </span>
          )}
        </NavItem>

        {canAdminMac && (
          <NavItem
            onClick={this.handleClick}
            selected={this.state.selected === '/administration/roles'}
            to="/administration/roles"
          >
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>Administration</Label>
              </span>
            )}
          </NavItem>
        )}

        {canManageFilters && (
          <NavItem onClick={this.handleClick} selected={this.state.selected === '/amenities'} to="/amenities">
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>Amenities</Label>
              </span>
            )}
          </NavItem>
        )}

        {canManageLeasingSpecials && (
          <NavItem
            onClick={this.handleClick}
            selected={this.state.selected === '/leasing-specials'}
            to="/leasing-specials"
          >
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>Leasing Specials</Label>
              </span>
            )}
          </NavItem>
        )}

        {canViewReports && (
          <NavItem
            onClick={this.handleClick}
            selected={this.state.selected === '/reports/property'}
            to="/reports/property"
          >
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>Reports</Label>
              </span>
            )}
          </NavItem>
        )}

        {process.env.REACT_APP_HIDE_LEGACY_BULK_PHOTO_ORDER === 'true' ? null : (
          <NavItem
            onClick={this.handleClick}
            selected={this.state.selected === '/bulk-photo-order'}
            to="/bulk-photo-order"
          >
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>Bulk Photo Ordering</Label>
              </span>
            )}
          </NavItem>
        )}

        {canManagePropertyPhotos && (
          <NavItem
            onClick={this.handleClick}
            selected={this.state.selected === '/bulk-photo-upload'}
            to="/bulk-photo-upload"
          >
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>Bulk Photo Upload</Label>
              </span>
            )}
          </NavItem>
        )}

        {process.env.REACT_APP_BULK_PHOTO_ORDER === 'true' ? (
          <NavItem
            onClick={this.handleClick}
            selected={this.state.selected === '/bulk-photo-ordering'}
            to={
              process.env.REACT_APP_HIDE_LEGACY_BULK_PHOTO_ORDER === 'true'
                ? '/bulk-photo-ordering'
                : '/planomatic-bulk-order'
            }
          >
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>
                  {process.env.REACT_APP_HIDE_LEGACY_BULK_PHOTO_ORDER === 'true'
                    ? 'Bulk Photo Ordering'
                    : 'Planomatic Bulk Order'}
                </Label>
              </span>
            )}
          </NavItem>
        ) : null}

        {process.env.REACT_APP_BULK_BOOST_ENABLED === 'true' ? (
          <NavItem
            onClick={this.handleClick}
            selected={this.state.selected === '/bulk-boost-listing'}
            to="/bulk-boost-listing"
          >
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>Bulk Boost Listing</Label>
              </span>
            )}
          </NavItem>
        ) : null}

        {process.env.REACT_APP_HIDE_PHOTOS_ENABLED === 'true' && canManagePropertyPhotos ? (
          <NavItem onClick={this.handleClick} selected={this.state.selected === '/hide-photos'} to="/hide-photos">
            {({ Label }) => (
              <span>
                <MdFormatListBulleted size="18" />
                <Label>Hide Photos</Label>
              </span>
            )}
          </NavItem>
        ) : null}

        {(canManageFeeds || canCreateFeeds) && (
          <Fragment>
            <NavItem onClick={this.handleClick} selected={this.state.selected === '/feeds'} to="/feeds">
              {({ Label }) => (
                <span>
                  <MdFormatListBulleted size="18" />
                  <Label>Feeds</Label>
                </span>
              )}
            </NavItem>
            <NavItem onClick={this.handleClick} selected={this.state.selected === '/feed-formats'} to="/feed-formats">
              {({ Label }) => (
                <span>
                  <MdFormatListBulleted size="18" />
                  <Label>Feed Formats</Label>
                </span>
              )}
            </NavItem>
          </Fragment>
        )}

        <NavItem onClick={this.handleClick} selected={this.state.selected === '/logout'} to="/logout">
          {({ Label }) => (
            <span>
              <MdPowerSettingsNew size="18" />
              <Label>Logout</Label>
            </span>
          )}
        </NavItem>
      </ListContainer>
    );
  }
}

const NavListWithPermissions = withRouter(withPermissions(NavList));

function NavComponent(props) {
  return (
    <nav className={`site-nav ${props.mini ? 'mini' : ''}`}>
      <NavHead {...props} />
      <NavListWithPermissions {...props} />
    </nav>
  );
}

export default withRouter(NavComponent);

NavComponent.propTypes = {
  mini: PropTypes.bool,
};
