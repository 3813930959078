import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { Flex, Box } from 'grid-styled';
import axios from 'utils/axios';
import { withPermissions } from '../../components/HoC';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import { isChecked } from 'utils/syndication';

const Amenities = ({ classes, permissions: { canEditPropertyAmenities = false }, ...rest }) => {
  const [amenitiesDef, setAmenitiesDef] = useState([]);
  const [amenitiesCount, setAmentitesCount] = useState(0);
  const methods = useFormContext({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { watch, control } = methods;

  const values = watch();

  useEffect(() => {
    axios.get('/api/admin/amenities').then(({ data }) => {
      // remove "New Construction" Amenity checkbox
      const filteredAmenities = data.filter((amenity) => amenity.name !== 'New Construction');
      setAmenitiesDef(filteredAmenities);
    });
    setAmentitesCount(values.amenities.length);
  }, []);

  const toggleValueInArray = (array, value) => {
    const index = array.findIndex((item) => item.name === value.name);
    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push(value);
    }
    setAmentitesCount(array.length);
    return array;
  };

  if (!values || !values.amenities || values.loading) {
    return <Loader inline="centered" active={true} />;
  }

  return (
    <Box>
      <Box width={1} mb={10}>
        <p>Total selected amenities {amenitiesCount}</p>
      </Box>
      <FormGroup>
        <Flex flexWrap="wrap">
          <Box width={1} mb={10}>
            <h2>Home Features</h2>
          </Box>

          {amenitiesDef
            .filter((amenity) => amenity.category === 'Home')
            .map((amenity, index) => {
              return (
                <Box width={[1, 1 / 2, 1 / 3]} key={`amenity-${index}`}>
                  <Controller
                    control={control}
                    name={'amenities'}
                    render={({ field: { onBlur, name } }) => (
                      <FormControlLabel
                        className={classes.checkBoxLabel}
                        control={
                          <Checkbox
                            color="default"
                            name={name}
                            onBlur={onBlur}
                            value={amenity}
                            data-testid="Featured"
                            checked={isChecked(amenity, values)}
                            disabled={!canEditPropertyAmenities}
                            onChange={() => toggleValueInArray(values.amenities, amenity)}
                          />
                        }
                        label={amenity.name}
                        labelPlacement="end"
                      />
                    )}
                  />
                </Box>
              );
            })}
        </Flex>
        <Flex flexWrap="wrap" css="padding: 5px;">
          <Box width={1} mb={10}>
            <h2>Community Features</h2>
          </Box>
          {amenitiesDef
            .filter((amenity) => amenity.category === 'Community')
            .map((amenity, index) => {
              return (
                <Box width={[1, 1 / 2, 1 / 3]} key={`amenity-${index}`}>
                  <Controller
                    control={control}
                    name={'amenities'}
                    render={({ field: { onBlur, name } }) => (
                      <FormControlLabel
                        className={classes.checkBoxLabel}
                        control={
                          <Checkbox
                            color="default"
                            name={name}
                            onBlur={onBlur}
                            value={amenity}
                            data-testid="Featured"
                            checked={isChecked(amenity, values)}
                            disabled={!canEditPropertyAmenities}
                            onChange={() => toggleValueInArray(values.amenities, amenity)}
                          />
                        }
                        label={amenity.name}
                        labelPlacement="end"
                      />
                    )}
                  />
                </Box>
              );
            })}
        </Flex>
      </FormGroup>
    </Box>
  );
};

Amenities.propTypes = {
  classes: PropTypes.object,
  permissions: PropTypes.object,
};

export default withPermissions(Amenities);
