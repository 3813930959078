import React from 'react';
import PropTypes from 'prop-types';
import Nav from './components/nav';
import SiteHead from './components/header';
import { ThemeProvider } from '@material-ui/core/styles';
import makeTheme from '@invitation-homes/styled-ui-theme-ih';

import './app.scss';

const theme = makeTheme();

class App extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  };

  constructor(props) {
    super(props);
    this.state = { navMini: false };
  }

  toggleNav = (e) => {
    e.preventDefault();
    this.setState({ navMini: !this.state.navMini });
  };
  hideNav = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ navMini: false });
  };
  render() {
    const navMini = this.state.navMini;
    return (
      <ThemeProvider theme={theme}>
        <div className="app-wrapper">
          <Nav mini={navMini} toggleNav={this.toggleNav} />
          <div className={`content-container ${navMini ? 'full' : ''}`}>
            {/* dropshadow for mobile nav triggering */}
            <div className="menu-dropshadow" style={{ display: navMini ? 'block' : 'none' }} onClick={this.hideNav} />
            <SiteHead toggleNav={this.toggleNav} />
            {this.props.children}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
