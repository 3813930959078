import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Flex, Box } from 'grid-styled';
import { has } from 'lodash';
import Papa from 'papaparse';
import axios from 'utils/axios';

import Table from 'components/Table';

import ExportToCVSButton from 'containers/ExportToCSVButton';
import withTableResources from 'containers/TableStateContainer';
import InlineMessage from 'components/InlineMessage';

import { openInNewTab, toSnakeCase } from 'utils/index';
import { MAX_REPORT_SIZE_LIMIT } from 'utils/constants';
import { FiltersProvider, DateRangesFilter, DateRangesFilterActions } from './PhotoOrdersFilterProvider';

const PhotoOrdersReport = (props) => {
  const {
    tableState,
    onFilteredChange,
    onSortedChange,
    onPageChange,
    onPageSizeChange,
    onBeforeRequestDataSetup,
    onFetchData,
  } = props;

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState({});

  useEffect(() => {
    props.onFetchData();
  }, []);

  const importCSV = (e) => {
    setOrders([]);
    setLoading(true);
    Papa.parse(e.target.files[0], {
      header: true,
      transformHeader: (header) => toSnakeCase(header),
      skipEmptyLines: true,
      chunk: (results) => {
        setErrors(results.errors);
        setOrders([...orders, ...results.data.map((order) => order.order_id)]);
      },
      complete: () => {
        setMessage({});
        setLoading(false);
      },
    });
  };

  const updateOrders = async () => {
    if (!orders.length) {
      setMessage({
        type: 'danger',
        message: 'You need to upload a CSV file first.',
      });
      return setLoading(false);
    }
    setLoading(true);

    await axios.put('/api/admin/photo-orders', { orders }).catch((err) => {
      setMessage({ type: 'danger', message: err.message });
      setErrors([]);
      setLoading(false);
      setOrders([]);
    });
    setMessage({ type: 'success', message: 'Successfully updated orders!' });
    setLoading(false);
    setOrders([]);
    setErrors([]);
  };

  return (
    <FiltersProvider>
      <div className="reports">
        <Flex>
          <Box pb={20} pt={40}>
            <p>Upload a CSV file of order IDs to re-import photo order results:</p>
            <p>Note: photos tied to that Order Id will be deleted and reimported once the job runs.</p>
            <input id="reimport_photo_orders_uploader" type="file" accept=".csv" onChange={importCSV} />
            <button className="ui button primary" disabled={loading} onClick={updateOrders}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
            {message.type && <InlineMessage {...message} />}
          </Box>
          <Box pb={20} pt={40} ml={2}>
            <p>View orders that have been placed for professional photos.</p>
            <p>* Starred filters must be exact matches.</p>
          </Box>
        </Flex>
        <Flex flexDirection="column">
          <Box pb={20} pt={40}>
            <DateRangesFilter />
          </Box>
        </Flex>
        <Flex flexDirection="column">
          <Box pb={20}>
            <DateRangesFilterActions onBeforeRequestDataSetup={onBeforeRequestDataSetup} onFetchData={onFetchData} />
          </Box>
        </Flex>
        {orders.length > 0 && (
          <Fragment>
            <InlineMessage type="success" message={`${orders.length - errors.length} rows ready to submit!`} />
          </Fragment>
        )}
        {errors.length > 0 && (
          <Fragment>
            <p>The following rows are not ready to submit:</p>
            {errors.map((error) => {
              const { row, type, message } = error;
              return (
                <Box mb={2} key={row}>
                  <InlineMessage type="danger" message={`Row #: ${row} Error: ${type}. Message: ${message}`} />
                </Box>
              );
            })}
          </Fragment>
        )}
        <Table
          data={tableState.data}
          columns={tableState.columns}
          page={tableState.page}
          pages={tableState.pages}
          defaultPageSize={'25'}
          filtered={tableState.filtered}
          sorted={tableState.sorted}
          loading={tableState.loading}
          onFilteredChange={onFilteredChange}
          onSortedChange={onSortedChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          getTdProps={(state, rowInfo, column) => {
            const style = {
              cursor: 'pointer',
            };
            if (column.id === 'address_1') {
              // eslint-disable-next-line react/display-name
              column.Cell = () => (
                <Link to={`/property/${rowInfo.original.slug}/photos`}>{rowInfo.original.address_1}</Link>
              );
              return {};
            } else if (column.id === 'feed_attempts' && rowInfo && rowInfo.original.feed_attempts_met === 'Yes') {
              style['backgroundColor'] = '#fcf';
            }
            return {
              style: style,
              onClick: () => {
                has(rowInfo, 'original') && openInNewTab(`/property/${rowInfo.original.slug}/photos`);
              },
            };
          }}
        />
        {ExportToCVSButton({
          state: tableState,
          maxReportSizeLimit: MAX_REPORT_SIZE_LIMIT,
          endpoint: '/api/admin/photo-orders',
          reportName: 'photo-orders-report',
        })}
      </div>
    </FiltersProvider>
  );
};

PhotoOrdersReport.propTypes = {
  // inherited from withRouter
  history: PropTypes.object,

  // inherited from withTableResources
  tableState: PropTypes.object,
  onFetchData: PropTypes.func,
  onFilteredChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  onBeforeRequestDataSetup: PropTypes.func,
};

export default withTableResources(withRouter(PhotoOrdersReport), {
  endpoint: '/api/admin/photo-orders',
  isReport: true,
});
