import React from 'react';
import DateRangeSelector from '../../../components/DateRangeSelector';
import InlineMessage from '../../../components/InlineMessage';
import { usePhotoOrdersReportFilters } from './FiltersContext';
import './DateRanges.scss';

const DateRangesFilter = () => {
  const {
    state: { createdAt, updatedAt, completedAt },
    actions: { setCreatedAtDates, setUpdatedAtDates, setCompletedAtDates },
  } = usePhotoOrdersReportFilters();

  return (
    <React.Fragment>
      <DateRangeSelector
        onChange={({ startDate, endDate }) => setCreatedAtDates({ startDate, endDate })}
        onClear={() => setCreatedAtDates({ startDate: '', endDate: '' })}
        filterLabel="Created At"
        keyName="createdAt"
        showApplyButton={false}
      />
      {createdAt.error && <InlineMessage message={createdAt.error} />}
      <DateRangeSelector
        onChange={({ startDate, endDate }) => setUpdatedAtDates({ startDate, endDate })}
        onClear={() => setUpdatedAtDates({ startDate: '', endDate: '' })}
        filterLabel="Updated At"
        keyName="updatedAt"
        showApplyButton={false}
      />
      {updatedAt.error && <InlineMessage message={updatedAt.error} />}
      <DateRangeSelector
        onChange={({ startDate, endDate }) => setCompletedAtDates({ startDate, endDate })}
        onClear={() => setCompletedAtDates({ startDate: '', endDate: '' })}
        filterLabel="Ingestion Date"
        keyName="ingestionDate"
        showApplyButton={false}
      />
      {completedAt.error && <InlineMessage message={completedAt.error} />}
    </React.Fragment>
  );
};

export default DateRangesFilter;
