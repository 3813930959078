import React, { Fragment, useEffect, useRef, useReducer, useState } from 'react';
import AceEditor from 'react-ace';
import { Redirect } from 'react-router-dom';
import { Form, Button, Message } from 'semantic-ui-react';
import ejs from 'ejs';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'utils/axios';

import 'ace-builds/src-noconflict/mode-ejs';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';

import Card from 'components/Card';
import getPreviewData from './preview-data';
import { withPermissions } from '../../components/HoC';
import InlineMessage from '../../components/InlineMessage';

function FeedFormatsForm({
  initialValues = {
    title: '',
    template: '',
    description: '',
  },
  onSubmit,
  location,
  permissions: { canManageFeeds, canCreateFeeds },
}) {
  const [values, updateValue] = useReducer((state, action) => {
    return { ...state, [action.field]: action.value };
  }, initialValues);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [previewDataVisible, setPreviewDataVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const previewRef = useRef(null);
  const previewDataRef = useRef(null);

  const [rendered, setRendered] = useState(false);
  const [renderError, setRenderError] = useState(false);

  // Render the template
  function renderTemplate(data) {
    try {
      const dataWithHelpers = {
        ...(data || previewData),
        usaToIsoDate: (usaDate) => moment(usaDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      };

      setRendered(ejs.render(values.template, dataWithHelpers));
      setRenderError(false);
    } catch (e) {
      setRenderError({
        message: e.message,
        trace: e.trace,
      });
    }
  }

  // Fetch preview data on mount
  useEffect(() => {
    getPreviewData().then((data) => {
      setPreviewData(data);
      renderTemplate(data);
    });
  }, []);

  // Scroll to preview/preview data when toggled
  useEffect(() => {
    if (previewVisible) {
      previewRef.current.scrollIntoView();
    } else if (previewDataVisible) {
      previewDataRef.current.scrollIntoView();
    }
  }, [previewVisible, previewDataVisible]);

  // Render the template on change
  useEffect(() => {
    if (previewData.properties && values.template) {
      renderTemplate();
    }
  }, [values.template]);

  function onFormSubmit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const user_id = axios.getUserId();

    if (!canManageFeeds && !canCreateFeeds) {
      return setError({
        type: 'danger',
        message: 'You do not have permission to edit any formats.',
      });
    }
    // Permission to manage own, not all, but didn't create this row:
    if (values.user_id && !canManageFeeds && canCreateFeeds && values.user_id !== user_id) {
      return setError({
        type: 'danger',
        message: 'You do not have permission to edit this format.',
      });
    }

    return onSubmit({ ...values, user_id: values.user_id || user_id })
      .then(setSuccess)
      .catch(setError);
  }

  return (
    <Card>
      {error && (
        <Message negative>
          <Message.Header>Failed to save feed format</Message.Header>
          <p>
            An error was encountered when saving this feed format. Perhaps the title is already in use. Double check
            your input and try again.
          </p>
          {error.type && <InlineMessage {...error} />}
        </Message>
      )}
      {success &&
        (success.redirect ? (
          <Redirect to={success.redirect} />
        ) : (
          <Message positive>
            <Message.Header>Feed Format Saved</Message.Header>
            <p>Your changes have been saved successfully.</p>
          </Message>
        ))}
      <Form onSubmit={onFormSubmit}>
        <Form.Field>
          <label>Title</label>
          <input
            type="text"
            name="title"
            value={values.title}
            onChange={(e) => updateValue({ field: 'title', value: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <textarea
            name="description"
            value={values.description}
            onChange={(e) => updateValue({ field: 'description', value: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          {renderError && (
            <Message negative>
              <Message.Header>Failed to render template</Message.Header>
              <strong>{renderError.message}</strong>
              <p>{renderError.trace}</p>
            </Message>
          )}

          <AceEditor
            mode="ejs"
            theme="chrome"
            value={values.template}
            onChange={(value) => updateValue({ field: 'template', value })}
            height="400px"
            width="100%"
            name="feed_format_editor"
            setOptions={{ behavioursEnabled: !location.search }}
          />
        </Form.Field>

        <div style={{ textAlign: 'right' }}>
          <div
            className="ui button secondary"
            style={{ marginBottom: '8px' }}
            onClick={() => {
              setPreviewDataVisible(!previewDataVisible);
            }}
          >
            {previewDataVisible ? 'Hide' : 'Show'} Example Data
          </div>
          {!renderError && (
            <div
              className="ui button secondary"
              style={{ marginBottom: '8px' }}
              onClick={() => {
                if (rendered) {
                  setPreviewVisible(!previewVisible);
                }
              }}
            >
              {previewVisible ? 'Hide' : 'Show'} Preview
            </div>
          )}
          <Button type="submit" primary>
            <i className="icon left save" />
            Save Feed Format
          </Button>
        </div>
        {previewDataVisible ? (
          <Fragment>
            <hr />
            <h4>Example Data</h4>
            <div ref={previewDataRef}>
              <AceEditor
                mode="json"
                theme="chrome"
                value={JSON.stringify(previewData, null, '\t')}
                readOnly={true}
                height="400px"
                width="100%"
              />
            </div>
          </Fragment>
        ) : null}

        {previewVisible && rendered && !renderError && (
          <Fragment>
            <hr />
            <h4>Preview</h4>
            <div ref={previewRef}>
              <AceEditor
                mode="xml"
                theme="chrome"
                value={rendered}
                readOnly={true}
                height="400px"
                width="100%"
                name="feed_format_preview"
              />
            </div>
          </Fragment>
        )}
      </Form>
    </Card>
  );
}

FeedFormatsForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  location: PropTypes.object,
  permissions: PropTypes.shape({
    canCreateFeeds: PropTypes.string,
    canManageFeeds: PropTypes.string,
  }),
};

export default withPermissions(FeedFormatsForm);
