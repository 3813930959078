import React, { PureComponent } from 'react';
import { Header, Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Flex } from 'grid-styled';

import './SortOrdering.scss';
class SortOrdering extends PureComponent {
  static propTypes = {
    removeQueryItem: PropTypes.func,
    addQueryItem: PropTypes.func,
    id: PropTypes.string,
    parent: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    query: PropTypes.object,
  };

  render() {
    const { children, removeQueryItem, id, addQueryItem, parent, query } = this.props;
    return (
      <div className={'sort-ordering'}>
        <Flex>
          <Header size={'tiny'}>Ordering</Header>
          <Button
            onClick={(e) => {
              e.preventDefault();
              addQueryItem({ type: 'QUERY_SORT_CONDITIONS', parent, query });
            }}
            color={'green'}
          >
            Add Order Column
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              removeQueryItem({ id, query });
            }}
            icon
            color={'red'}
          >
            <Icon name={'minus circle'} />
            Remove Ordering
          </Button>
        </Flex>
        {children}
      </div>
    );
  }
}

export default SortOrdering;
