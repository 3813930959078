import axios from 'axios';

const createAxiosInstance = ({ emitter, localStorage, decoder }) => {
  let LOGGED_IN = false;

  const instance = axios.create();

  const redirecMessageObj = {
    401: 'You must be logged first',
    403: "You don't have the correct permissions to do that",
  };

  const redirectAndDisplayErrorMessage = (status) => {
    if (LOGGED_IN) {
      instance.clearLogin();
      emitter.emit('redirect', { to: '/login' });

      emitter.emit('alert-queue:add', {
        type: 'warning',
        message: redirecMessageObj[status],
      });
    }
  };

  instance.clearLogin = () => {
    LOGGED_IN = false;
    localStorage.removeItem('token');
    delete instance.defaults.headers.common['Authorization'];
  };

  instance.setLogin = (token) => {
    localStorage.setItem('token', token);
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  instance.resetHeaders = () => {
    const token = localStorage.getItem('token');
    if (token) {
      instance.setLogin(token);
    }
  };

  instance.hasToken = () => {
    const token = localStorage.getItem('token');
    LOGGED_IN = token !== null;
    return LOGGED_IN;
  };

  instance.getUserId = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = decoder(token);
      const { userId } = decoded.data;
      return userId;
    }
    return null;
  };

  instance.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response) {
        const { status } = err.response;
        if (status === 401 || status === 403) {
          redirectAndDisplayErrorMessage(status);
        }
      }
      return Promise.reject(err);
    },
  );

  if (instance.hasToken()) {
    instance.resetHeaders();
  }

  return instance;
};

export default createAxiosInstance;
