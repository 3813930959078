import createInstance from './createAxiosInstance';
import { emitter } from '../services';
import decoder from 'jwt-decode';

const instance = createInstance({
  emitter,
  localStorage: window.localStorage,
  decoder: decoder,
});

export default instance;
