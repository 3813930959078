import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, makeStyles, FormGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import axios from 'utils/axios';

const useStyles = makeStyles({
  label: {
    fontSize: '1rem',
    color: 'var(--bs-body-color)',
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '8px',
  },
});

const AssetReviewTypes = ({ canEditPropertyDetails }) => {
  const [newConstruction, setNewConstruction] = useState([]);

  useEffect(() => {
    axios.get('/api/admin/amenities').then(({ data }) => {
      const filteredAmenities = data.filter((amenity) => amenity.name === 'New Construction');
      setNewConstruction(filteredAmenities);
    });
  }, []);

  const classes = useStyles();
  const { watch, control, setValue } = useFormContext();
  const values = watch();
  return (
    <FormGroup className={classes.row}>
      <Controller
        control={control}
        name={'allow_featured'}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                color="default"
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                data-testid="Featured"
                checked={values.allow_featured}
                disabled={!canEditPropertyDetails}
              />
            }
            label="Show in featured home blocks"
            labelPlacement="end"
          />
        )}
      />
      <Controller
        control={control}
        name={'allow_greatschools'}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                color="default"
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                data-testid="schools"
                checked={values.allow_greatschools}
                disabled={!canEditPropertyDetails}
              />
            }
            label="Show GreatSchools data"
            labelPlacement="end"
          />
        )}
      />
      <Controller
        control={control}
        name={'is_pet_friendly'}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                color="default"
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                data-testid="pets"
                checked={values.is_pet_friendly}
                disabled={!canEditPropertyDetails}
              />
            }
            label="Pet Friendly Environment"
            labelPlacement="end"
          />
        )}
      />
      <Controller
        control={control}
        name={'new_construction'}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                color="default"
                name={name}
                onChange={(e) => {
                  onChange(e.target.checked);
                  if (!e.target.checked) {
                    const newAmenities = values.amenities.filter((item) => item.name !== 'New Construction');
                    setValue('amenities', newAmenities);
                  } else {
                    setValue('amenities', [
                      ...values.amenities,
                      { id: newConstruction[0].id, name: 'New Construction' },
                    ]);
                  }
                }}
                onBlur={onBlur}
                value={value}
                data-testid="construction"
                checked={values.new_construction}
                disabled
              />
            }
            label="New Construction"
            labelPlacement="end"
          />
        )}
      />
      <Controller
        control={control}
        name={'btr_community'}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                color="default"
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                data-testid="community"
                checked={values.btr_community}
                disabled={!canEditPropertyDetails}
              />
            }
            label="BTR Community"
            labelPlacement="end"
          />
        )}
      />
      <Controller
        control={control}
        name={'model_home'}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                color="default"
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                data-testid="model_home"
                checked={values.model_home}
                disabled={!canEditPropertyDetails}
              />
            }
            label="Model Home"
            labelPlacement="end"
          />
        )}
      />
    </FormGroup>
  );
};
export default AssetReviewTypes;

AssetReviewTypes.propTypes = {
  canEditPropertyDetails: PropTypes.bool,
};
