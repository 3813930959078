import React from 'react';
import axios from 'utils/axios';
import PropTypes from 'prop-types';

import FeedFormatsForm from 'containers/FeedFormats/Form';
import View from 'components/View';
import ViewHeader from 'components/ViewHeader';
import ViewContent from 'components/ViewContent';

export default function FeedFormatsCreate(props) {
  function onSubmit(values) {
    return axios.post('/api/admin/feed-formats', values).then((response) =>
      response && response.data
        ? {
            redirect: `/feed-format/${response.data.id}`,
          }
        : false,
    );
  }

  return (
    <View>
      <ViewHeader title="Create Feed Format" subtitle="Create a new feed format:" />
      <ViewContent>
        <FeedFormatsForm onSubmit={onSubmit} location={props.location} />
      </ViewContent>
    </View>
  );
}

FeedFormatsCreate.propTypes = {
  location: PropTypes.object,
};
