import React from 'react';
import ExportToCVSButton from '../components/ExportToCSVButton';
import { Flex, Box } from 'grid-styled';
import { transformDataForCSV } from '../utils/reports';
import PropTypes from 'prop-types';
import { MAX_REPORT_SIZE_LIMIT } from '../utils/constants';

const ExportToCVSButtonContainer = ({ state, maxReportSizeLimit, transformValues, endpoint, reportName }) => (
  <Flex justifyContent="flex-end">
    <Box pt={20}>
      <ExportToCVSButton
        transformDataForCSV={transformDataForCSV.bind(this, {
          state,
          maxReportSizeLimit,
          transformValues,
          endpoint,
          reportName,
        })}
      />
    </Box>
  </Flex>
);

ExportToCVSButtonContainer.propTypes = {
  endpoint: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  state: PropTypes.object,
  maxReportSizeLimit: PropTypes.number,
  transformValues: PropTypes.func,
};

ExportToCVSButtonContainer.defaultProps = {
  maxReportSizeLimit: MAX_REPORT_SIZE_LIMIT,
};

export default ExportToCVSButtonContainer;
