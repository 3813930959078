import React, { Component } from 'react';
import axios from 'utils/axios';
import { sortManuallyAddedProperties, sanitizeAndReturnQueryObj } from 'utils/featuredBlock';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { arrayMove } from 'react-sortable-hoc';

import { MAX_NUM_PROPERTIES } from './config';
import { getFeaturedHomesQueryBuilderConf } from 'utils/queryBuilder';
import { getFeatureBlock, getPropertiesByIds } from '../../api';

import QueryBuilderHelper from 'utils/queryBuilderHelper';

/**
 * withFeatureBlockResources HOC
 *
 * @param {*} WrappedFeatureBlockComponent
 */
const withFeatureBlockResources = (WrappedFeatureBlockComponent) => {
  return class AddEditFeatureBlock extends Component {
    static propTypes = {
      match: PropTypes.object,
      marketId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      params: PropTypes.object,
      backLink: PropTypes.string,
      isEditing: PropTypes.bool,
    };

    static contextTypes = {
      emitter: PropTypes.object,
    };

    static defaultProps = {
      isEditing: false,
    };

    state = {
      notification: {},
      predictions: [],
      rows: [],
      title_error: false,
      displayManualPropertyAdditions: false,
      displayQueryBuilder: false,
      query: {},
      isQueryValid: true,
      queryConfig: null,
      blockData: {
        view_type: 'Grid',
        show_promo_button: true,
        no_lifestyle_photo: false,
        is_visible: 'Yes',
        title: '',
      },
    };

    onImageUpload = (e) => {
      const input = e.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.setState({
            previewImg: e.target.result,
            blockData: {
              ...this.state.blockData,
              image: input.files[0],
              no_lifestyle_photo: false,
            },
          });
        };
        reader.readAsDataURL(input.files[0]);
      }
    };

    onChangeOrder = ({ oldIndex, newIndex }) => {
      const { rows, query } = this.state;
      const sortedRows = arrayMove(rows, oldIndex, newIndex);
      query.manually_added_properties = sortedRows.map((sr) => sr.id);

      this.setState({
        rows: sortedRows,
        query,
      });
    };

    onPropertySelect = (suggestion) => {
      const { rows, query } = this.state;
      if (this.isDuplicateProperty(suggestion)) return;
      const copyRows = [...rows];

      suggestion.is_syndicated = true;
      copyRows.push(suggestion);

      query.manually_added_properties = (query.manually_added_properties || []).concat(suggestion.id);

      this.setState({
        rows: copyRows,
        searching: false,
        query,
      });
    };

    onPropertyAdditionCheckboxSelection = (e, data) => {
      const { query } = this.state;
      if (data.name === 'displayManualPropertyAdditions') {
        if (!data.checked) {
          query.manually_added_properties = [];
        }

        this.setState({
          displayManualPropertyAdditions: data.checked,
          rows: [],
          ...(query ? query : {}),
        });
      } else {
        if (!data.checked) {
          query.criteria = undefined;
        }
        this.setState({
          displayQueryBuilder: data.checked,
          ...(query ? query : {}),
        });
      }
    };

    onChange = (e, props) => {
      const target = e.target;
      this.setState(
        {
          blockData: {
            ...this.state.blockData,
            [props.name]:
              props.type === 'radio' ? props.label : props.type === 'checkbox' ? props.checked : target.value,
          },
        },
        () => {
          this.validateFields([target.name]);
        },
      );
    };

    onBlur = () => {
      this.setState({
        searching: false,
      });
    };

    getQueryConfig = async () => {
      const queryConfig = await getFeaturedHomesQueryBuilderConf(this.props.marketId);
      this.setState({ queryConfig });
    };

    getMarketFeatureBlock = async ({ featuredId }) => {
      try {
        const result = await getFeatureBlock(featuredId);
        const data = Array.isArray(result.data) ? result.data[0] : result.data,
          { criteria, ordering, manually_added_properties, excluded_properties } = data.query_builder,
          rows = (manually_added_properties && (await getPropertiesByIds(manually_added_properties))) || {
            data: [],
          },
          dynamicPart = Moment().format();

        this.setState({
          previewImg: data.image_url ? `${data.image_url}?d=${dynamicPart}` : null,
          rows:
            (manually_added_properties && sortManuallyAddedProperties(rows.data, manually_added_properties)) ||
            rows.data,
          blockId: data.id,
          query: {
            criteria,
            ordering: ordering || [],
            manually_added_properties: manually_added_properties || [],
            excluded_properties: excluded_properties || [],
          },
          displayManualPropertyAdditions: (manually_added_properties || []).length > 0,
          displayQueryBuilder:
            (criteria && criteria.children && criteria.children.length > 0) || (ordering && ordering.length > 0),
          blockData: {
            view_type: data.view_type,
            title: data.title,
            description: data.description,
            long_description: data.long_description,
            is_visible: data.is_visible ? 'Yes' : 'No',
            show_promo_button: data.show_promo_button,
            no_lifestyle_photo: data.no_lifestyle_photo,
          },
        });
      } catch (err) {
        if (err && err.response && err.response.status === 401) {
          axios.clearLogin();
        }
      }
    };

    setSuccessRedirectState = () => {
      this.setState({
        successRedirect: true,
      });
    };

    setSubmitFailureState = (err) => {
      this.setState({
        notification: {
          msg: err.message,
          type: 'danger',
        },
      });
    };

    setupAndReturnPayload = (additionalPayloadOptions) => {
      const { blockData, displayQueryBuilder, queryConfig, query } = this.state;
      const payload = new FormData();
      const data = blockData;
      const helperInstance = new QueryBuilderHelper(queryConfig);
      const queryBuilder = displayQueryBuilder ? additionalPayloadOptions : query;

      if (displayQueryBuilder) {
        if (!helperInstance.hasValidQuery(queryBuilder.criteria)) {
          this.setState({ isQueryValid: false });
        } else {
          payload.append('query_builder', JSON.stringify(sanitizeAndReturnQueryObj(queryBuilder)));
        }
      }

      // put everything stored in blockData into the payload
      Object.keys(data).forEach((key) => {
        let value;
        if (typeof data[key] === 'boolean') {
          value = data[key];
        } else if (key === 'is_visible') {
          value = data[key] === 'Yes';
        } else if (data[key] === null) {
          value = '';
        } else {
          value = data[key] || '';
        }
        payload.append(key, value);
      });

      return payload;
    };

    removeImageFromState = () => {
      this.setState({
        previewImg: null,
        blockData: { ...this.state.blockData, image: null, image_url: null },
      });
    };

    isValid = () => {
      const { state } = this;
      const { view_type } = state.blockData;
      const { rows } = state;

      this.validateFields(['title']);

      if (view_type === 'Grid') {
        if (rows.length <= MAX_NUM_PROPERTIES) {
          return !!state.blockData.title && !state.title_error;
        } else {
          window.alert(
            `A Grid featured homes block may only have ${MAX_NUM_PROPERTIES} properties, and this block has more than ${MAX_NUM_PROPERTIES}. Please remove properties so that there are ${MAX_NUM_PROPERTIES} or less, and try to save again.`,
          );
          return false;
        }
      } else {
        this.validateFields(['description']);
        return this.requiredFields.every((curVal) => {
          return state.blockData[curVal];
        });
      }
    };

    updateQueryState = (queryState) => {
      const { query } = this.state;
      return this.setState({ query: { ...query, ...queryState } });
    };

    requiredFields = ['title', 'description'];

    /***
     * addPropertyToManualPropertyList
     *
     * Updates the internal row state by adding the
     */
    addPropertyToManualPropertyList = async (propertyId) => {
      const { rows } = this.state;
      const result = await getPropertiesByIds(propertyId && [propertyId]);

      this.setState({
        rows: rows.concat(result.data),
        displayManualPropertyAdditions: true,
      });
    };

    updateFeatureBlockState = (newState) => {
      this.setState({ ...newState });
    };

    addProperty = () => {
      this.setState({ searching: true });
    };

    isDuplicateProperty(property) {
      const { rows } = this.state;

      return rows.some((row) => {
        return row.id === property.id;
      });
    }

    validateFields = (fieldNames) => {
      fieldNames
        .filter((fieldName) => this.requiredFields.includes(fieldName))
        .forEach((rFieldName) => {
          if (!this.state.blockData[rFieldName]) {
            this.setState({
              [`${rFieldName}_error`]: true,
            });
          } else {
            this.setState({
              [`${rFieldName}_error`]: false,
            });
          }
        });
    };

    deleteFeatureBlock = (id) => {
      const { rows, query } = this.state;
      const copyRows = [...rows],
        isConfirmed = window.confirm('Are you sure you want to remove this property from this featured homes block?');

      if (!isConfirmed) return;

      const newRows = copyRows.filter((item, i) => i !== id);
      query.manually_added_properties = newRows.map((nr) => nr.id);

      this.context.emitter.emit('query-builder:map-removed', copyRows[id].id);

      this.setState({
        rows: newRows,
        query,
      });
    };

    render() {
      return (
        <WrappedFeatureBlockComponent
          featureBlockState={this.state}
          onChange={this.onChange}
          onImageUpload={this.onImageUpload}
          removeImageFromState={this.removeImageFromState}
          onPropertySelect={this.onPropertySelect}
          onBlur={this.onBlur}
          onChangeOrder={this.onChangeOrder}
          deleteFeatureBlock={this.deleteFeatureBlock}
          addProperty={this.addProperty}
          addNewFeatureBlock={this.addNewFeatureBlock}
          updateFeatureBlock={this.updateFeatureBlock}
          getMarketFeatureBlock={this.getMarketFeatureBlock}
          onPropertyAdditionCheckboxSelection={this.onPropertyAdditionCheckboxSelection}
          getQueryConfig={this.getQueryConfig}
          addPropertyToManualPropertyList={this.addPropertyToManualPropertyList}
          setSuccessRedirectState={this.setSuccessRedirectState}
          setSubmitFailureState={this.setSubmitFailureState}
          setupAndReturnPayload={this.setupAndReturnPayload}
          updateQueryState={this.updateQueryState}
          updateFeatureBlockState={this.updateFeatureBlockState}
          isValid={this.isValid}
          {...this.props}
        />
      );
    }
  };
};

export default withFeatureBlockResources;
