import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class PermissionsProvider extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    userPermissions: PropTypes.array.isRequired,
  };

  static childContextTypes = {
    userPermissions: PropTypes.array.isRequired,
  };

  getChildContext() {
    const { userPermissions } = this.props;
    return { userPermissions };
  }

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

export default PermissionsProvider;
