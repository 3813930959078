import React from 'react';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import PhotoOrderBulkRequest from 'components/PhotoOrderBulkRequest';

const BulkPhotoOrdering = () => {
  return (
    <View>
      <ViewHeader
        title={
          process.env.REACT_APP_HIDE_LEGACY_BULK_PHOTO_ORDER === 'true'
            ? 'Bulk Photo Ordering'
            : 'Planomatic Bulk Ordering'
        }
        subtitle="Upload a csv file to bulk place photo orders"
      ></ViewHeader>

      <ViewContent>
        <PhotoOrderBulkRequest />
      </ViewContent>
    </View>
  );
};

export default BulkPhotoOrdering;
