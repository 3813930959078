import { pick } from 'lodash';
import axios from 'utils/axios';

export const removeIdsFromQueryState = (query = []) => {
  return query.map((q) => {
    if (q.children) q.children = removeIdsFromQueryState(q.children);
    return pick(q, ['field', 'operator', 'value', 'children', 'direction', 'data_type']);
  });
};

export const getFeaturedHomesQueryBuilderConf = (marketId) => {
  return axios.get(`/api/admin/featured/queryConfig?market_id=${marketId}`).then((res) => res.data);
};
