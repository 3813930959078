import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from 'grid-styled';
import editIcon from '../../icons/edit.svg';
import deleteIcon from '../../icons/delete.svg';
import axios from 'utils/axios';
import { confirmAndFilterValues } from 'utils/administration';

class RolesList extends React.Component {
  constructor() {
    super();
    this.state = {
      roles: [],
    };
  }

  componentDidMount() {
    axios.get(`/api/admin/roles`).then((r) => {
      this.setState({
        roles: r.data,
      });
    });
  }

  deleteRole = (id) => {
    const newRoles = confirmAndFilterValues([...this.state.roles], id, 'Are you sure you want to delete this role?');

    this.setState({
      roles: newRoles,
    });

    axios.delete(`/api/admin/roles/${id}`);
  };

  renderRoles = () => {
    const { roles } = this.state;

    return roles.map((role, i) => (
      <div className="rt-tr-group" key={role.id}>
        <div className={`rt-tr ${i % 2 ? '-even' : '-odd'}`}>
          <div className="rt-td" style={nameStyle}>
            {role.name}
          </div>
          <div className="rt-td" style={rowStyle}>
            <Link to={`/administration/roles/${role.id}`}>
              <img alt="edit" className="cursor-pointer" src={editIcon} />
            </Link>
          </div>
          <div className="rt-td" style={rowStyle}>
            {role.name !== 'Administrators' && (
              <img
                alt="delete"
                className="cursor-pointer"
                src={deleteIcon}
                onClick={() => {
                  this.deleteRole(role.id);
                }}
              />
            )}
          </div>
        </div>
      </div>
    ));
  };

  render() {
    return (
      <div>
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <h2>Roles list</h2>
          </Box>
        </Flex>
        <Flex>
          <Box pl="2%" pb={20} width={1}>
            <div className="ReactTable -striped -highlight" style={{ maxWidth: '802px' }}>
              <div className="rt-table">
                <div className="rt-thead -header" style={{ minWidth: '800px' }}>
                  <div className="rt-tr">
                    <div className="rt-th" style={nameStyle}>
                      <div>Name</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Edit</div>
                    </div>
                    <div className="rt-th" style={rowStyle}>
                      <div>Remove</div>
                    </div>
                  </div>
                </div>
                <div className="rt-tbody" style={{ minWidth: '800px', overflow: 'visible' }}>
                  {this.renderRoles()}
                </div>
              </div>
            </div>
          </Box>
        </Flex>
        <Flex>
          <Box ml={'2%'} width={1}>
            <Flex>
              <Box ml={'auto'}>
                <Link to={`/administration/roles/add`} className={`ui button primary`}>
                  Add Role
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </div>
    );
  }
}

const rowStyle = { flex: '150 0 auto', width: '150px', textAlign: 'center' };
const nameStyle = {
  flex: '500 0 auto',
  width: '500px',
  textAlign: 'center',
};

export default RolesList;
