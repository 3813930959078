import React, { Component, createContext } from 'react';
import { findIndex, get, has, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Group from './Group';
import Condition from './Condition';
import SortOrdering from './SortOrdering';
import SortCondition from './SortCondition';
import QueryResults from './QueryResults';
import QueryBuilderHelper from 'utils/queryBuilderHelper';
import { removeIdsFromQueryState } from 'utils/queryBuilder';

import shortid from 'shortid';

import { Button } from 'semantic-ui-react';

import { getPropertiesByIds, previewFeatureBlock } from '../../api';

import './QueryBuilder.scss';

export const QueryBuilderContext = createContext();

class QueryBuilder extends Component {
  static propTypes = {
    queryConfig: PropTypes.object,
    query: PropTypes.object,
    marketId: PropTypes.string,
    addPropertyToManualPropertyList: PropTypes.func,
    updateQueryState: PropTypes.func.isRequired,
  };

  static contextTypes = {
    emitter: PropTypes.object,
  };

  static defaultProps = {
    query: {
      criteria: {
        operator: 'AND',
        children: [],
      },
      ordering: [],
      manually_added_properties: [],
      excluded_properties: [],
    },
  };

  state = {
    queryConfig: null,
    hasSortOrderGroup: false,
    queryBuilderResults: null,
    hasRunQuery: false,
    hasUpdatedQuery: false,
  };

  componentDidMount() {
    const { query, queryConfig } = this.props;

    if (query && query.manually_added_properties && query.manually_added_properties.length > 0) {
      query.manually_added_properties.forEach((map) => {
        this.setManuallyAddedProperties(map);
      });
    }

    if (query && query.excluded_properties && query.excluded_properties.length > 0) {
      query.excluded_properties.forEach((p) => {
        this.setExcludedProperties(p);
      });
    }

    if (isEmpty(query.criteria)) {
      query.criteria = {
        operator: 'AND',
        children: [],
      };
    }

    this.props.updateQueryState({ criteria: query.criteria });

    this.context.emitter.on('query-builder:map-removed', this.updateQueryBuilderThatMAPHasBeenRemoved);

    this.setState({
      queryConfig,
      hasSortOrderGroup: (query.ordering || []).length > 0,
    });
  }

  componentWillUnmount() {
    this._helperInstance = null;

    this.context.emitter.off('query-builder:map-removed', this.updateQueryBuilderThatMAPHasBeenRemoved);
  }

  getManuallyAddedProperties = () => {
    return this._manuallyAddedProperties;
  };

  setManuallyAddedProperties = (manuallyAddedPropertyId) => {
    const { updateQueryState } = this.props;
    this._manuallyAddedProperties.push(manuallyAddedPropertyId);
    this.removeExcludedPropertyFromList(manuallyAddedPropertyId);

    updateQueryState({
      manually_added_properties: this.getManuallyAddedProperties(),
    });
  };

  getExcludedProperties = () => {
    return this._excludedProperties;
  };

  setExcludedProperties = (excludedPropertyId) => {
    this._excludedProperties.push(excludedPropertyId);
  };

  getQueryDescription = (query, ordering) => {
    let queryString = '';
    if (query.children && query.children.length) {
      queryString += '(';
      queryString += query.children
        .map((child) => {
          if (child.children) {
            return this.getQueryDescription(child);
          }

          return `${this._helperInstance.getCriteriaLabelByValue(child.field).toUpperCase()} ${
            child.operator
          } ${this._helperInstance.getConditionValueByCriteriaAndKey(child.field, child.value)}`;
        })
        .join(` <b>${query.operator}</b> `);
      queryString += ')';
    }

    if (ordering && ordering.length > 0) {
      queryString += ` <b>ORDER BY</b>${ordering
        .map(
          (orderCondition) =>
            ` ${this._helperInstance.getOrderingLabelByCondition(orderCondition.field).toUpperCase()} <b>${
              orderCondition.direction
            }</b>`,
        )
        .join(',')}`;
    }

    return queryString;
  };

  updateQueryStateWithManuallyAddedAndExcludedProperties = () => {
    const { updateQueryState } = this.props;
    updateQueryState({
      manually_added_properties: this.getManuallyAddedProperties(),
      excluded_properties: this.getExcludedProperties(),
    });
  };

  updateQueryBuilderThatMAPHasBeenRemoved = (propertyId) => {
    this.removeManuallyAddedPropertyFromList(propertyId);
  };

  removeExcludedPropertyFromList = (excludedPropertyId) => {
    this._excludedProperties = this._excludedProperties.filter((ep) => ep !== excludedPropertyId);
    this.updateQueryStateWithManuallyAddedAndExcludedProperties();
  };

  removeManuallyAddedPropertyFromList = (excludedPropertyId) => {
    this._manuallyAddedProperties = this._manuallyAddedProperties.filter((map) => map !== excludedPropertyId);
    this.updateQueryStateWithManuallyAddedAndExcludedProperties();
  };

  /**
   * recursiveFnToRender
   *
   * Used to build Groups and Conditions recursively.
   * @returns {React Component} Group/Condition Components
   */
  recursiveFnToRender = ({ item, parent, isRoot, isQueryValid = true }) => {
    return this.isGroup(item)
      ? this.createGroup({ item, parent, isRoot, isQueryValid })
      : this.createCondition({ item, parent, isQueryValid });
  };

  /**
   * createGroup
   *
   * Creates a new Group. If the item contains children (making it a Group)
   * it will then build out more Groups. If the child element is a Condition,
   * then it will build out that Condition.
   *
   * @returns {React Component} Group/Condition Components
   */
  createGroup = ({ item, parent, isRoot = true, isQueryValid = true }) => {
    if (item && !item.id) {
      item.id = `g-${shortid.generate()}`;
    }

    item.isRoot = isRoot;

    return (
      <Group
        item={item}
        id={item.id}
        key={item.id}
        parent={parent}
        isRoot={isRoot}
        isQueryValid={isQueryValid}
        addQueryItem={this.addQueryItem}
        removeQueryItem={this.removeQueryItem}
        updateQueryCondition={this.updateQueryCondition}
      >
        {this.isGroup(item) &&
          item.children.map((group) =>
            this.recursiveFnToRender({
              item: group,
              parent: item,
              isRoot: false,
              isQueryValid,
            }),
          )}
      </Group>
    );
  };

  /**
   * createCondition
   *
   * Creates a new Condition Component.
   * @returns { React Component } Condition Component
   */
  createCondition = ({ item, parent, isQueryValid }) => {
    const { queryConfig } = this.props;

    if (item && !item.id) {
      item.id = `c-${shortid.generate()}`;
    }

    return (
      <Condition
        item={item}
        id={item.id}
        key={item.id}
        parent={parent}
        queryConfig={queryConfig}
        isQueryValid={isQueryValid}
        removeQueryItem={this.removeQueryItem}
        updateQueryCondition={this.updateQueryCondition}
      />
    );
  };

  createSortOrderGroup = (options) => {
    const { queryConfig } = this.state;
    const children = get(options, 'children');
    const query = get(options, 'query');

    return (
      <SortOrdering addQueryItem={this.addQueryItem} removeQueryItem={this.removeQueryItem} query={query}>
        {children &&
          children.map((child, index) => {
            if (!child.id) {
              child.id = `sc-${Date.now()}-${Math.floor(Math.random() * 100)}`;
            }

            child.header = index === 0 ? 'Order By' : 'Then By';

            return (
              <SortCondition
                id={child.id}
                key={child.id}
                item={child}
                index={index}
                parent={children}
                queryConfig={queryConfig}
                removeQueryItem={this.removeQueryItem}
                updateQueryCondition={this.updateQueryCondition}
              />
            );
          })}
      </SortOrdering>
    );
  };

  /**
   * removeQueryItem
   *
   * Removes a query item based on it's id. If the id is for a
   * Condition it will be removed from it's parents index. If
   * the id belongs to a group it will remove the group.
   *
   * @param {String} - The id of the Group or Condition
   */
  removeQueryItem = ({ parent, id, query }) => {
    const { hasRunQuery } = this.state;
    let { hasSortOrderGroup, hasUpdatedQuery } = this.state;
    const hasChildren = has(parent, 'children');
    if (hasChildren && parent.children.length > 0) {
      parent.children.splice(findIndex(parent.children, ['id', id]), 1);
    } else if (this.isGroup(parent)) {
      delete parent.children;
    } else {
      const lookup = hasChildren ? parent.children : parent;
      if (id) {
        parent.splice(findIndex(lookup, ['id', id]), 1);
      } else {
        hasSortOrderGroup = false;
        query.ordering = [];
      }
    }

    if (hasRunQuery) {
      hasUpdatedQuery = true;
    }

    this.setState({
      hasSortOrderGroup,
      hasUpdatedQuery,
    });
  };

  /**
   * addQueryItem
   *
   * Adds a query item based on it's type. If and id is
   * passed, it will add query type at that [the ids] location.
   * When creating a new Condition, we'll pass along the config
   * in order to display any possible conditions that could be
   * used when building out a query.
   *
   * @param {options}
   * @param {options.type} The type of Query Item to add.
   * @param {options.parent} The parent object.
   */
  addQueryItem = ({ type, parent, query }) => {
    const { hasRunQuery, hasUpdatedQuery } = this.state;

    let queryHasUpdated = hasUpdatedQuery;

    const firstMappedCriteria = this._helperInstance.getMappedCriteriaTypes()[0];
    const criteriaType = this._helperInstance.getCriteriaByValue(firstMappedCriteria.value);

    const defaultGroupObj = { operator: 'AND', children: [] };

    const defaultConditionObj = {
      field: firstMappedCriteria.value,
      operator: this._helperInstance.getOperatorTypesByGroup(criteriaType.operators)[0].value,
      value: criteriaType.values && this._helperInstance.mapCriteriaValuesForDropdowns(criteriaType)[0].value,
      data_type: criteriaType.data_type,
      parent,
    };

    const defaultSortConditionObj = {
      id: `so-${shortid.generate()}`,
      field: 'market_rent',
      direction: 'ASC',
    };

    if (type === 'QUERY_CONDITION') {
      parent.children.push(defaultConditionObj);
    } else if (type === 'QUERY_GROUP') {
      parent.children.push(defaultGroupObj);
    } else if (type === 'QUERY_SORT_ORDER') {
      this.setState({ hasSortOrderGroup: true });
    } else {
      if (!query.ordering) {
        query.ordering = [];
      }
      query.ordering.push(defaultSortConditionObj);
    }

    if (hasRunQuery) {
      queryHasUpdated = true;
    }

    this.setState({ hasUpdatedQuery: queryHasUpdated });
  };

  /**
   * updateQueryCondition
   *
   * Updates a query conditions based on it's id. Replaces the coditions
   * old value with the new value object passed via arguments. Once the
   * new value is saved the query state is set again to update the UI.
   *
   * @param {options.id} The id of the Query Condition
   * @param {options.parent} The parent object.
   * @param {options.newValue} An object containing new values for a Query Conditio0n
   */
  updateQueryCondition = ({ id, parent, newValue }) => {
    const { hasRunQuery } = this.state;
    let { hasUpdatedQuery } = this.state;
    const hasChildren = has(parent, 'children');
    const childIndex = hasChildren ? findIndex(parent.children, ['id', id]) : findIndex(parent, ['id', id]);

    if (hasChildren && childIndex >= 0) {
      parent.children[childIndex] = { id, ...newValue };
    } else if (!hasChildren) {
      parent[childIndex] = { id, ...newValue };
    }

    if (hasRunQuery) {
      hasUpdatedQuery = true;
    }

    this.setState({ hasUpdatedQuery });
  };

  /**
   * isGroup
   *
   * Takes an item and checks to see if that item has any children.
   * If it does, then it is a Group, else it is a Condition.
   *
   * @param {Array|Object}
   * @returns {Boolean}
   */
  isGroup = (item) => {
    return (item && item.operator && item.children instanceof Array) || false;
  };

  runQueryBuilder = async (query, updateFeatureBlockState) => {
    const isQueryValid = this._helperInstance.hasValidQuery(query.criteria);

    if (isQueryValid) {
      const sanitizedQuery = {
        excluded_properties: this.getExcludedProperties(),
        manually_added_properties: this.getManuallyAddedProperties(),
        criteria: removeIdsFromQueryState([query.criteria])[0],
        ordering: removeIdsFromQueryState(query.ordering),
      };

      const { data } = await previewFeatureBlock(this.props.marketId, sanitizedQuery);

      // append excluded property details to the list of data coming back from the preview endpoint
      const excludedProperties = this.getExcludedProperties();
      if (excludedProperties.length) {
        data.rows = data.rows || [];
        const excludedPropertyDetails = await getPropertiesByIds(excludedProperties);
        if (excludedPropertyDetails.data) {
          data.rows = data.rows.concat(excludedPropertyDetails.data);
        }
      }

      this.setState({
        queryBuilderResults: data,
        hasRunQuery: true,
        hasUpdatedQuery: false,
      });
    }

    updateFeatureBlockState({ isQueryValid });
  };

  _manuallyAddedProperties = [];
  _excludedProperties = [];
  _helperInstance = new QueryBuilderHelper(this.props.queryConfig);

  render() {
    const { hasSortOrderGroup, queryBuilderResults, hasRunQuery, hasUpdatedQuery } = this.state;
    const { queryConfig, updateQueryState } = this.props;

    return (
      <QueryBuilderContext.Consumer>
        {({ query, isQueryValid, updateFeatureBlockState }) => {
          return query && query.criteria && queryConfig ? (
            <React.Fragment>
              <div data-testid="query-builder" className={'query-builder'}>
                {this.recursiveFnToRender({
                  item: query.criteria,
                  parent: query.criteria,
                  isRoot: true,
                  isQueryValid,
                })}
                {(query.ordering && query.ordering.length > 0) || hasSortOrderGroup
                  ? this.createSortOrderGroup({
                      children: query.ordering,
                      query,
                    })
                  : null}
                <div className={'query-builder__footer'}>
                  <div
                    className={'query-builder__description'}
                    dangerouslySetInnerHTML={{
                      __html: this.getQueryDescription(query.criteria, query.ordering),
                    }}
                  />
                  <div className={'query-builder__cta'}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        this.runQueryBuilder(query, updateFeatureBlockState);
                      }}
                    >
                      {hasRunQuery ? 'Re-Run' : 'Run'} Query
                    </Button>
                  </div>
                </div>
              </div>
              {queryBuilderResults && (
                <QueryResults
                  results={queryBuilderResults}
                  hasStaleResults={hasUpdatedQuery}
                  runQueryBuilder={(query) => this.runQueryBuilder(query, updateFeatureBlockState)}
                  addPropertyToManualPropertyList={this.props.addPropertyToManualPropertyList}
                  setManuallyAddedProperties={(map) => {
                    this.setManuallyAddedProperties(map, query);
                    updateQueryState({
                      manually_added_properties: this.getManuallyAddedProperties(),
                      excluded_properties: this.getExcludedProperties(),
                    });
                  }}
                  getManuallyAddedProperties={this.getManuallyAddedProperties}
                  setExcludedProperties={(ep) => {
                    this.setExcludedProperties(ep, query);
                    updateQueryState({
                      excluded_properties: this.getExcludedProperties(),
                    });
                  }}
                  getExcludedProperties={this.getExcludedProperties}
                  removeExcludedPropertyFromList={this.removeExcludedPropertyFromList}
                />
              )}
            </React.Fragment>
          ) : null;
        }}
      </QueryBuilderContext.Consumer>
    );
  }
}

export default QueryBuilder;
